<template>
	<div class="PersonalisationFields" v-if="ticketPersonalisations"
		style="display: flex; flex-direction: column; gap: 12px;"
	>
		<Field :title="$t('text.fixedPersonalisationFields')" :infoText="$t('text.fixedPersonalisationHelp')">
			<div v-for="fixedTicketPersonalisation of possibleFixedTicketPersonalisationsSorted" :key="fixedTicketPersonalisation.sys.id"
				style="display: flex; gap: 4px; align-items: center;"
			>
				<v-checkbox hide-details density="compact"
					:disabled="disabled || fixedTicketPersonalisation.fields.personalisationGroup.de.fields.title.de === 'Name'"
					v-model="fixedTicketPersonalisations"
					:label="fixedTicketPersonalisation.fields.personalisationGroup.de.fields.title[ selectedLocale ]"
					:value="fixedTicketPersonalisation"
					:value-comparator="(a, b) => a.fields.personalisationGroup.de.sys.id === b.fields.personalisationGroup.de.sys.id"
					style="flex-grow: 0;"
				/>
			</div>
		</Field>
		<Field :title="$t('text.additionalPersonalisationFields')" :infoText="$t('text.additionalPersonalisationHelp')">
			<div v-if="additionalTicketPersonalisations?.length"
				style="margin: 10px 0; display: flex; flex-direction: column; gap: 10px;"
			>
				<div v-for="(additionalPersonalisation, p) in additionalTicketPersonalisations" :key="p"
					style="display: flex; gap: 10px; align-items: center; flex-wrap: wrap;"
				>
					<PersonalisationField v-if="additionalPersonalisation?.fields?.personalisationGroup?.de?.fields"
						:additionalPersonalisation="additionalPersonalisation"
						@remove="removePersonalisation(additionalPersonalisation)"
					/>
				</div>
			</div>
			<v-btn class="gradientButton" elevation="0" @click="addAdditionalPersonalisationRow()">
				<v-icon>mdi-plus</v-icon>{{ $t('text.addPersonalisationOption') }}
			</v-btn>
		</Field>
		<br />
		<br />
		<br />
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import PersonalisationField from './PersonalisationField.vue'

export default {
	name: 'PersonalisationFields',
	mixins: [ Common ],
	components: { PersonalisationField },
	props: {
		ticketPersonalisations: Array, // Array of PersonalisationGroups. Each group contains PersonalisationFields.
		disabled: Boolean,
	},
	data: () => ({
		possibleFixedTicketPersonalisations: [],
	}),
	computed: {
		possibleFixedGroupIds() {
			return this.possibleFixedTicketPersonalisations.map(item => item.fields.personalisationGroup.de.sys.id)
		},
		fixedTicketPersonalisations: {
			get() {
				return this.ticketPersonalisations.filter(item => this.possibleFixedGroupIds.includes(item.fields.personalisationGroup.de.sys.id))
			},
			set(value) {
				this.ticketPersonalisations.splice(0, this.ticketPersonalisations.length, ...value, ...this.additionalTicketPersonalisations)
			},
		},
		additionalTicketPersonalisations() {
			return this.ticketPersonalisations.filter(item => !this.possibleFixedGroupIds.includes(item.fields.personalisationGroup.de.sys.id))
		},
		possibleFixedTicketPersonalisationsSorted() {
			return this.possibleFixedTicketPersonalisations.sort((a, b) => {
				if (a.fields.personalisationGroup.de.fields.title.de === 'Name') return -1
				if (b.fields.personalisationGroup.de.fields.title.de === 'Name') return 1
				return a.fields.personalisationGroup.de.fields.title.de.localeCompare(b.fields.personalisationGroup.de.fields.title.de)
			})
		},
	},
	methods: {
		addAdditionalPersonalisationRow() {
			this.ticketPersonalisations.push({
				sys: {
					id: 'id_p_' + Math.round(Math.random() * 99999)
				},
				fields: {
					title: { de: '', en: '', fr: '', it: '', nl: '' },
					required: { de: true },
					personalisationGroup: {
						de: {
							sys: { id: '' },
							fields: {
								title: { de: '', en: '', fr: '', it: '', nl: '' },
								personalisationFields: {
									de: [{
										fields: {
											inputType: { de: 'Text' },
											label: { de: '', en: '', fr: '', it: '', nl: '' },
											options: { de: [] }
										}
									}]
								}
							}
						}
					}
				}
			})
		},
		removePersonalisation(customPerso) {
			const index = this.ticketPersonalisations.indexOf(customPerso)
			if (index < 0) return
			this.ticketPersonalisations.splice(index, 1)
		},
		wrapTicketPersonalisation(personalisationGroup) {
			return {
				sys: {
					id: 'id_p_' + Math.round(Math.random() * 99999)
				},
				fields: {
					personalisationGroup: { de: personalisationGroup },
					// TODO: we force required here - is that ok? shouldnt the user have control?
					required: { de: true },
				},
			}
		},
	},
	async mounted() {
		// Set possibleFixedTicketPersonalisations
		if (this.$store.state.coreConfig.fields.personalisationGroups.de?.length > 0) {
			for (const personalisationGroup of this.$store.state.coreConfig.fields.personalisationGroups.de) {
				let ftp = this.ticketPersonalisations.find(tp => tp.fields.personalisationGroup.de.sys.id == personalisationGroup.sys.id)
				if (!ftp) {
					ftp = this.wrapTicketPersonalisation(personalisationGroup)
					// we always add the name, this is a fixed field
					if (personalisationGroup.fields.title.de === 'Name') {
						this.ticketPersonalisations.push(ftp)
					}
				}
				this.possibleFixedTicketPersonalisations.push(ftp)
			}
		}
	},
}
</script>

<style scoped lang="scss">
.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
	line-height: 2 !important;
}
div.field .v-input { margin-top: 0; }
table { margin-top: 15px; margin-bottom: 15px; padding: 10px; width: 100%; }
table th { text-align: left; }
table td { padding-right: 10px; vertical-align: bottom; }
</style>