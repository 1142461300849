<template>
	<Dialog ref="translationsIncompleteDialog" data-cy="translationsIncompleteDialog" :confirmLabel="$t('text.confirm')"
		:cancelLabel="$t('text.editTranslations')" :confirm-handler="handleConfirm"
		:cancel-handler="handleCancel" :title="$t('text.translations')" :height="'360px'" :width="'540px'">
		<template #content>
			<div style="display: flex; align-items: center; justify-content: space-around; flex: 1;">
				<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
				<div style="display: flex; flex-direction: column;">
					<p class="dialogTitle">{{ $t('text.translationsIncomplete') }}</p>
					<span class="dialogText">{{ $t('text.translationsIncompleteText', { languages: incompleteLanguages })
					}}</span>
				</div>
			</div>
		</template>
	</Dialog>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'

export default {
	name: 'TranslationsIncompleteDialog',
	components: { Dialog },
	props: {
		translations: Object,
		handleConfirm: Function,
		handleCancel: Function,
	},
	computed: {
		incompleteLanguages() {
			const languages = this.translations.filter(({ percentage }) => percentage < 100).map(({ locale: { name } }) => name)

			if (languages.length < 2) return languages.join(', ')

			return `${languages.slice(0, -1).join(', ')} and ${languages[languages.length - 1]}`
		},
		isIncomplete() {
			return this.translations
				// TODO: we should filter out all languages that are not enabled for the client
				.filter(({ locale: { name } }) => name != 'nl')
				.some(({ percentage }) => percentage < 100)
		},
	},
	methods: {
		open() {
			this.$refs.translationsIncompleteDialog.show = true
		},
		close() {
			this.$refs.translationsIncompleteDialog.show = false
		},
	},
}
</script>

<style>
.dialogTitle {
	font-size: 19px;
	font-weight: bold;
	color: #000;
}

.dialogText { 
  width: 268px;
  height: 110px;
  margin: 8px 0 0;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.42px;
  color: #9da0a5;
}

.warningDialogImage {
  width: 96px;
  height: 96px;
 /* margin: 24px 48px 23px 0; */
  object-fit: contain;
}
</style>
