<template>
	<div class="Api2FastifyUserForm">
		Api2FastifyUserForm User Form Test
		<Field typeName="User" fieldName="name" v-model="modelValue.name" />
		<Field typeName="User" fieldName="email" v-model="modelValue.email" />
		<Field typeName="User" fieldName="i18nTest" v-model="modelValue.i18nTest" />
	</div>
</template>

<script lang="ts">

// TODO: currently Field only works with i18n objects - but in the new model
//       this is actually a rarity.
//       how to consolidate this? should Field switch between the 2 modes based on the model deifnition?
//       - the model comes from the new decorators
//       - the model has a localizable flag

//import { User } from '../../../../api2/src/modules/test/FastifyTestExecutive'

// TODO: TranslationWizardDialog also uses this.$store.state.fieldModels - we'll have to
//       add access to metadata there too.
//       same for Common::getTranslationStatus, InlineEntryEditor, TranslateableField.

// MODEL DRIVEN FIELDS WITH API2
// this file demoes the usage of Field with the @Field decorator in models.
// this is the new standard way to define classes and fields in api2.
// with this approach both "schema" and "model" endpoints are not necessary anymore,
// as the schema is defined in the class itself as decorators and we link the classes
// in our components.

export default {
	props: {
		modelValue: Object, // User
	},
}
</script>

<style scoped>
.Api2FastifyUserForm { border: 1px solid silver; }
</style>