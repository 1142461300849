<template>
	<div class="TicketOptionExternalSystemAxess" v-if="ticketOption">
		<div class="row">
			<Field :title="$t('text.axessTicketType')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.ticketTypeNumber" 
					:error-messages="axessTicketTypeNumberError"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
			<Field :title="$t('text.axessPool')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.poolNumber" 
					:error-messages="axessPoolNumberError"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
			<Field :title="$t('text.axessPersonType')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.personTypeNumber" 
					:error-messages="axessPersonTypeNumberError"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
			<Field :title="$t('text.axessIDNumber')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.idNumber" 
					:error-messages="axessIdNumberError"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
		</div>
	</div>
</template>

<script lang="ts">
// isNaturalNumber, userIsOperator, spTrustLevel
import Common from '../../mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		ticketOption: Object,
	},
	computed: {
		// TODO: make computed
		axessTicketTypeNumberError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) return ''
			return !this.ticketOption?.fields.external.ticketTypeNumber?.length ? this.$t('text.externalSystemRequired') : ''
		},
		axessPersonTypeNumberError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) return ''
			return !this.ticketOption?.fields.external.personTypeNumber?.length ? this.$t('text.externalSystemRequired') : ''
		},
		axessPoolNumberError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) return ''
			return !this.ticketOption?.fields.external.poolNumber?.length ? this.$t('text.externalSystemRequired') : ''
		},
		axessIdNumberError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) return ''
			return !this.ticketOption?.fields.external.idNumber?.length ? this.$t('text.externalSystemRequired') : ''
		},
		valid() {
			return !this.axessTicketTypeNumberError
				&& !this.axessPersonTypeNumberError
				&& !this.axessPoolNumberError
				&& !this.axessIdNumberError
		},
	},
	methods: {
/*
			const external = this.modelValue.fields.ticket.de.fields.external
			if (this.modelValue.fields.ticket.de.fields.hasOwnProperty('external')) {
				if (this.modelValue.fields.ticket.de.fields.external.system === 'Axess') {
					ticketOption.fields.external = {
						ticketTypeNumber: '',
						personTypeNumber: '',
						poolNumber: '',
						idNumber: ''
					}
				}
			}*/
		validateAxess(ticketOption) {
			if (this.axessTicketTypeNumberError ||
				this.axessPersonTypeNumberError ||
				this.axessPoolNumberError ||
				this.axessIdNumberError
			) {
				return false
			}
			return true
		},
		async setExternalSystem() {
			if (!this.ticketOption) return
			this.ticketOption.fields.external = {
				ticketTypeNumber: this.ticketOption.fields.external.ticketTypeNumber ?? '',
				poolNumber: this.ticketOption.fields.external.poolNumber ?? '',
				personTypeNumber: this.ticketOption.fields.external.personTypeNumber ?? '',
				idNumber: this.ticketOption.fields.external.idNumber ?? '',
			}
		},
	},
	beforeMount() {
		// TODO: also check if ticketTypeNumber actually exists, unify with the init in setExternalSystem
		if (!this.ticketOption?.fields.external) {
			this.ticketOption.fields.external = {
				ticketTypeNumber: '',
				personTypeNumber: '',
				poolNumber: '',
				idNumber: '',
			}
		}
	},
	async mounted() {
		await this.setExternalSystem()
	},
}
</script>

<style scoped>
.row { display: flex; gap: 10px; width: 100%; }
.row > * { flex: 1 0; }
.row > .Field { border-left: none; padding: 0; }
</style>