<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail"
		v-model:successTitle="successTitle" :loading="loading"
		:style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }">
		<template #navbar>

		</template>

		<template v-if="tokenIsValid">
			<SideBar class="signing-sidebar" :actions="sidebarActions" :navigation="sections"
				:preselectedActiveLink="'contracts'" @action=" handleAction($event)">
				<template #customActions>
					<div class="pa-4 flex-column d-flex">
						<span class="pt-1 mb-4 sidebar-title">{{ $t('text.actions') }}</span>
						<ContractCard v-for="contract in contracts" :key="contract.id" :contract="contract" class="mb-4"
							@click="setActiveLink(contract.id)" style="cursor: pointer;" />
					</div>
				</template>

				<template #customNavigation>
					<div class="pb-4 flex-column d-flex signing-sidebar-navigation">
						<span class="sidebar-title pa-4">{{ $t('text.sidebar') }}</span>
						<SideBarNavigation v-for="navigation in navigations" :key="navigation.clientId"
							:model-value="selectedContract?.id" :title="navigation.clientId"
							:sections="navigation.contracts" :expanded="true"
							:title-error-icon="hasUnsignedContracts(navigation.clientId) ? 'mdi-alert' : null"
							:preselected-active-link="selectedContract?.id" @setActive="setActiveLink($event)" />
					</div>
				</template>
			</SideBar>

			<div style="margin-top: 60px;" v-if="selectedContract">
				<Disclosure expanded class="signing-disclousure">
					<template #header-accessory>
						<div class="header-accessory">
							<v-icon size="24px">mdi-gavel</v-icon>
							<div>
								<span>
									{{ selectedContract.contractTemplate.name }}
								</span>
								<span>
									Version {{ 'todo Version' }} | {{ $t('text.issuedOn') }} {{ issuedOnText }}
								</span>
							</div>
							<div>
								<div class="d-flex">
									<v-spacer></v-spacer>
									<div :style="{ 'background-color': backgroundColorChip }" class="status-chip">
										<v-icon size="18px">mdi-pencil</v-icon>
										<span>{{ statusText }}</span>
									</div>
								</div>
								<span :style="{ 'color': textColor }" v-html="infoText" />
							</div>
						</div>
					</template>
					<p>hashhad</p>
				</Disclosure>
			</div>

			<DialogV2 ref="contractorDialog" :title="$t('text.editContractorInformation')" :onConfirm="onConfirm"
				:disableConfirmButton="hasFormError">
				<template #content>
					<ContractorForm v-model="contractor" @update:modelValue="contractor = $event"
						@update:hasFormError="hasFormError = $event" />
				</template>
			</DialogV2>
		</template>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Application from '../Application.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import DialogV2 from '../../../components/common/DialogV2.vue'
import ContractorForm from '../../../components/contract/ContractorForm.vue'
import ContractCard from '../../../components/contract/ContractCard.vue'
import SideBar from "@/components/common/SideBar.vue"
import SideBarNavigation from "@/components/common/SideBarNavigation.vue"
import Disclosure from '@/components/common/Disclosure.vue'
import moment from 'moment'

export default {
	name: 'ContractSigningView',
	components: { Application, DialogV2, ContractorForm, SideBar, ContractCard, SideBarNavigation, Disclosure },
	mixins: [Common],
	props: {
		token: {
			type: String,
			// required: true,
			default: '75cfbeaf-2f68-4cb6-b866-7ca243c15c83'
		},
	},
	data: () => ({
		tokenIsValid: false,
		contractor: null,
		contracts: [],
		loading: false,
		hasFormError: false,
		selectedContract: null
	}),
	computed: {
		navigations() {
			const grouped = []

			this.contracts.forEach(item => {
				const clientId = item.contractTemplate.contractSequence.clientId
				const id = item.id
				const title = item.contractTemplate.name

				let group = grouped.find(g => g.clientId === clientId)

				if (!group) {
					group = { clientId, contracts: [] }
					grouped.push(group)
				}

				group.contracts.push({
					id, title,
					state: item.signedDate ? 'valid' : 'error',
					errorIconColor: 'black',
					validIcon: true,
					validIconColor: 'black'
				})
			})
			return grouped
		},
		isSigned() {
			return !!this.selectedContract?.signedDate
		},
		toBeSigned() {
			return !this.isSigned && (!this.selectedContract?.dueDate || (this.selectedContract?.dueDate && moment().isBefore(moment(this.selectedContract?.dueDate))))
		},
		overdue() {
			return !this.isSigned && !this.toBeSigned && this.selectedContract?.dueDate && moment().isAfter(moment(this.selectedContract?.dueDate))
		},
		backgroundColorChip() {
			if (this.isSigned) {
				return '#64C823'
			}
			if (this.toBeSigned) {
				return '#FFB400'
			}
			if (this.overdue) {
				return '#F34545'
			}
			return '#8F8F8F'
		},
		statusText() {
			if (this.isSigned) {
				return this.$t('text.signed')
			}
			return this.$t('text.unsigned')
		},
		infoText() {
			if (this.isSigned) {
				return `${this.$t('text.signedOn')} <b>${moment(this.selectedContract?.signedDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(this.selectedContract?.signedDate).format('HH:mm:ss')}</b> by <b>${this.selectedContract?.signatureName}, ${this.selectedContract?.signaturePosition}</b>`
			}
			if (this.toBeSigned) {
				return `<b>${this.$t('text.signUntil')} ${moment(this.selectedContract?.dueDate).format('DD.MM.YYYY')}</b>`
			}
			if (this.overdue) {
				return `<b>${this.$t('text.notSignedInTime')}</b>`
			}
			return ''
		},
		issuedOnText() {
			return `${this.$t('text.issuedOn')} ${moment(this.selectedContract?.createDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
				`${moment(this.selectedContract?.createDate).format('HH:mm:ss')}`
		},
		textColor() {
			if (this.isSigned) {
				return '#28500E'
			}
			if (this.toBeSigned) {
				return '#664800'
			}
			if (this.overdue) {
				return '#611C1C'
			}
			return '#DBDBDB'
		},
	},
	methods: {
		hasUnsignedContracts(clientId) {
			return this.navigations.find(x => x.clientId === clientId)?.contracts?.some(x => x.state === 'error')
		},
		openDialog() {
			this.$refs.contractorDialog.open()
		},
		async getContractorByToken() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractsForContractor(null, this.token)
				this.tokenIsValid = true
				this.contractor = result.contractor
				this.contracts = result.contracts
				if (this.contracts.length) {
					this.setActiveLink(this.contracts[0].id)
				}
				this.$nextTick(() => {
					this.openDialog()
				})
			}
			catch (error) {
				this.showError(error.message)
				setTimeout(() => {
					//this.$emit('show-login')
				}, 5000)
			}
			this.loading = false
		},
		setActiveLink(id) {
			this.selectedContract = this.contracts.find(x => x.id === id)
		}
	},
	async mounted() {
		await this.getContractorByToken()
	},
}
</script>

<style lang="scss">
.signing-sidebar {
	.sidebar-title {
		font-size: 15px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: -0.037px;
	}

	&-navigation {
		.v-expansion-panel-title {
			min-height: 60px !important;
			display: block !important;
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: -0.037px;
			padding: 0 28px !important;
			position: relative;

			&__icon {
				position: absolute;
				top: calc(50% + 4px);
				transform: translateY(-50%);
				left: 5px;
			}
		}

		.v-expansion-panel-text__wrapper {
			padding: 0 !important;

			#navlist {
				li {
					padding-left: 28px;
					padding-right: 28px;
					list-style-type: none;
					width: 100%;
					align-items: middle;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.037px;
					color: black;
					height: 60px;
					align-content: center;
					cursor: pointer;
					border: none;

					&.active {
						background-color: #00aeef;
						color: white !important;
						font-weight: 600;
					}
				}
			}
		}
	}
}

.signing-disclousure {
	.v-expansion-panel {
		padding: 0;

		&-title {
			height: 84px;
			padding-right: 64px;
			padding-left: 20px;
			pointer-events: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.10);

			&__icon {
				display: none;
			}

			>div {
				flex: 1;

				&.column {
					display: none;
				}
			}

			.header-accessory {
				display: flex;
				align-items: center;

				>div:first-of-type {
					padding-left: 20px;
					display: flex;
					flex-direction: column;
					flex: 1;

					>span:first-child {
						font-size: 20px;
						font-weight: 600;
						line-height: 25px;
						letter-spacing: -0.196px;
					}

					>span:last-child {
						font-size: 12px;
						font-weight: 500;
						line-height: 16px;
						letter-spacing: 0.001px;
						color: #8F8F8F
					}
				}

				>div:last-child {
					>span {
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 16px;
						letter-spacing: 0.001px;
					}

					.status-chip {
						display: flex;
						height: 24px;
						padding: 0px 8px;
						justify-content: center;
						align-items: center;
						gap: 8px;
						border-radius: 4px;
						font-size: 13px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0.001px;
						color: white;
					}
				}
			}
		}
	}
}
</style>