<template>
	<Disclosure class="TicketInfoNew" v-if="modelValue"
		ref="SECTION_ticketInfo"
		:title="$t('text.tickets')"
		:error="sectionMessage.error"
		:message="sectionMessage.message"
		data-cy="ticketInfo"
	>
		<Field :title="$t('text.taxClass')" :helpText="$t('text.taxClassDesc')" required
			v-if="hasField('ticketInfo','taxClass', modelValue)"
		>
			<TaxClassSelect v-model="modelValue.fields.taxClass.de" :disabled="productIsExternal" />
		</Field>
		<TicketInfoExternalSystem v-if="hasField('ticketInfo','externalSystem', modelValue) && modelValue.fields.ticket.de.fields?.external?.system"
			:modelValue="modelValue"
			:disabled="productIsExternal"
		/>
		<Field :title="$t('text.tickets')" required>
			<div style="display: flex; gap: 10px;">
				<p class="helpText" v-html="$t('text.fromPriceDesc')" />
				<mys-switch v-model="modelValue.fields.fromPriceEnabled.de"
					:label="$t('text.fromPrice')"
					data-cy="fromPrice"
					style="flex-shrink: 0;"
				/>
			</div>
			<div class="ticketOptions" v-if="modelValue.fields.ticket?.de?.fields?.ticketOptions?.de">
				<TicketOption v-for="(ticketOption, ti) in modelValue.fields.ticket.de.fields.ticketOptions.de" :key="ti"
					:ref="'ticketOption' + ti"
					:modelValue="ticketOption"
					:product="modelValue"
					:externalSystem="modelValue.fields.ticket.de.fields?.external?.system"
					:dataCyIndex="ti"
					:allowDelete="modelValue.fields.ticket.de.fields.ticketOptions.de.length > 1"
					@delete="modelValue.fields.ticket.de.fields.ticketOptions.de.splice(ti, 1)"
				/>
			</div>
			<button class="addTicketOptions addPanel" elevation="0" @click="addTicketOption" data-cy="addTicketOption">
				<v-icon>mdi-plus</v-icon>
				{{ $t('text.addTicketOption') }}
			</button>
		</Field>
	</Disclosure>
</template>

<script lang="ts">
import Disclosure from '../../components/common/Disclosure.vue'
import TicketTypeField from './TicketTypeField.vue'
import ReductionField from './ReductionField.vue'
import TaxClassSelect from './TaxClassSelect.vue'
import TicketOption from './TicketOption.vue'
import MysSwitch from '../mys/mys-switch.vue'
import TicketInfoExternalSystem from './TicketInfoExternalSystem.vue'
import Common from '../../mixins/Common.vue'

// TODO: hasField

export default {
	components: { Disclosure, TicketTypeField, ReductionField, TaxClassSelect, TicketOption, MysSwitch, TicketInfoExternalSystem },
	props: {
		modelValue: Object, // Product
	},
	mixins: [Common],
	data: () => ({
		// TODO: we should get rid of this and use our <Section> comp instead
		sectionMessage: {
			error: false,
			message: '',
		},
	}),
	computed: {
		// TODO: do we really need this? or is the whole ticket section disabled for externals anyhow?
		productIsExternal() {
			return this.modelValue?.addl?.isExternal
		},
		valid() {
			return this.validateAllFields()
		},
	},
	watch: {
		modelValue: {
			deep: true,
			handler() { this.$emit('change') },
		},
	},
	methods: {
		addTicketOption() {
			if (!this.modelValue?.fields?.ticket?.de?.fields?.ticketOptions?.de) throw new Error('modelValue is not properly set up')
			const ticketOptions: any[] = this.modelValue.fields.ticket.de.fields.ticketOptions.de
			const ticketPersonalisations = ticketOptions?.[0]?.fields?.ticketPersonalisations?.de ?? []

			// TODO: do we actually need to set an id? we usually only do this when we reference to it - but where do we?
			//const maxIdNr = ticketOptions
			//	.map(to => to.sys.id)
			//	.filter(id => id.startsWith('id_v'))
			//	.map(id => parseInt(id.replace('id_v', '')))
			//	.reduce((r, id) => Math.max(r, id), 0)
			const id = '' //'id_v' + (maxIdNr + 1)
			ticketOptions.push({
				sys: { id },
				fields: {
					title: { de: '' },
					ticketType: { de: null },
					reduction: { de: null },
					price: { de: '' },
					ticketPersonalisations: { de: ticketPersonalisations },
					minimumTickets: { de: 1 },
					maximumTickets: { de: 6 },
					contingentQuantity: { de: 1 },
					fromPriceOption: { de: false },
				},
			})
		},
		// TODO: use childErrorDispatcher / Receiver instead
		validateAllFields() {
			console.log('TIN validateAllFields', this.modelValue)
			if (!this.modelValue) return false
			if (!this.modelValue.fields.taxClass.de?.sys?.id) return false
			const ticket = this.modelValue.fields.ticket.de
			for (let to = 0; to < ticket.fields.ticketOptions.de.length; to++) {
				if (!this.$refs['ticketOption' + to]?.[0]) continue
				if (!this.$refs['ticketOption' + to]?.[0]?.valid) return false
			}
			return true
		},
	},
	beforeMount() {
		if (!this.modelValue) return
		if (!this.modelValue.fields.fromPriceEnabled)
			this.modelValue.fields.fromPriceEnabled = { de: false }
		if (!this.modelValue.fields.ticket?.de?.fields?.ticketOptions?.de || this.modelValue.fields.ticket.de.error)
			this.modelValue.fields.ticket = { de: { sys: { id: '' }, fields: { ticketOptions: { de: [] } } } }
		if (this.hasField('ticketInfo','externalSystem', this.modelValue) && !this.modelValue.fields.ticket.de.fields?.external){
			this.modelValue.fields.ticket.de.fields.external = { system: 'Skidata' }
		}
	},
}
</script>

<style scoped>
.ticketOptions { display: flex; flex-direction: column; gap: 12px; margin-top: 12px; }
.addTicketOptions { margin-top: 12px; }
</style>