<template>
	<div>
		<!-- Mobile App View -->
		<div v-if="view == 'mobile'">
			<MobileAppView :qrCodes="qrCodes"
				:ticketServiceData="ticketServiceData"
				:hasError="hasError"
				@toggle-view="toggleView" 
			/>
		</div>

		<!-- Web App View -->
		<div v-if="view == 'web'">
			<WebAppView :ticketServiceData="ticketServiceData" @toggle-view="toggleView" />
		</div>
	</div>
</template>

<script>
import MobileAppView from './MobileAppView.vue'
import WebAppView from './WebAppView.vue'
import Common from '@/mixins/Common.vue'
import Loading from '@/mixins/Loading.vue'

export default {
	components: { MobileAppView, WebAppView },
	mixins: [ Common, Loading ],
	data: () => ({
		view: 'mobile',
		qrCodes: [],
		ticketServiceData: null,
		hasError: false
	}),
	methods: {
		toggleView() {
			this.view = this.view === 'mobile' ? 'web' : 'mobile'
		},
		async getQRCodes() {
			await this.loadingOverlay(async () => {
				try {
					const response = await this.$httpGet('/validitychecker/qr-codes', {
						'serviceProviderId': this.$store.state.selectedServiceProvider?.sys?.id
					})

					this.qrCodes = response.qrCodes?.length ? response.qrCodes : []
					this.ticketServiceData = response
					this.hasError = false
				} catch (e) {
					this.hasError = true
				}
			}, 'getQRCodes')
		}
	},
	mounted() {
		this.getQRCodes()
	}
}
</script>