export enum AV_TYPES {
  OV = 'OV',
  INCLUSIVE = 'inclusive',
  OPTIONAL = 'optional',
  FIXED = 'fixed',
  MISCONFIGURED = 'misconfigured',
}

export enum DATE_FORMATS {
  DATE = "DD.MM.YYYY",
  DATE_TIME = "DD.MM.YYYY HH:mm",
  YEAR_MONTH_DAY = "YYYY-MM-DD",
  // TODO Add rest of date formats to this enum
}