<template>
	<Step id="misc" icon="mdi-dots-horizontal" :forceStatus="modelValue?.fields?.contentMode?.de == 'ticket' ? 'complete' : undefined">
		<Section id="contactInfo" :open="!ticketOnly" :disabled="ticketOnly">
			<ContactDetailsFieldSet v-model="contactDetails" />
		</Section>
		<Section id="businessInfo" :open="!ticketOnly" :disabled="ticketOnly">
			<!-- TODO: are BusinessHours even relevant for Packages (especially Railaway)? -->
			<!-- TODO: should we cook up a new BusinessHours control based on ColorCalendar?
					would be cool to have a "paintable" calendar, see ProfileColorCalendar.vue
					will the editor be any good?
						would have to prototype
						PROFILES           | CALENDAR
						[A] [ regular  ]   |      ( 1) ( 2) ( 3) ( 4) ( 5) ( 6)
						    10:00 - 12:00  | ( 7) ( 8) ( 9) (10) (11) (12) (13)
					        13:00 - 18:00  | (14) (15) (16) (17) (18) (19) (20)
						[B] [ short    ]   | (21) (22) (23) (24) (25) (26) (27)
						    10:00 - 12:00  | (28) (29) (30)
						[C] [ afternoon]   |
						    13:00 - 18:00  |
			-->
			<!-- TODO: wrapper for BusinessHoursNew
					wrapping for sendData, validateAllFields, etc.
			-->
			<BusinessHoursNew
				:showCopyOption="true"
				:businessHours="businessHours" 
				:averageDurationOfStayInMinutes="averageDurationOfStayInMinutes"
				app="BusinessProfile"
				:expanded="true"
				@toggle-business-hours="toggleHaveBusinessHours" 
				@average-duration-changed="averageDurationChanged"
			/>
			<BusinessHoursProfileCalendar />
		</Section>
		<Section id="publicInfo" :open="!ticketOnly" :disabled="ticketOnly">
			<Field typeName="Package" fieldName="importantInformation" v-model="modelValue.fields.importantInformation" />
		</Section>
		<Section id="generalInfo" :open="!ticketOnly" :disabled="ticketOnly">
			<Field typeName="Package" fieldName="emphasize" v-model="modelValue.fields.emphasize" />
			<FieldSet id="duration">
				TODO: Duration of Stay
				You may specify the duration of a guest's visit that is eligible for a guestcard. You may enter a minimum and a maximum number of days.
				<div style="display: flex; gap: 10px;">
					<Field typeName="Package" fieldName="durationMin" v-model="modelValue.fields.durationMin" style="flex: 1;" />
					<Field typeName="Package" fieldName="durationMax" v-model="modelValue.fields.durationMax" style="flex: 1;" />
				</div>
			</FieldSet>
			<Field typeName="Package" fieldName="faqGroup" v-model="modelValue.fields.faqGroup" />
		</Section>
	</Step>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import ContactDetailsFieldSet from './ContactDetailsFieldSet.vue'
import BusinessHoursNew from '../../../components/businesshours/BusinessHoursNew.vue'
import BusinessHoursProfileCalendar from '../../../components/calendar/BusinessHoursProfileCalendar.vue'

export default {
	components: { Section, FieldSet, Step, ContactDetailsFieldSet, BusinessHoursNew, BusinessHoursProfileCalendar },
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data() {
		return {
			contactDetails: { fields: {
				/*
				streetAddress: { de: 'Lakeside B03' },
				zipCode: { de: '9020' },
				city: { de: 'Klagenfurt' },
				country: { de: 'AT' },
				*/
				streetAddress: { de: '' },
				zipCode: { de: '' },
				city: { de: '' },
				country: { de: 'AT' },
				location: { de: { lat: 0, lon: 0 } },
				phone: { de: '' },
				email: { de: '' },
				website: { de: '' },
			}},
			businessHours: [],
			averageDurationOfStayInMinutes: 0,
		}
	},
	computed: {
		ticketOnly() {
			return this.modelValue.fields.contentMode?.de === 'ticket'
		},
	},
	methods: {
		toggleHaveBusinessHours() {
			//this.modelValue.fields.haveBusinessHours = !this.modelValue.fields.haveBusinessHours
		},
		averageDurationChanged(value) {
			//this.averageDurationOfStayInMinutes = value
		},
	},
}
</script>

<style scoped>
</style>