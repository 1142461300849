<template>
	<div class="DatePicker">
		<v-label v-if="$slots.label">
			<slot name="label"></slot>
		</v-label>
		<div>
			<input type="date" class="input" v-model="model" :min="min" :max="max" :data-cy="dataCy" />
		</div>
		<div class="errors">{{ errors }}</div>
		<slot name="helperText" />
	</div>
</template>

<script>
export default {
	props: {
		modelValue: String,
		min: String,
		max: String,
		errors: String,
		dataCy: String,
	},
	computed: {
		model: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			},
		},
	},
}
</script>

<style scoped>
.input { border: 1px solid #ddd; padding: 4px 8px; border-radius: 4px; background: white; }
.errors { color: var(--error-color); }
</style>