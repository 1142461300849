<template>
	<div :class="{backdrop}" @click="backdropClick">
		<div class="dialog-container">
			<!-- Header -->
			<header class="dialog-header">
				<h2 class="dialog-title">{{ dialogTitle }}</h2>
				<v-btn icon class="close-btn" @click="closeDialog()" data-cy="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</header>

			<v-divider/>

			<!-- Content -->
			<div class="dialog-content">
				<!-- Ticket Header -->
				<div class="ticket-header">
					<h3 class="ticket-name">{{ ticket.ticketInfo.productName }}</h3>
					<div v-if="ticket.contingentInfo.free === 0" class="status-badge redeemed">
						{{ $t('text.redeemed')}}
					</div>
				</div>

				<!-- Ticket Details Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.ticketDetails') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.type') }}</span>
							<span class="value">{{ ticket.ticketInfo.ticketTypeName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.category') }}</span>
							<span class="value">{{ ticket.ticketInfo.consumerGroupName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.price') }}</span>
							<span class="value">{{ $store.state.selectedClient.fields.currency.de }} {{ formatPrice(ticket.ticketInfo.price?.value ? ticket.ticketInfo.price.value / 100 : 0) }}</span>
						</div>
					</div>
				</section>

				<!-- Validity Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.validity') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.validOn') }}</span>
							<span class="value">
								{{ formatDate(ticket.ticketInfo.validityDate) }}
								<template v-if="ticket.ticketInfo.validityEndDate">
									- {{ formatDate(ticket.ticketInfo.validityEndDate) }}
								</template>
							</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.totalUsages') }}</span>
							<span class="value">{{ ticket.contingentInfo.used }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.remainingUsages') }}</span>
							<span class="value">{{ ticket?.guestcard?.guestcardInfo?.masterCard ? 'N/A' : ticket.contingentInfo.free }}</span>
						</div>
					</div>
				</section>

				<!-- Order Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.order') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.name') }}</span>
							<span class="value">{{ ticket.orderInfo.customerFirstName }} {{ ticket.orderInfo.customerLastName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.order') }}</span>
							<span class="value">{{ ticket.orderInfo.orderId }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.orderDate') }}</span>
							<span class="value">{{ ticket.orderInfo.orderDate }}</span>
						</div>
					</div>
				</section>
			</div>

			<v-divider/>

			<!-- Footer -->
			<footer class="dialog-footer">
				<div class="footer-left">
					<input
						v-if="ticket?.guestcard?.guestcardInfo?.masterCard"
						type="number" 
						min="0"
						class="number-input"
						v-model="amount"
						style="width: 180px"
						data-cy="amount-input"
						:placeholder="$t('text.bulkAmount')"
					/>
					<DatePicker
						class="date-picker"
						v-model="date"
						:min="min"
						:max="max"
						dataCy="date-input"
					/>
				</div>

				<div class="footer-right">
					<v-btn v-if="isGuestcard" 
						class="action-btn" 
						prepend-icon="mdi-arrow-left"
						@click="backHandler()"
						data-cy="back"
					>
						{{$t('text.back')}}
					</v-btn>

					<v-btn v-if="!isGuestcard"
						class="action-btn"
						@click="closeDialog()"
						data-cy="cancel"
					>
						{{ $t('text.cancel') }}
					</v-btn>
					
					<v-btn class="action-btn primary"
						:disabled="ticket.contingentInfo.free === 0"
						@click="redeemTicket()"
						data-cy="redeem"
					>
						{{ $t('text.redeem') }}
					</v-btn>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import Dialog from '@/components/common/Dialog.vue'
import DatePicker from '../datePicker/DatePicker.vue';

export default {
	name: 'TicketDetails',
	components: {Loading, Dialog, DatePicker},
	mixins: [Common],

	props: {
		isGuestcard: Boolean,
		gateId: String,
		ticket: Object,
		qrCodeData: String,
		min: String,
		max: String
	},

	data() {
		return {
			loading: false,
			hasError: false,
			isWarning: false,
			isError: false,
			isSuccess: false,
			isLoading: false,
			canRetry: false,
			messageTitle: '',
			messageDetail: '',
			confirmLabel: '',
			cancelLabel: '',
			backLabel: '',
			backdrop: true,
			closeOnOuterClick: false,
			dialogTitle: this.isGuestcard === true ? this.$t('text.validateGuestcard') : this.$t('text.validateTicket'),
			amount: null,
			date: null
		}
	},

	methods: {
		async redeemTicket() {
			this.showLoading()
			try {
				const redemptionResponse = await this.$httpPost('/validitychecker/redeem-ticket',
				{ 
					gateId: this.gateId,
					identificationId: this.ticket.ticketInfo.identificationId,
					qrCodeData: this.qrCodeData,
					clientId: this.$store.state.selectedClient.sys.id,
					amount: this.amount,
					date: this.date,
				})

				this.hideMessage()

				if (redemptionResponse.resultInfo.code > 0) {
					this.showWarning(redemptionResponse.resultInfo.message)
				} else {
					this.ticket.contingentInfo.free = 0
					this.ticket.contingentInfo.used = redemptionResponse.contingentInfo.used
					this.showSuccess()
				}
				
			} catch (e) {
				this.hideMessage()
				this.showGeneralError()
			}
		},
		showMessage(type, title, text, confirmLabel, canRetry) {
			this.$emit('show-message', {type:type, title:title, text:text, confirmLabel:confirmLabel, canRetry:canRetry})
		},
		showGeneralError() {
			this.showMessage('error', this.$t('text.generalErrorTitle'), this.$t('text.errorUnexpected'), this.$t('text.done'), true)
		},
		showInvalidWarning(text) {
			this.showMessage('warning', this.$t('text.invalidErrorTitle'), text, this.$t('text.done'), false)
		},
		showWarning(text) {
			this.showMessage('warning', this.$t('text.invalidErrorTitle'), text, this.$t('text.done'), false)
		},
		showSuccess() {
			this.showMessage('success', this.$t('text.success'), this.$t('text.ticketRedemptionSuccess'), this.$t('text.done'), false)
		},
		showLoading() {
			this.showMessage('loading', this.$t('text.ticketRedemptionProgress'), '', '', false)
		},
		hideMessage() {
			this.$emit('hide-message')
		},
		closeDialog() {
			this.$emit('close-dialog')
		},
		backdropClick() {
			if (this.closeOnOuterClick) {
				this.closeDialog()
			}
		},
		backHandler() {
			this.$emit('view-guestcard')
		},
		cancelHandler() {
			this.reset()
			this.$emit('close-ticket-redemption')
		},
	},
}
</script>

<style scoped lang="scss">
.backdrop {
	position: fixed;
	top: 64px;
	left: 0;
	height: calc(100vh - 64px);
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.dialog-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 900px;
	max-width: calc(100vw - 20px);
	max-height: 90vh;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	z-index: 1000;

	.dialog-header {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.dialog-title {
			font-size: 1.25rem;
			font-weight: 600;
			margin: 0;
		}

		.close-btn {
			color: #666;
		}
	}

	.dialog-content {
		flex: 1;
		overflow-y: auto;
		padding: 24px;

		.ticket-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 24px;

			.ticket-name {
				font-size: 1.5rem;
				font-weight: 600;
				margin: 0;
				flex: 1;
			}

			.status-badge {
				padding: 6px 12px;
				border-radius: 4px;
				font-size: 0.875rem;
				font-weight: 500;

				&.redeemed {
					background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%);
					color: white;
				}
			}
		}

		.details-section {
			margin-bottom: 24px;

			.section-title {
				font-size: 1.1rem;
				font-weight: 600;
				margin-bottom: 16px;
				color: #333;
			}

			.details-grid {
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
				gap: 16px;

				.detail-item {
					display: flex;
					flex-direction: column;
					gap: 4px;

					.label {
						font-size: 0.875rem;
						color: #666;
					}

					.value {
						font-size: 1rem;
						color: #333;
					}
				}
			}
		}
	}

	.dialog-footer {
		padding: 16px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.footer-left {
			display: flex;
			gap: 12px;
		}

		.footer-right {
			display: flex;
			gap: 12px;
		}

		.action-btn {
			min-width: 100px;
			height: 40px;

			&.primary {
				background: #4caf50;
				color: white;

				&:disabled {
					background: #ccc;
				}
			}
		}
	}
}

.number-input {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: revert;
}
</style>