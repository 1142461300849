<template>
	<Disclosure ref="SECTION_ticketSettingsInfo"
		:title="$t('text.personalisation')"
		:error="sectionMessage.error"
		:warning="sectionMessage.warning"
		:message="sectionMessage.message"
		:lock="!userMayEditFields || productIsExternal"
		data-cy="ticketSettingsInfo"
	>
		<Field :title="$t('text.personalisationType')" :helpText="$t('text.personalisationTypeDesc')">
			<v-select variant="outlined" density="compact" hide-details
				v-model="product.fields.personalisationType.de"
				:items="personalisationTypes"
				:item-title="item => $t('text.' + item.value.replace(/\s+/g, ''))"
				:disabled="!hasTicket"
				data-cy="personalisationType"
				@update:modelValue="setDefaultPersonalisations()"
			/>
		</Field>
		<Field :title="$t('text.personalisationFields')"
			v-if="product.fields.personalisationType.de !== personalisationType_no"
		>
			<div>
				<v-btn class="gradientButton" elevation="0" style="width:100%" @click="openDialog()"
					v-if="chips.length === 0"
				>{{ $t('text.choose') }}</v-btn>

				<div class="chips-selector" ref="selector" @click.stop="openDialog" :data-cy="dataCy" v-if="chips.length > 0">
					<!-- TODO: do we have search? if there should be, its currently not working.. -->
					<div class="chips-search">
						<div class="selected-chips">
							<span v-for="(chip, index) in chips" :key="'selected_' + index">{{ chipsLabel(chip) }}</span>
						</div>
					</div>
				</div>
			</div>
		</Field>
		<!-- TODO: why do we need :key="1" here? -->
		<Dialog ref="personalisationFieldsDialog"
			:confirmLabel="$t('text.confirmSelection')"
			:cancelLabel="$t('text.discardChanges')"
			:cancel-handler="cancelDialog"
			:confirm-handler="confirmDialog"
			:closeOnOuterClick="false"
			:showClose="false"
			:title="$t('text.personalisationFields')"
			:key="1"
		>
			<template #content>
				<PersonalisationFields
					ref="personalisationFields"
					:ticketPersonalisations="editingTicketPersonalisations"
					:locales="locales"
					data-cy="personalisationGroups"
				/>
			</template>
		</Dialog>
	</Disclosure>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import PersonalisationFields from './PersonalisationFields.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'

export default {
	components: { Dialog, PersonalisationFields, Disclosure },
	mixins: [ Common ],
	props: {
		product: Object,
		title: String,
		productIsExternal: Boolean,
		dataCy: { type: String, default: undefined },
	},
	data: () => ({
		error: '',
		sectionMessage: { error: false, warning: false, message: '' },
		personalisationType_no: 'No personalisation',
		personalisationTypes: [ { value: 'No personalisation' }, { value: 'Contact person only' }, { value: 'Personalisation for all' } ],
		editingTicketPersonalisations: null,
	}),
	computed: {
		ticketOption() {
			// TODO: why do we use the lastIndex and not the first?
			const lastIndex = this.product.fields.ticket.de.fields.ticketOptions.de.length - 1
			return this.product.fields.ticket.de.fields.ticketOptions.de[ 0 ] //lastIndex ]
		},
		hasTicket() {
			const to = this.ticketOption?.fields
			return to
				&& to?.reduction?.de !== null
				&& to?.ticketType?.de !== null
				&& to?.price?.de !== null && to?.price?.de !== undefined && to?.price?.de !== ''
		},
		chips() {
			const r = []
			const tps = this.ticketOption.fields.ticketPersonalisations?.de
			if (!tps?.length) return []
			for (let ticketPersonalisation of tps) {
				const tpg = ticketPersonalisation.fields?.personalisationGroup?.de
				if (!tpg?.fields) continue
				r.push({ title: tpg.fields.title })
			}
			return r
		},
	},
	watch: {
		hasTicket: {
			immediate: true,
			handler: function (val) {
				if (!val) {
					this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
				}
				else {
					this.resetSectionWarning(this.sectionMessage)
				}
			},
		},
		// copy personalisations to all other ticket options
		'ticketOption.fields.ticketPersonalisations.de': {
			deep: true,
			handler: function (n) {
				for (const to of this.product.fields.ticket.de.fields.ticketOptions.de) {
					if (to.fields.ticketPersonalisations.de == n) continue
					to.fields.ticketPersonalisations.de = n
				}
			},
		},
	},
	methods: {
		chipsLabel(chip) {
			let title = chip.title[this.selectedLocale] ?? chip.title.de
			if (chip.count) return title + ' +' + chip.count
			return title
		},
		openDialog() {
			const ticketPersonalisations = this.ticketOption.fields.ticketPersonalisations.de
			this.editingTicketPersonalisations = JSON.parse(JSON.stringify(ticketPersonalisations))
			this.$refs.personalisationFieldsDialog.show = true
		},
		cancelDialog() {},
		confirmDialog() {
			// TODO: validate, return false if invalid
			this.ticketOption.fields.ticketPersonalisations.de = this.editingTicketPersonalisations
			return true
// TODO: the old solution prevents dialog from closing if invalid (?)
			//const isValid = this.updatePersonalisations()
			//return isValid
		},
		setDefaultPersonalisations() {
			if (this.product.fields.personalisationType.de === 'No personalisation') {
				this.product.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de = []
			}
			else if (this.hasTicket) {
				const namePersonalisationGroup = this.$store.state.coreConfig.fields.personalisationGroups.de.find(x => x.fields?.title?.de === 'Name')
				const hasName = this.product.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de.find(x => x.fields?.personalisationGroup?.de?.fields?.title?.de === 'Name')
				if (!hasName) {
					this.product.fields.ticket.de.fields.ticketOptions.de[0].fields.ticketPersonalisations.de.push({
						sys: { id: '' },
						fields: {
							personalisationGroup: { de: namePersonalisationGroup },
							required: { de: true },
						},
					})
				}
			}
		},
/*
    validateAdditionalOptions() {
      let valid = true
      let additionalPersonalisations = this.$refs.personalisationFields ? this.$refs.personalisationFields.additionalPersonalisations : this.additionalPersonalisations
      let defaultLocale = 'de'
      let key

      if (additionalPersonalisations.length > 0) {
        for (let additionalPersonalisation of additionalPersonalisations) {
          additionalPersonalisation.fields.personalisationGroup.errors = {}
          const inputType = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.inputType.de
          const optionValues = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options ? additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.options.de : []

          if (!this.validateTextLocales(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
            additionalPersonalisation.fields.personalisationGroup.errors["title"] = 'error'
          } else {
            //Copy value to other locales
            if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[defaultLocale] === '') {
              for (key of Object.keys(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
                if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] !== '') {
                  defaultLocale = key
                  break
                }
              }
            }

            for (key of Object.keys(additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label)) {
              if (additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] === '') {
                additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[key] = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label[defaultLocale]
              }
            }
          }

          if (!inputType) {
            additionalPersonalisation.fields.personalisationGroup.errors["inputType"] = 'error'
          } else {
            if (inputType === 'List') {
              if (!optionValues || optionValues.length === 0) {
                additionalPersonalisation.fields.personalisationGroup.errors["optionValues"] = 'error'
              } else {
                for (const optionValue of optionValues) {
                  delete optionValue["errors"]

                  if (!this.validateTextLocales(optionValue.values)) {
                    additionalPersonalisation.fields.personalisationGroup.errors["optionValues"] = 'error'
                    optionValue["errors"] = 'error'
                  } else {
                    //Copy value to other locales
                    if (optionValue.values[defaultLocale] === '') {
                      for (key of Object.keys(optionValue.values)) {
                        if (optionValue.values[key] !== '') {
                          defaultLocale = key;
                          break
                        }
                      }
                    }

                    for (key of Object.keys(optionValue.values)) {
                      if (optionValue.values[key] === '') {
                        optionValue.values[key] = optionValue.values[defaultLocale]
                      }
                    }
                  }
                }
              }
            }
          }

          if (Object.keys(additionalPersonalisation.fields.personalisationGroup.errors).length > 0) {
            valid = false
          }
        }
      }

      if (!valid && this.$refs.personalisationFields) {
        this.$refs.personalisationFields.$forceUpdate()
      }

      return valid
    },
    setAdditionalPersonalisationForRemoval(additionalPersonalisation) {
      for (const ticketPersonalisation of this.ticketOption.fields.ticketPersonalisations.de) {
        if (ticketPersonalisation.fields.personalisationGroup.de.sys.id == additionalPersonalisation.fields.personalisationGroup.de.sys.id &&
            ticketPersonalisation.fields.personalisationGroup.de.fields.title == additionalPersonalisation.fields.personalisationGroup.de.fields.title) {
          ticketPersonalisation.addl = 'remove'
          additionalPersonalisation.addl = 'remove'
          break
        }
      }
    },
		// TODO: this should be much simpler
    updatePersonalisations() {
      let valid = this.validateAdditionalOptions()

      if (valid) {
        if (!this.ticketOption.fields.ticketPersonalisations?.de) {
          this.ticketOption.fields.ticketPersonalisations = { de: [] }
        }
        //Remove additional personalisations that were set for removal
        this.ticketOption.fields.ticketPersonalisations.de = this.ticketOption.fields.ticketPersonalisations.de.filter((pg) => !pg.addl || pg.addl !== 'remove')

        //Set remaining personalisations
        this.fixedPersonalisationGroups = this.$refs.personalisationFields.fixedPersonalisationGroups
        this.additionalPersonalisations = this.$refs.personalisationFields.additionalPersonalisations

        //Fixed Personalisation Groups
        for (const fixedPersonalisationGroup of this.fixedPersonalisationGroups) {
          let tpIndex = this.ticketOption.fields?.ticketPersonalisations?.de?.findIndex(x => x.fields?.personalisationGroup?.de?.sys?.id === fixedPersonalisationGroup.sys.id)

          if (tpIndex < 0) {
            if (fixedPersonalisationGroup.addl.checked) {
              this.ticketOption.fields.ticketPersonalisations.de.push(
                  {
                    sys: {id: this.ticketOption.fields.ticketPersonalisations.de.length + 1},
                    fields: {
                      personalisationGroup: {de: fixedPersonalisationGroup},
                      required: {de: fixedPersonalisationGroup.addl.required}
                    }
                  }
              )
            }
          } else {
            if (!fixedPersonalisationGroup.addl.checked) {
              this.ticketOption.fields.ticketPersonalisations.de.splice(tpIndex, 1)
            } else {
              //Check required
              this.ticketOption.fields.ticketPersonalisations.de[tpIndex].fields.required = {de: fixedPersonalisationGroup.addl.required}
            }
          }
        }

        //Additional Personalisation Groups
        for (const additionalPersonalisation of this.additionalPersonalisations) {
          let adIndex = this.ticketOption.fields?.ticketPersonalisations?.de?.findIndex(x => x.sys?.id === additionalPersonalisation.sys.id)

          if (adIndex < 0) {
            //Add New
            additionalPersonalisation.fields.personalisationGroup.de["sys"] = {id: this.additionalPersonalisations.length}
            additionalPersonalisation.fields.personalisationGroup.de.fields.title = additionalPersonalisation.fields.personalisationGroup.de.fields.personalisationFields.de[0].fields.label
            this.ticketOption.fields.ticketPersonalisations.de.push(additionalPersonalisation)
          }
        }
      }

      return valid
    },
*/
	},
	async mounted() {
		if (!this.ticketOption) return
		// make sure, the "Name" field is selected
		const groups = this.$store.state.coreConfig.fields.personalisationGroups.de.filter((pg) => pg.fields?.title.de === 'Name')
		let nameId = groups?.[0]?.sys.id
		if (nameId) {
			const pgs = this.ticketOption.fields.personalisationGroups?.de
			if (pgs && !pgs.includes(nameId))
				pgs.push(nameId)
		}
	},
}
</script>

<style scoped lang="scss">
h4 { color: #000000; font-size: 14pt; text-align: left; font-weight: bold; }
.personalisationGroupCol { height: 38px; border-radius: 4px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); border: solid 1px rgba(0, 0, 0, 0.1); background-color: #ffffff; }
.personalisationGroupCol label, .timeframe-contingent-row label { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.v-input--selection-controls { margin-top: -10px !important; }

.chips-selector {
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all .3s cubic-bezier(.25, .8, .5, 1);
  cursor: pointer;

  &:hover { border: 1px solid rgba(0, 0, 0, 0.86); }

  .chips-search {
    i {
      position: absolute;
      right: 0;
      top: 0;
      height: 48px;
      width: 48px;
      cursor: pointer;
      transition: all .3s cubic-bezier(.25, .8, .5, 1);

      &.rotated { transform: rotate(180deg); }
    }
  }

  .selected-chips {
    min-height: 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
      border: thin #dddddd solid !important;
      color: #000;
      padding: 7px 18px;
      border-radius: 10px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 36px;
      align-items: center;
      background: rgb(245, 245, 245);
      background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
      cursor: pointer;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .error { border-color: red; }
}
</style>