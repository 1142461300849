<template>
  <v-container fluid class="pa-0 mb-12">
	
	<!-- Guestcard Overview -->
	<GuestcardOverview 
		v-if="isGuestcard && view === 'overview'" 
		:show="showGuestcard"
		:ticketServiceResponse="ticketServiceResponse" 
		:gateId="gateId" 
		:qrCodeData="qrCodeData" 
		@close-dialog="cancelHandler" 
		@show-message="displayMessage"
		@view-ticket="viewTicketDetails"
	/>

	<!-- Multiple Tickets List -->
	<div v-if="!isGuestcard && view === 'overview' && ticketServiceResponse.tickets?.length > 1" class="tickets-list">
		<h3>{{ $t('text.selectTicket') }}</h3>
		<v-list>
			<v-list-item
				v-for="ticket in ticketServiceResponse.tickets.filter(t => t.ticketInfo.ticketIsValid)"
				:key="ticket.ticketInfo.identificationId"
				@click="viewTicketDetails(ticket)"
				class="ticket-item"
			>
				<v-list-item-title>{{ ticket.ticketInfo.productName }}</v-list-item-title>
				<v-list-item-subtitle>
					{{ ticket.ticketInfo.ticketTypeName }} - 
					{{ $t('text.remainingUsages') }}: {{ ticket.contingentInfo.free }}
				</v-list-item-subtitle>
			</v-list-item>
		</v-list>
	</div>

	<TicketDetails ref="ticketDetails" 
		v-if="view === 'ticketDetails'" 
		:isGuestcard="isGuestcard"
		:ticket="ticket" 
		:gateId="gateId" 
		:qrCodeData="qrCodeData" 
		:min="ticketServiceResponse.guestcard.guestcardInfo.validityStartDate"
		:max="ticketServiceResponse.guestcard.guestcardInfo.validityEndDate"
		@close-dialog="cancelHandler"
		@show-message="displayMessage"
		@hide-message="hideMessage"
		@view-guestcard="viewGuestcard"
	/>
	
	<!-- Message Dialog -->
	<MessageDialog 
		:show="showMessage"
		:confirmLabel="confirmLabel"
		:cancelLabel="this.$t('text.cancel')"
		:confirm-handler="confirmHandler"
		:cancel-handler="closeMessage"
		:back-handler="backHandler"
		title=""
		:type="messageType"
		:messageTitle="messageTitle"
		:messageText="messageText" 
		:showRetry="canRetry">
	</MessageDialog>
  </v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'
import MessageDialog from './MessageDialog.vue'
import GuestcardOverview from './GuestcardOverview.vue'
import TicketDetails from './TicketDetails.vue'

export default {
	name: 'TicketRedemption',
	components: {MessageDialog, GuestcardOverview, TicketDetails},
	mixins: [Common],

	props: {
		isGuestcard: Boolean,
		ticketServiceResponse: Object,
		gateId: String,
		qrCodeData: String
	},

	data() {
		return {
			canRetry: false,
			messageType: '',
			messageTitle: '',
			messageText: '',
			confirmLabel: '',
			cancelLabel: '',
			
			showGuestcard: false,
			showMessage: false,

			view: 'overview',
			ticket: null,
		}
	},

	mounted() {
		this.showGuestcard = this.isGuestcard && this.view === 'overview'

		if (!this.showGuestcard) {
			// If single ticket, show details directly
			if (this.ticketServiceResponse.tickets.length === 1) {
				this.viewTicketDetails(this.ticketServiceResponse.tickets[0])
			}
			// Otherwise, show tickets list in overview
		}
	},

	methods: {
		viewGuestcard() {
			this.view = 'overview'
		},
		viewTicketDetails(ticket) {
			this.ticket = ticket
			this.view = 'ticketDetails'
		},
		displayMessage(value) {
			this.messageType = value.type
			this.messageTitle = value.title
			this.messageText = value.text
			this.canRetry = value.canRetry
			this.confirmLabel = !value.canRetry ? value.confirmLabel : ''
			this.showMessage = true
		},
		hideMessage() {
			this.showMessage = false
		},
		confirmHandler() {
			if (this.view === 'overview') {
				this.showMessage = false
			} else {
				if (!this.canRetry) {
					this.showMessage = false
				} else {
					this.$refs.ticketDetails.redeemTicket()
				}
			}
		},
		cancelHandler() {
			this.view = 'overview'
			this.showGuestcard = false
			this.$emit('close-dialog')
		},
		backHandler() {
			this.view = 'overview'
			this.confirmLabel = this.$t('text.done')
			this.cancelLabel = this.$t('text.cancel')
		},
		closeMessage() {
			this.showMessage = false
		},
	},
}
</script>

<style scoped lang="scss">
.title { font-size: 24pt; color: #000000; font-weight: bold; }
.generalLabel { color: #999999 !important; font-size: 12pt;}
.fieldLabel { font-weight: bold; font-size: 12pt; line-height: 2;}
.fieldValue { font-size: 12pt; line-height: 2;}
.helpLabel { font-size: 10.5pt;}
.nameLabel { font-weight: bold; font-size: 1.25rem;}
.ticketRow {  cursor: pointer }
.ticketRow:hover { background-color: #f4f4f4; }
.theme--light.v-divider { border-color: #999999 !important; }
.redeemedLabel {
  height: 32px !important;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%) !important;
  border: thin #088ec0 solid !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 11pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  color: #ffffff;
  padding: 10px;
}

tickets-list {
	padding: 20px
}

.ticket-item {
	cursor: pointer;
	border-radius: 4px;
	margin-bottom: 8px;

	&:hover {
		background-color: #f5f5f5;
	}
}
</style>