<template>
	<div class="FilterCombo" @click="onFilterClick">
		<div class="search" :class="{ focus }">
			<div class="openDetail" @click="detailOpen = true; $refs.input.focus()" data-cy="filter-openDetail">
				<v-icon>mdi-filter</v-icon>
				Filter
			</div>
			<div class="item FilterItem" v-if="showContentTypeSelector" data-cy="filteritem-contentType">
				<label>Content Type</label>
				<select
					v-model="modelValue.contentType"
					@click="$event.captured = true"
					@change="$emit('input', modelValue)"
					:style="{ width: ((modelValue.contentType ? modelValue.contentType.length : 4) + 7) + 'ch' }"
				>
					<option :value="null">{{ allowedContentTypes ? ('Any (' + allowedContentTypes.length + ')') : 'Any' }}</option>
					<option v-for="allowedType of allowedContentTypesWithFallback" :key="allowedType" :value="allowedType">
						{{ typeNameForTypeId(allowedType) }}
					</option>
				</select>
			</div>
			<FilterItem class="item FilterItem" v-for="filter of modelValue.filters" :key="filter.id"
				:filter="filter"
				@click="filterSelected = filter;"
				:class="{ selected: filterSelected == filter }"
			/>
			<input type="text" data-cy="filter-input"
				:placeholder="placeholder"
				autocomplete="off"
				v-model="modelValue.search"
				@keyup="onInputKeyup"
				@change="$emit('input', modelValue)"
				@click="filterSelected = null"
				style="flex: 1 1 auto; min-width: 200px;"
				ref="input"
			/>
		</div>
		<div class="filterDetail" v-if="modelValue.filters && detailOpen && filteredFields?.length" ref="filterDetail" data-cy="filter-detail">
			<table>
				<!--
				<tr><th><label>Field</label></th><th>Content Type</th><th>Description</th></tr>
				-->
				<tr v-for="(field, f) of filteredFields" :key="field.contentType + '.' + field.id"
					:class="{
						[ 'type_' + field.contentType ]: true,
						selected: f == selectionIndex,
					}"
					@click="addFilter(field)"
					:data-cy="'filter-field-' + field.contentType + '.' + field.id"
					:ref="f == selectionIndex ? 'selectedField' : undefined"
				>
					<td><label>{{ field.id }}</label></td>
					<!-- <td>{{ typeNameForTypeId(field.contentType) }}</td> -->
					<td>{{ field.description }}</td>
				</tr>
			</table>
			<!--
			<div class="help">
				<div>
					Note that only certain field types are supported for filtering.
				</div>
			</div>
			-->
		</div>
	</div>
</template>

<script lang="ts">
// NOTE: this has mostly been copied over from Content-Hub UI

import FilterItem from './FilterItem.vue'

export type Filter = {
	id: number
	field: string
	scope: string
	type: string
	itemType: string
	in: string[] | { [key: string]: string } | null
	mode: string
	value: any
	query: { k: string, v: string }
	focus: boolean
	storage: string[] | null,
	modes: string[] | null,
}

export function newFilterForFieldOrName(fields, fieldNameOrField): Filter | undefined {
	let field = fieldNameOrField
	if (typeof fieldNameOrField == 'string') {
		// TODO: this will often call getFields... should we rather pass in the fields?
		field = fields.find(f => f.id == fieldNameOrField)
		if (!field) return
	}

	// TODO: set mode dependent on type
	let value: any = ''
	if (field.type == 'Date') value = new Date().toISOString().substring(0, 10)
	if (field.type == 'Number') value = 1
	if (field.type == 'Boolean') value = 'Yes'
	// TODO: my intention of this object is to persist it later.
	//       but adding the field.in does not fit with that. actually we need to add that in some other way.
	//       maybe by having a method getIn(type, fieldId) here and pass that as a param? 
	let mode = 'eq'
	if (field.type == 'SymbolList') mode = 'in'
	if (field.type == 'Array') mode = 'in'
	return {
		id: Math.random(),
		field: field.id,
		scope: field.scope,
		type: field.type,
		itemType: field.itemType,
		in: field.in,
		mode,
		value,
		query: { k: '', v: '' },
		focus: true,
		storage: null,
		modes: field.modes,
	}
}

export type Field = {
	id: string
	contentType: string
	description: string
	type: string
	scope: string
	in?: { [ key: string ]: string } | string[]
	modes?: string[]
}

export default {
	components: { FilterItem },
	props: {
		locale: String,
		// { contentType: '', search: '' }
		modelValue: { type: Object, default: () => {} },
		def: Object,
		allowedContentTypes: Array,
		placeholder: { type: String, default: 'Type to search' },
		// TODO: better examples
		// { id: 'width', contentType: '_all', description: 'Media width', type: 'Number', scope: 'sys' },
		// { id: 'file.contentType', contentType: '_all', description: 'File Type', type: 'Symbol', scope: 'fields', in: { 'image/png': 'png', 'image/jpeg': 'jpg', 'image/svg': 'svg', 'video/mp4': 'mp4', 'audio/mp3': 'mp3', 'audio/mpeg': 'mpeg audio', 'application/xml': 'xml', 'application/pdf': 'pdf' } },
		// { id: 'status', contentType: '_all', description: 'Current status of the entry', type: 'Symbol', scope: 'sys', in: { any: 'Any (except Archived)', published: 'Published', changed: 'Changed', draft: 'Draft', /*archived: 'Archived'*/ } },
		fields: { type: Array<Field>, default: () => [] },
	},
	data: () => ({
		focus: false,
		detailOpen: false,
		filterSelected: null,
		selectionIndex: 0,
	}),
	computed: {
		showContentTypeSelector() {
			return this.modelValue.contentType !== undefined
		},
		filteredFields() {
			return this.fields.filter(field => {
				// TODO: also search in name + description? CF does not
				if (this.modelValue.search && field.id.toLowerCase().indexOf(this.modelValue.search.toLowerCase()) < 0) return false
				if (this.modelValue.contentType && field.contentType != '_all' && this.modelValue.contentType != field.contentType) return false

				// TODO: actually implement these at the server and then activate
				//if (field.type == 'Number') return false
				//if (field.type == 'Date' && field.contentType != '_all') return false
				if (field.type == 'Link' && field.contentType != '_all') return false
				if (field.type == 'SymbolList' && ![ 'channels' ].includes(field.id) && field.contentType != '_all') return false
				if (field.type == 'Array' && ![ 'storyCategories' ].includes(field.id) && field.contentType != '_all') return false
				if (field.type == 'Boolean' && field.contentType != '_all') return false
				if (field.type == 'Object' && field.contentType != '_all') return false
				if (field.type == 'Asset' && field.contentType != '_all') return false

				return true
			})
		},
		allowedContentTypesWithFallback() {
			if (!this.allowedContentTypes?.length) {
				return Object.values(window['typeLookup']).map(t => t.sys.id)
					// TODO: filter by some kind of "hidden" prop instead
					.filter(t => ![ 'contentHubAsset', 'x_graph', 'x_graph_node' ].includes(t))
					.sort((a, b) => a.localeCompare(b))
			}
			return this.allowedContentTypes
				// TODO: filter by some kind of "hidden" prop instead
				.filter(t => ![ 'contentHubAsset', 'x_graph', 'x_graph_node' ].includes(t))
				.filter(e => e).sort((a, b) => a.localeCompare(b))
		},
	},
	watch: {
		filterSelected(n) {
			if (!n) return
			// whenever we select an item, we remove focus from the input
			this.$refs.input.blur()
		},
		value: {
			deep: true,
			handler() {
				this.resetSelection()
			},
		},
		openDetail() {
			this.resetSelection()
		},
	},
	methods: {
		addFilter(field) {
			this.modelValue.filters.push(newFilterForFieldOrName(this.fields, field))
			if (!this.modelValue.contentType && field.contentType != '_all') {
				this.modelValue.contentType = field.contentType
			}
			this.modelValue.search = ''
			// TODO: set input focus on new field - how?
			//       the fields are actually part of the sub components
		},
		onFilterClick(event) {
			event.owner = this
			if (!this.focus) {
				this.focus = true
				if (!this.filterSelected && !event.captured) {
					this.$refs.input.focus()
				}
			}
		},
		resetSelection() {
			this.selectionIndex = -1
			if (this.$refs.filterDetail)
				this.$refs.filterDetail.scrollTo(0, 0)
		},
		onInputKeyup(event) {
			event.captured = true

			if (event.key == 'Escape') this.detailOpen = false
			else if (event.key == 'ArrowDown' && this.selectionIndex < this.filteredFields.length-1) {
				this.selectionIndex++
				if (this.$refs.selectedField?.[0])
					this.$refs.selectedField[0].scrollIntoView(false)
				if (this.selectionIndex > 3)
					this.$refs.filterDetail.scrollBy(0, 100)
				window.scrollTo(0, 0)
			}
			else if (event.key == 'ArrowUp' && this.selectionIndex > 0) {
				this.selectionIndex--
				if (this.$refs.selectedField?.[0])
					this.$refs.selectedField[0].scrollIntoView(false)
				if (this.selectionIndex > 3)
					this.$refs.filterDetail.scrollBy(0, 50)
				window.scrollTo(0, 0)
			}
			else if (event.key == 'Enter' && this.selectionIndex > -1) {
				this.addFilter(this.filteredFields[this.selectionIndex])
			}
			else {
				this.detailOpen = this.modelValue.search != ''
			}

			// when we did not delete anything from the field, move the cursor to the last item
			// TODO: ideally: if the cursor is at 0 and not if == ''
			if (event.key == 'Backspace' && this.modelValue.search == '' && this.modelValue.filters && this.modelValue.filters.length > 0) {
				this.filterSelected = this.modelValue.filters[this.modelValue.filters.length - 1]
			}
		},
		onWindowClick(event) {
			if (event.owner == this) return
			this.focus = false
			this.detailOpen = false
			this.filterSelected = null
		},
		// backspace to delete
		onWindowKeyup(event) {
			if (!this.focus) return
			if (!this.filterSelected) return
			if (event.captured) return
			if (event.key != 'Backspace') return
			if (event.target.nodeName == 'INPUT') return
			const i = this.modelValue.filters.indexOf(this.filterSelected)
			this.modelValue.filters.splice(i, 1)
			if (i > 0)
				this.filterSelected = this.modelValue.filters[i-1]
			else if (this.modelValue.filters.length > 0)
				this.filterSelected = this.modelValue.filters[0]
			else
				this.$refs.input.focus()
		},
		typeNameForTypeId(id) {
			if (id == '_all') return 'All Content Types'
			return window['typeLookup'][id]?.name
		},
	},
	mounted() {
		window.addEventListener('click', this.onWindowClick)
		window.addEventListener('keyup', this.onWindowKeyup)
	},
	destroyed() {
		window.removeEventListener('click', this.onWindowClick)
		window.removeEventListener('keyup', this.onWindowKeyup)
	},
}
</script>

<style scoped>
.FilterCombo { --primary: var(--color-blue); --color-element-light: #ebebeb; }

.search { height: 45px; min-height: 45px; overflow: hidden; width: 100%; background-color: rgb(255, 255, 255); border: 1px solid rgb(207, 217, 224); border-radius: 6px; padding: 3px 50px 0 3px; display: flex; flex-wrap: wrap; width: 100%; box-sizing: border-box; position: relative; }
.search.focus { height: auto; border-color: var(--primary); outline: 3px solid rgb(152 203 255); }
.search select { -webkit-appearance: none; border: 0; border-radius: 5px; border-radius: 0 6px 6px 0; font-size: 14px; padding: 7px; margin-left: 5px; }
.search input { outline: none; margin-left: 5px; border: none; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; color: rgb(65, 77, 99); font-family: var(--font-stack-primary); font-size: 0.875rem; line-height: 1.25rem; padding: 5px 0; }
.openDetail { position: absolute; right: 15px; top: 8px; z-index: 1; color: var(--primary); cursor: pointer; }
.openDetail svg { width: 18px; height: 18px; margin-right: 5px; fill: currentcolor; vertical-align: bottom; }

.item { position: relative; margin-right: 6px; margin-bottom: 3px; font-size: 14px; padding-left: 10px; font-weight: normal; border-radius: 6px; }
.item > select { height: 100%; }
.item.selected { outline: 1px solid var(--primary); }

.filterDetail { position: absolute; background: white; border: 1px solid var(--primary); font-size: 14px; border-radius: 6px; overflow: hidden; margin-top: 8px; width: 100%; max-height: 320px; overflow-y: auto; }
.filterDetail table { border-spacing: 0; width: 100%; }
.filterDetail th,
.filterDetail td { padding: 15px; color: rgb(65, 77, 99); }
.filterDetail tr.selected td { background: rgb(3, 111, 227, 0.05); }
.filterDetail tr.type__all td { font-weight: 600; }
.filterDetail td { border-top: 1px solid rgb(231, 235, 238); cursor: pointer; }
.filterDetail th { font-weight: 600; font-size: 0.75rem; text-align: left; background-color: rgb(247, 249, 250); }
.filterDetail td label { font-weight: normal; background: rgb(231, 235, 238); padding: 10px 8px; border-radius: 4px; }
.filterDetail tr:hover td { background: rgb(247, 249, 250); }
.filterDetail .help { display: flex; -webkit-box-align: center; align-items: center; background: rgb(232, 245, 255); border-top: 1px solid rgb(231, 235, 238); padding: 0.75rem 1rem; }
</style>