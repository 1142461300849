<template>
	<table class="PriceMatrix" v-if="modelValue && ticketCategories && ticketTypes">
		<tr>
			<th></th>
			<th v-for="ticketCategory, tc of activeTicketCategories" :key="ticketCategory.sys.id">
				<div class="fixedWidth">
					<ReductionField dense
						v-model="activeTicketCategories[tc]"
						:serviceProviderOptionsOverride="availableTicketCategories"
						:canAddNew="false"
					>
						<template #append-item>
							<v-btn @click="removeTicketCategory(ticketCategory)" variant="text"><v-icon>mdi-minus</v-icon> {{ $t('text.remove') }}</v-btn>
						</template>
					</ReductionField>
				</div>
			</th>
			<th>
				<v-icon @click="addTicketCategory()" v-if="activeTicketCategories.length < availableTicketCategories.length">mdi-plus</v-icon>
			</th>
		</tr>
		<tr v-for="ticketType, tt of activeTicketTypes" :key="ticketType.sys.id">
			<th>
				<div class="fixedWidth" style="width: 120px">
					<!-- TODO: pass availableTicketCategories as options -->
					<TicketTypeField dense
						v-model="activeTicketTypes[tt]"
						:serviceProviderOptionsOverride="availableTicketTypes"
						:canAddNew="false"
					>
						<template #append-item>
							<v-btn @click="removeTicketType(ticketType)" variant="text"><v-icon>mdi-minus</v-icon> {{ $t('text.remove') }}</v-btn>
						</template>
					</TicketTypeField>
				</div>
			</th>
			<td v-for="ticketCategory of activeTicketCategories" :key="ticketCategory.sys.id"
				:data-ticketType="ticketType.sys.id"
				:data-ticketCategory="ticketCategory.sys.id"
			>
				<!-- TODO: model driven field?
				<input type="number"
					v-if="baseMatrix[ keyFor(ticketCategory, ticketType) ]"
					v-model="model[ keyFor(ticketCategory, ticketType) ].price"
				/>
				-->
				<!-- TODO: we may want to move this block to a dedicated comp to isolate the key concern -->
				{{ ensurePriceModel(ticketCategory, ticketType) }}
				<NumberField class="field"
					v-if="model[ keyFor(ticketCategory, ticketType) ] && baseMatrix[ keyFor(ticketCategory, ticketType) ]"
					v-model="model[ keyFor(ticketCategory, ticketType) ].price"
					:unit="currency"
					:min="0"
					:disabled="disabled"
					:field="dummyFieldDefinition"
					width="100%"
				/>
			</td>
			<td></td>
		</tr>
		<tr v-if="activeTicketTypes.length < ticketTypes.length">
			<th>
				<v-icon @click="addTicketType()" v-if="activeTicketTypes.length < availableTicketTypes.length">mdi-plus</v-icon>
			</th>
			<td :colspan="ticketCategories.length + 1" />
		</tr>
	</table>
</template>

<script lang="ts">
import NumberField from '../fields/NumberField.vue'
import ReductionField from '../serviceDesigner/ReductionField.vue'
import TicketTypeField from '../serviceDesigner/TicketTypeField.vue'
import { TicketPrice } from '../../../../shared/seasonal-prices'

type Matrix = { [ key: string ]: TicketPrice }

export const matrixCalculatorMixin = {
	props: {
		basePrices: Array,
		disabled: Boolean,
	},
	data: () => ({
		model: {}, // Matrix
		// these can not be computed as we will push into them
		activeTicketCategories: [],
		activeTicketTypes: [],
	}),
	computed: {
		modelMatrix() {
			return this.toMatrix(this.modelValue)
		},
		baseMatrix() {
			return this.toMatrix(this.basePrices)
		},
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
		availableTicketCategories() {
			return this.getTicketOptionsForMatrix('ticketCategory', this.baseMatrix)
		},
		availableTicketTypes() {
			return this.getTicketOptionsForMatrix('ticketType', this.baseMatrix)
		},
	},
	methods: {
		keyFor(ticketCategory, ticketType) {
			return (ticketCategory?.sys?.id ?? ticketCategory) + '|' + (ticketType?.sys?.id ?? ticketType)
		},
		toMatrix(value: TicketPrice[]): Matrix {
			if (!value) return {}
			const r: Matrix = {}
			value.forEach((price: TicketPrice) => {
				r[ this.keyFor(price.ticketCategory, price.ticketType) ] = price
			})
			return r
		},
		// TODO: if the value changes, we should maintain "hidden" prices
		//       dont return them in toValue though
		//       CURRENTLY hidden prices are sent to the server
		//       maybe that is fine? the server must only ignore them when sending to peak..
		//       could be confusing though if some old price shows up later..
		toModel(value: TicketPrice[]): Matrix {
			const r: Matrix = this.toMatrix(value)
			// add missing prices (prices defined in the basePrices but not in the model)
			this.basePrices?.forEach?.((price: TicketPrice) => {
				if (r[ this.keyFor(price.ticketCategory, price.ticketType) ]) return
				r[ this.keyFor(price.ticketCategory, price.ticketType) ] = { ...price, price: null }
			})
			return r
		},
		toValue(model: Matrix): TicketPrice[] {
			// filter out any non-active categories/types
			return Object.values(model)
				.filter((price: TicketPrice) => this.activeTicketCategories.find(o => o.sys.id == price.ticketCategory))
				.filter((price: TicketPrice) => this.activeTicketTypes.find(o => o.sys.id == price.ticketType))
		},
		// with this trick we make sure, that the model contains proper objects as they are needed
		ensurePriceModel(ticketCategory, ticketType) {
			const key = this.keyFor(ticketCategory, ticketType)
			if (!this.model[ key ]) this.model[ key ] = { ticketCategory, ticketType, price: null }
		},
		getTicketOptionsForMatrix(type: 'ticketCategory' | 'ticketType', matrix: Matrix, andMatrix?: Matrix): any[] {
			const all: string[] = Object.values(matrix).map((price: TicketPrice) => price?.[type])
			const and: string[] | null= andMatrix ? Object.values(andMatrix).map((price: TicketPrice) => price?.[type]) : null
			let collection: any[] = []
			if (type == 'ticketCategory') collection = this.ticketCategories
			if (type == 'ticketType') collection = this.ticketTypes
			return collection.filter(o => all.includes(o.sys.id) && (!and || and.includes(o.sys.id)))
		},
	},
	mounted() {
		this.model = this.toModel(this.modelValue)

		//this.availableTicketCategories = this.getTicketOptionsForMatrix('ticketCategory', this.baseMatrix)
		this.activeTicketCategories = this.getTicketOptionsForMatrix('ticketCategory', this.modelMatrix, this.baseMatrix)
		//this.availableTicketTypes = this.getTicketOptionsForMatrix('ticketType', this.baseMatrix)
		this.activeTicketTypes = this.getTicketOptionsForMatrix('ticketType', this.modelMatrix, this.baseMatrix)
	},
}

export default {
	mixins: [ matrixCalculatorMixin ],
	components: { ReductionField, TicketTypeField, NumberField },
	props: {
		ticketCategories: Array,
		ticketTypes: Array,
		// [ { ticketCategory: 'adult', ticketType: '1', price: 0 }, ... ]
		modelValue: Array,
	},
	data: () => ({
		dummyFieldDefinition: { validations: [] },
	}),
	watch: {
		model: {
			handler(n, o) {
				const v = this.toValue(n)
				if (JSON.stringify(o) == JSON.stringify(v)) return
				this.$emit('update:modelValue', this.toValue(n))
			},
			deep: true,
		},
		modelValue(n, o) {
			if (JSON.stringify(n) == JSON.stringify(o)) return
			this.model = this.toModel(n)
		},
	},
	methods: {
		// if no id is passed, add the first available category/type
		addTicketCategory(id: string = undefined) {
			const tc = this.availableTicketCategories.find(o => !id && !this.activeTicketCategories.includes(o) || id == o.sys.id)
			if (!tc) return
			this.activeTicketCategories.push(tc)
		},
		addTicketType(id: string = undefined) {
			const tt = this.availableTicketTypes.find(o => !id && !this.activeTicketTypes.includes(o) || id == o.sys.id)
			if (!tt) return
			this.activeTicketTypes.push(tt)
		},
		removeTicketCategory(ticketCategory) {
			this.activeTicketCategories = this.activeTicketCategories.filter(o => o != ticketCategory)
			// TODO: this doesnt work yet - should we even remove the data from the working model?
			//       i think we should only remove from the outbound model..
			//for (const p in this.modelValue) {
			//	if (p.startsWith(ticketCategory.sys.id) + '|') delete this.modelValue[p]
			//}
		},
		removeTicketType(ticketType) {
			this.activeTicketTypes = this.activeTicketTypes.filter(o => o != ticketType)
			//for (const p in this.modelValue) {
			//	if (p.endsWith('|' + ticketType.sys.id)) delete this.modelValue[p]
			//}
		},
	},
	mounted() {
		// for empty model, add one category and one type, so the ui opens with a 1-cell-table
		if (this.modelValue && this.modelValue.length == 0) {
			this.addTicketCategory(this.basePrices[0]?.ticketCategory)
			this.addTicketType(this.basePrices[0]?.ticketType)
		}
	},
}
</script>

<style scoped>
input { background: white; max-width: 90px; padding: 2px 4px; border: 1px solid #ddd; border-radius: 4px; }

table { border-collapse: collapse; }
th { text-align: left; }
th,
td { white-space: nowrap; padding: 8px; }

/* borders */
th,
td { border: 1px solid #ddd; }
th:first-child,
td:first-child { border-left-color: transparent; }
th:last-child,
td:last-child { border-right-color: transparent; width: 100% !important; }
tr:first-child th,
tr:first-child td { border-top-color: transparent; }
tr:last-child th,
tr:last-child td { border-bottom-color: transparent; }

.fixedWidth { width: 80px; overflow: hidden; text-overflow: ellipsis; }

.field { padding: 0; }
</style>