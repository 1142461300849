<template>
	<v-row align="center" :justify="showButtons ? 'space-between' : 'center'" class="StepBar">
		<v-btn v-if="showButtons && !hideGoBack" id="backBtn" class="ml-1 text-capitalize" variant="outlined" @click="goBack" >
			<v-icon class="mr-1">mdi-arrow-left</v-icon>
			{{ $t('text.back') }}
		</v-btn>
		<ul v-if="steps?.length" class="steps">
			<li class="step" v-for="(step, index) of steps" :key="step.id"
				@click="show(step)"
				:data-cy="`step-bar-${step.id}`"
				:class="{
					complete: step.status == 'complete',
					error: step.status == 'error',
					current: step.id == currentStepId,
					disabled: step.disabled,
				}"
			>
				<span class="divider" v-if="showDividers === true && index < steps.length-1"></span>
				<span class="icon">
					<v-icon v-if="step.icon" size="14" color="white">{{ step.icon }}</v-icon>
				</span>
				<span class="label">{{ $t('text.' + step.id) }}</span>
			</li>
		</ul>
		<v-btn v-if="showButtons && !hideGoNext" id="nextBtn" class="mr-7 text-capitalize next-btn" variant=outlined @click="goNext" >
			{{ $t('text.next') }}
			<v-icon class="ml-1">mdi-arrow-right</v-icon>
		</v-btn>
	</v-row>
</template>

<script>
import eventBus from '@/utils/eventBus.js'

export default {
	props: {
		showDividers: { type: Boolean, default: true },
		showButtons: { type: Boolean, default: false },
		group: { type: String, default: 'app' },
		order: { type: Array, default: () => [] },
	},
	data: () => ({
		stepsByGroup: {},
		currentStepId: null,
		// TODO: on change of modelValue, call show?
		modelValue: null,
	}),
	computed: {
		steps() {
			return this.stepsByGroup[this.group] ?? []
		},
		hideGoBack() {
			return this.steps.findIndex(s => s.id == this.currentStepId) <= 0
		},
		hideGoNext() {
			return this.steps.findIndex(s => s.id == this.currentStepId) >= (this.steps.length - 1)
		},
	},
	methods: {
		register(step, group) {
			if (group != this.group) return
			if (!this.stepsByGroup[group]) this.stepsByGroup[group] = []
			const steps = this.stepsByGroup[group]

			const index = steps.findIndex(s => s.id == step.id)
			// on update, replace the step
			if (index >= 0) {
				steps.splice(index, 1, step)
			}
			// on add, insert the step
			else {
				// if the step is not explicitely ordered, add at the end
				if (!this.order || !this.order.includes(step.id)) {
					steps.push(step)
				}
				else {
					const prios = {}
					for (let s = 0; s < this.order.length; s++) prios[this.order[s]] = s
					const newStepPrio = prios[step.id]
					let found = false
					for (let s = 0; s < steps.length; s++) {
						const prio = prios[steps[s].id] ?? 999
						// look for the first place with a higher prio than the new step
						if (prio > newStepPrio) {
							// insert before that one
							steps.splice(s, 0, step)
							found = true
							break
						}
					}
					// if we did not find a place, add at the end
					if (!found) steps.push(step)
				}

				// show the first step
				if (steps.length == 1) this.show(step)
			}
			this.$emit('steps', steps)
		},
		// TODO: if the removed one is currently shown -> show the next one?
		deregister(step, group) {
			if (group != this.group) return
			if (!this.stepsByGroup[group]) this.stepsByGroup[group] = []
			const steps = this.stepsByGroup[group]

			const index = steps.findIndex(s => s.id == step.id)
			if (index < 0) return
			steps.splice(index, 1)
			this.$emit('steps', steps)
		},
		show(stepOrId) {
			const step = typeof stepOrId == 'string' ? this.steps.find(s => s.id == stepOrId) : stepOrId
			if (this.currentStepId == step.id) return
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
		goBack() {
			const index = this.steps.findIndex(s => s.id == this.currentStepId)

			if (index <= 0) return

			const step = this.steps[index - 1]
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
		goNext() {
			const index = this.steps.findIndex(s => s.id == this.currentStepId)

			if (index >= (this.steps.length - 1)) return

			const step = this.steps[index + 1]
			this.$emit('update:modelValue', step.id)
			this.$emit('step', step.id)
			eventBus.$emit('step-show', step.id, this.group)
			this.currentStepId = step.id
		},
	},
	mounted() {
		eventBus.$on('step-register', this.register)
		eventBus.$on('step-deregister', this.deregister)
		eventBus.$emit('step-cfr', this, this.group)
	},
	unmounted() {
		eventBus.$off('step-register', this.register)
		eventBus.$off('step-deregister', this.deregister)
	},
}
</script>

<style scoped>
.StepBar { height: 60px; width: auto; padding-left: 320px; border-bottom: 1px solid #ddd; z-index: 99; }
@media screen and (max-width: 950px) { .StepBar { padding-left: 0; } }
@media screen and (max-width: 1040px) { .StepBar { justify-content: center !important; } #backBtn,#nextBtn { display: none; } }

ul { text-align: justify; position: relative; overflow: hidden; margin: 0; padding: 0; display: flex; gap: 10px; }
li { position: relative; width: 20%; text-align: center; padding-bottom: 20px; min-width: 120px; cursor: default; display: flex; flex-direction: column; gap: 5px;  }

.step { --col: #737373; color: var(--col); flex: 1 1 0; margin-top: 4px; position: relative; cursor: pointer; }
.step .icon { background: var(--col); width: 20px; height: 20px; border-radius: 10px; display: flex; justify-content: center; align-items: center; margin: auto; }
.step .label { font-size: 12px; white-space: nowrap; line-height: -15px; position: absolute; left: 0; right: 0; bottom: 0; }
.divider { border-top: 3px solid var(--col); position: absolute; top: 9px; width: 100%; left: 50% }

.current { font-weight: bold; }
.current .icon { outline: 2px solid var(--col); outline-offset: 1px; }
.complete { --col: #7BC043; }
.error { --col: #ff0022; }
.disabled { pointer-events: none; opacity: 0.5; }
.v-icon { position: relative; }
.steps { 
	/* make sure this stays in the center, even if the prev/next button is not rendered */
	margin-left: auto; 
	margin-right: auto; 
	position: fixed; 
	left: 60%; 
	transform: translateX(-50%);
}
.next-btn { margin-left: auto; }
</style>