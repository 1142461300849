<template>
	<div class="SystemInfo">
		<div>Service: <span data-cy="productId">{{ product.sys ? product.sys.id : '' }} / {{ serviceName }}</span></div>
		<div>
			{{ $t('text.serviceProvider') }}:
			{{ serviceProvider?.sys?.id }}
			<v-icon @click="showServiceProvider" size="x-small">mdi-arrow-right-circle</v-icon>
			/ {{ serviceProviderName }}
		</div>
		<div v-if="hasField('availabilityInfo','productContingents', product)">SKU: {{ getSKU }}</div>
		<div v-if="hasField('availabilityInfo','productContingents', product) && !productIsExternal">Contingent ID: {{ getSpaceId }}</div>
		<div v-if="hasField('availabilityInfo','productContingents', product)">Peak ID: {{ getPeakId }}</div>
		<!--<div v-if="getJamesURL"><br/><a :href="getJamesURL" target="_blank">View In JAMES</a></div>-->
		<IfFeatureFlag flag="FEATURE_AUDIT_LOGS">
			<div v-if="userIsMainOperator">
				<a href='#' @click.prevent.stop="$refs.trackingDialog.open()">Show Activity Log</a>
			</div>
		</IfFeatureFlag>
		<DialogV2 ref="trackingDialog"
			:title="product.fields.title.de"
		>
			<template #content>
				<TrackingView v-if="product" entryType="product" :entryId="product.sys.id" />
			</template>
		</DialogV2>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import PeakInfo from '@/components/serviceDesigner/PeakInfo.vue'
import ContentfulInfo from '@/components/serviceDesigner/ContentfulInfo.vue'
import DialogV2 from '../common/DialogV2.vue'
import TrackingView from '../common/TrackingView.vue'
import IfFeatureFlag from "@/components/ifFeatureFlag/IfFeatureFlag.vue"

export default {
	name: 'SystemInfo',
	components: { PeakInfo, ContentfulInfo, DialogV2, TrackingView, IfFeatureFlag },
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
		productIsExternal:Boolean,
		updateModel: Boolean
	},

	data() {
		return {
			model: {},
			sectionMessage: {
				error: false,
				message: ''
			},
			linkedEntries: [],
			peakSyncData: [],
			productPeakId: '',
			contentfulDataLoaded: false,
			peakDataLoaded: false,
		}
	},
	computed: {
		serviceName() {
			return this.product?.fields?.title?.[this.serviceLocale] ?? this.product?.fields?.title?.de ?? ''
		},
		serviceProviderName() {
			if (this.serviceProvider?.fields?.title?.[this.serviceLocale]) {
				return this.serviceProvider.fields.title[this.serviceLocale]
			}
			else {
				if (this.serviceProvider?.fields?.title?.de?.length) {
					return this.serviceProvider.fields.title.de
				}
			}
			return ''
		},
		getSKU() {
			if (this.product?.fields?.clientAssignments?.de?.length > 0) {
				for (const clientAssignment of this.product.fields.clientAssignments.de) {
					if (clientAssignment?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id) {
						return clientAssignment.fields.sku?.de ? clientAssignment.fields.sku.de : 'MYSERVICES'
					}
				}
			}
			return "MYSERVICES"
		},
		getPeakId() {
			if (this.product?.fields?.clientAssignments?.de?.length > 0) {
				for (const clientAssignment of this.product.fields.clientAssignments.de) {
					if (clientAssignment?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id) {
					return clientAssignment.fields.externalId?.de ? clientAssignment.fields.externalId.de : ''
					}
				}
			}
			return ''
		},
		getSpaceId() {
			if (this.product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields?.spaceId) {
				return this.product.fields.ticket.de.fields.ticketOptions.de[0].fields.spaceId.de
			}
			return ''
		},
		getJamesURL() {
			if (this.$store.state.selectedClient?.fields?.jamesUrl?.de) {
				return `${this.$store.state.selectedClient.fields.jamesUrl.de}/de/products/MYS_${this.product.sys.id}`
			}
			return ''
		},
	},
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.product)
		}
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		async getLinkedEntries() {
			try {
				const response = await this.$httpGet('/linkedEntries', {
					entryId: this.$store.state.selectedProduct.sys.id,
					clientId: this.$store.state.selectedClient.sys.id
				})
				this.linkedEntries = response
				this.contentfulDataLoaded = true
			}
			catch (error) {
				this.showError(error)
			}
		},
		async getPeakSync() {
			try {
				const response = await this.$httpGet('/peakSync', {
				productId: this.$store.state.selectedProduct.sys.id,
				client: this.$store.state.selectedClient.fields.clientId.de,
				clientId: this.$store.state.selectedClient.sys.id
				})
				this.peakSyncData = response
				this.peakDataLoaded = true
			}
			catch (error) {
				this.showError(error)
			}
		},
		async showServiceProvider() {
			const id = this.serviceProvider.sys.id
			this.$router.push(`/serviceProviders/${ id }`)
		},
	},
	created() {
		this.model = this.valueToModel(this.product)
	},
	async mounted() {
		if (this.featureEnabled('contingent-monitor')) {
		//   await this.getLinkedEntries()
		//   await this.getPeakSync()
		}
	},
}
</script>

<style scoped>
.SystemInfo { color: gray; font-size: smaller; }
</style>