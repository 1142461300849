<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<div style="padding: 12px 24px; display: flex; gap: 16px; flex-grow: 1;">
				<div style="position: relative; flex: 1;">
					<ContractorFilterCombo v-model="filterComboModel" :disabled-filters="!contractSequenceFilterSet ? ['contractState'] : []"/>
				</div>
				<button style="background: var(--color-blue); padding: 8px 16px; color: white; border-radius: 4px;">Search
				</button>
			</div>
		</template>

		<ContractorsList :contractors="contractorsModelFiltered" :sequenceFilterSet="contractSequenceFilterSet" @updateContractor="updateContractor" style="margin-top: 64px;"/>
	</Application>
</template>

<script lang="ts">
import Application from '../Application.vue'
import ContractorsList from '@/components/contract/ContractorsList.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import ContractorFilterCombo from '../../../components/searchFilter/ContractorFilterCombo.vue'
import Toast from '../../../mixins/Toast.vue'
import { isEqual } from 'lodash'

export default {
	components: { ContractorsList, Application, ContractorFilterCombo },
	mixins: [ Toast ],
	data() {
		return {
			contractorFields: ['id', 'name', 'email', 'productKinds', 'salesChannels'],
			contractors: [] as any[],
			contractorsModel: [] as any[],
			contractorsModelFiltered: [] as any[],
			loading: false,
			errorTitle: '',
			errorDetail: '',
			filterComboModel: { filters: [], search: '' },
			searchTimeout: null as any,
		}
	},
	watch: {
		'filterComboModel.filters': {
			deep: true,
			handler(filters, old) {
				// TODO: fix search string changes also triggers the filters watch
				// temporary solution to check for filters changes
				if (isEqual(filters, old)) return

				if (!filters.length) {
					this.loadContractors()
				} else if (filters.every(f => f.value !== '')) {
					this.loadContractorsWithFilters(filters)
				}
			},
		},
		'filterComboModel.search'(n) {
			if (this.searchTimeout) clearTimeout(this.searchTimeout)
			if (!n.length) {
				this.contractorsModelFiltered = this.contractorsModel
				return
			}
			this.searchTimeout = window.setTimeout(() => {
				this.contractorsModelFiltered = this.contractorsModel.filter(c => c.name.toLowerCase().includes(n.toLowerCase()))
			}, 200)
		},
	},
	computed: {
		contractSequenceFilterSet() {
			return this.filterComboModel.filters.some(f => f.field === 'contractSequence' && f.value !== '')
		},
	},
	methods: {
		async loadContractorsWithFilters(filters) {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				this.contractors = await contractExecutive.getContractorsWithFilters(this.$store.state.selectedClient.sys.id, this.contractorFields, filters)
				this.contractorsModel = this.mapContractorResults(this.contractors)
				this.contractorsModelFiltered = this.contractorsModel
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
		async loadContractors() {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				this.contractors = await contractExecutive.getContractors(this.$store.state.selectedClient.sys.id, this.contractorFields)
				this.contractorsModel = this.mapContractorResults(this.contractors)
				this.contractorsModelFiltered = this.contractorsModel
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
		mapContractorResults(contractors) {
			return contractors.map(r => {
				const contractorStatus = r.hasAtLeastOneContract ? 'assigned' : 'missing'
				return {
					id: r.id,
					name: r.name,
					email: r.email,
					noEmail: !r.email?.length,
					productKinds: r.productKinds,
					salesChannels: r.salesChannelObjects.map(sc => sc?.title?.de),
					contractorStatus,
					contractStatus: 'TODO',
					contracts: r.contracts,
				}
			})
		},
		async updateContractor(contractor) {
			try {
				const fieldsToUpdate = ['email', 'name']
				const contractorEntity = this.contractors.find(c => c.id === contractor.id)
				let contractExecutive = new ContractExecutive(this)
				await contractExecutive.updateContractor({ 
					...contractorEntity,
					email: contractor.email,
					name: contractor.name
				}, fieldsToUpdate)
				this.showSuccessToast(this.$t('text.updateContractorSuccess'))
			} catch (error) {
				this.showErrorToast(this.$t('text.updateContractorError'))
			} finally {
				if (!this.filterComboModel.filters.length) {
					this.loadContractors()
				} else if (this.filterComboModel.filters.every(f => f.value !== '')) {
					this.loadContractorsWithFilters(this.filterComboModel.filters)
				}
			}
		},
	},
	async mounted() {
		await this.loadContractors()
	}
}
</script>