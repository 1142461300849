<template>
	<div class="ProductKindSelector Field" v-if="productKinds?.length">
		<div @click="$refs.editDialog.open()" class="input">
			<v-chip v-for="(pc,index) of model" :key="index">
				{{ pc }}
			</v-chip>
		</div>
		<DataDialog ref="editDialog" v-model="model" :title="$t('text.eCommerceCategories')" :useWrap="true">
			<template #content="{ wrap }">
				<div v-for="(pcs, s) of productKinds" :key="'scp' + s"
					style="display: flex; gap: 10px; align-items: center;">
					<v-checkbox v-model="wrap.model" hide-details :label="pcs" :value="pcs"
						data-cy="product-kind-checkbox" />
				</div>
			</template>
		</DataDialog>
		<ul v-if="error" class="errors">
			<li>
				<svg class="icon-info" viewBox="0 0 24 24">
					<path d="M0 0h24v24H0V0z" fill="none"></path>
					<path
						d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z">
					</path>
				</svg>
				{{ $t('text.field-val-' + error) }}
			</li>
		</ul>
	</div>
</template>

<script>
import DataDialog from '../common/DataDialog.vue'
import ChildErrorDispatcher from '../../views/applications/packageDesigner/ChildErrorDispatcher.vue'

export default {
	mixins: [ChildErrorDispatcher],
	components: { DataDialog },
	props: {
		modelValue: Array,
		productKinds: Array,
		required: { type: Boolean, default: true },
	},
	data: () => ({
		model: [],
		error: null
	}),
	watch: {
		modelValue: {
			handler(n) {
				this.model = n
				this.validate()
			},
		},
		model: {
			handler(n) {
				this.$emit('update:modelValue', n)
			},
		},
	},
	methods: {
		validate() {
			this.$nextTick(() => {
				if (!this.modelValue.length) {
					this.childErrorAutoDispatch(this.$el, [{ id: 'required' }])
					this.error = 'required'
				}
				else {
					this.childErrorAutoDispatch(this.$el, [])
					this.error = null
				}
			})
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>

<style scoped>
.input {
	cursor: pointer;
	height: auto;
	max-height: initial;
	min-height: 40px;
	padding: 5px 4px;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.errors {
	font-size: 14px;
	margin: 0.75rem 0px 0px;
	padding: 0px;
	overflow-wrap: break-word;
	color: var(--col-error);
	list-style-type: none;
}

.errors li {
	list-style-type: none;
	margin-bottom: 5px;
	display: flex;
}
.icon-info {
	display: inline-block;
	fill: var(--col-error);
	height: 18px;
	width: 18px;
	margin-right: 5px;
}
</style>