<template>
	<Disclosure wide
		:title="$t('text.seasonalPricing')"
		:error="sectionMessage.error"
		:warning="sectionMessage.warning"
		:message="sectionMessage.message"
		data-cy="ticketInfo"
		ref="SECTION_seasonalPricing"
		v-if="modelValue"
	>
		<template #header-accessory>
			<div style="flex: 1;" />
			<ProFeatureTag />
		</template>
		<template v-if="featureEnabled('seasonal-pricing')">
			<!--
			<pre>{{ $store.state.selectedClient.fields.ticketTypes.de }}</pre>
			{{ product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0] }}
			-->
			<div v-if="!ticketsDefined">
				{{ $t('text.seasonalPricingTicketsMissing') }}
			</div>
			<div v-else>
				<!-- TODO: show editable price matrix for default prices?
							probably not easily possible to make reactive with Ticket.. -->
				<Field :title="$t('text.seasonalPricing')" :infoText="$t('text.seasonalPricing_info')" />
				<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
				<Field :title="$t('text.seasonalPricingExceptions')" :infoText="$t('text.seasonalPricingExceptions_info')" />
				<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" mode="exception" />
			</div>
		</template>
		<template v-else>
			<Field :title="$t('text.proFeature')" :helpText="$t('text.proFeature_help')" />
		</template>
	</Disclosure>
</template>

<script lang="ts">
import Common from '../../../mixins/Common.vue'
import Disclosure from '../../../components/common/Disclosure.vue'
import PriceMatrixCalendar from '../../../components/calendar/PriceMatrixCalendar.vue'
import ProFeatureTag from '../../../components/common/ProFeatureTag.vue'
import Field from '../../../components/fields/Field.vue'
import { calcBasePrices } from '../../../../../shared/seasonal-prices'

export default {
	components: { Disclosure, PriceMatrixCalendar, ProFeatureTag, Field },
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
		updateModel: Boolean,
		productIsExternal: Boolean,
		modelValue: Object, // { de: PricingTimespan[] }
	},
	data: () => ({
		sectionMessage: {
			error: false,
			warning: false,
			message: ''
		},
		/*
		profiles: [
			// prices: { adult_1: 0, child_1: 0, senior_1: 0, adult_2: 0, child_2: 0, senior_2: 0 }
			//{ id: 0, color: null, name: 'default', weekdays: [], prices: {}, isRemove: true },
			{ id: 1, color: '#aae4fa', name: 'summer', weekdays: [], prices: [], start: '2024-10-01', end: '2024-10-31' },
			{ id: 2, color: '#cbedb6', name: 'winter', weekdays: [], prices: [], start: null, end: null },
			{ id: 3, color: '#ffe6aa', name: 'holiday', weekdays: [], prices: [], start: null, end: null },
		],
		*/
	}),
	computed: {
		// merge ticketTypes and ticketCategories from client and serviceProvider
		// this is essentially what the user can select from
		// gives the complete objects (with .fields)
		/*
		allTicketTypes() {
			return [
				...this.$store.state.selectedServiceProvider.fields.ticketTypes.de,
				...this.$store.state.selectedClient.fields.ticketTypes.de,
				...this.ticketOptions.map((option: any) => option.fields?.ticketType?.de),
			]
		},
		allTicketCategories() {
			return [
				...this.$store.state.selectedServiceProvider.fields.reductions.de,
				...this.$store.state.selectedClient.fields.reductions.de,
				...this.ticketOptions.map((option: any) => option.fields?.reduction?.de),
			]
		},
		*/
		// ticket variations of the product (only contains links to the actual ticketType and reduction)
		ticketOptions() {
			const ticket = this.product?.fields?.ticket?.de
			return ticket?.fields?.ticketOptions?.de ?? []
		},
		// full objects that are linked in the ticketOptions
		ticketTypes() {
			// i have changed this implementation to use the TTs / TCs straight from the ticketOptions
			// in the past i have seen these objects not containing all fields, thats why i used the id
			// approach. however we have found problems with this when an operator creates an option, because
			// then it may not be linked to the SP, meaning we cannot find in allTicketCategories..
			return this.uniqueBy(this.ticketOptions, o => o.fields?.ticketType?.de)
			// const ids = this.ticketOptions.map((option: any) => option.fields?.ticketType?.de?.sys?.id)
			// return this.allTicketTypes.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketCategories() {
			return this.uniqueBy(this.ticketOptions, o => o.fields?.reduction?.de)
			// const ids = this.ticketOptions.map((option: any) => option.fields?.reduction?.de?.sys?.id)
			// return this.allTicketCategories.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketsDefined() {
			return this.ticketTypes.length > 0 && this.ticketCategories.length > 0
		},
		basePrices() {
			return calcBasePrices(this.ticketOptions)
		},
		hasTicket() {
			const to = this.product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields
			return to
				&& to?.reduction?.de !== null
				&& to?.ticketType?.de !== null
				&& to?.price?.de !== null && to?.price?.de !== undefined && to?.price?.de !== ''
		},
	},
	watch: {
		hasTicket: {
			handler: function (val) {
				if (!val) this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
				else this.resetSectionWarning(this.sectionMessage)
			},
			immediate: true,
		},
	},
	methods: {
		// TODO: move to some util
		uniqueBy<T>(array: T[], keyFunc: Function): T[] {
			return Object.values(array.reduce((acc, o) => {
				const tc = keyFunc(o)
				acc[tc.sys.id] = tc
				return acc
			}, {}))
		},
		/*updateTicketWarning() {
			console.log('update ticket warning')
			const to0 = this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.[0]
			const hasTicket = to0 && to0.fields?.ticketType?.de && to0.fields?.reduction?.de && to0.fields?.price?.de
			if (!hasTicket) this.setSectionWarning(this.sectionMessage, this.$t('text.ticketRequiredWarning'))
			else this.resetSectionWarning(this.sectionMessage)
		},*/
	},
	mounted() {
		// repair potentially missing seasonalPricing prop
		if (!this.modelValue) {
			this.$emit('update:modelValue', { de: [] })
		}
	},
}
</script>

<style scoped>
</style>