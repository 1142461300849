<template>
	<Application :title="$t('text.productCategories')" :loading="loading" :errorDetail="errorDetail" v-model:errorTitle="errorTitle">
		<template #navbar>
			<v-text-field variant="outlined" density="compact" clearable hide-details
				prepend-inner-icon="mdi-magnify"
				:placeholder="$t('text.searchCategoryOrSubcategory')"
				style="max-width:33%; height:48px" 
				v-model="searchString" 
				@keyup.enter="offset=0;search()"
				@click:clear="clearSearch()"
				data-cy="search"
			/>
			<v-spacer/>
			<v-btn class="gradientButton" elevation="0" data-cy="addNew" @click="showNewCategory()"><v-icon>mdi-plus</v-icon>{{$t('text.addMainCategory')}}</v-btn>
		</template>

		<SideBar :navigation="sections" @action="handleAction($event)" />

		<!-- Product Category List -->
		<v-card class="tableCard" style="margin-left: 300px;">
			<v-data-table fixed-header disable-sort
				:headers="headers"
				:items="productCategories"
				:items-per-page="limit"
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr @click="showCategoryDetails(item)">
						<td>{{ item.fields.title[selectedLocale] }}</td>
						<td align="right">
							<span v-if="item.fields.subProductCategories && item.fields.subProductCategories.de">
								{{ item.fields.subProductCategories.de.length }}
							</span>
							<span v-if="!item.fields.subProductCategories || !item.fields.subProductCategories.de">
								0
							</span>
						</td>
					</tr>
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" :limit="limit" :results="productCategories" :total="categoryCount" @update:modelValue="getProductCategories()" />
				</template>
			</v-data-table>
		</v-card>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import SideBar from "@/components/common/SideBar.vue"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Application from '../Application.vue'

export default {
	name: "ProductCategoryView",
	mixins: [ Common, SettingsMixin ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, TablePaginator, Application },

	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',
			productCategories: [],
			searchString: '',
			categoryCount: 0,
			allCategories: [],
			// ATT: sections is defined in SettingsMixin!
		}
	},

	computed: {
		headers() {
			return [
				{ title: this.$t('text.category'), align: 'left', sortable: true, key: 'item_type' },
				{ title: this.$t('text.subCategoriesAvailable'), key: 'sca', width:'100px', align:"right" },
			]
		},
	},
	methods: {
		search() {
			// filter out categories based on the category title or the sub-category title containing the search string
			this.productCategories = this.allCategories.filter(category => {
				if (category.fields.title[this.selectedLocale].toLowerCase().includes(this.searchString.toLowerCase())) {
					return true
				}
				if (category.fields.subProductCategories?.de) {
					for (let subCategory of category.fields.subProductCategories.de) {
						if (subCategory.fields?.title?.[this.selectedLocale]?.toLowerCase().includes(this.searchString.toLowerCase())) {
							return true
						}
					}
				}
				return false
			})
			this.categoryCount = this.productCategories.length
		},
		clearSearch() {
			this.searchString = ''
			this.getProductCategories()
		},
		showNewCategory() {
			this.$router.push(`/productCategoryDetail`)
		},
		showCategoryDetails(item) {
			this.$router.push(`/productCategoryDetail?categoryId=${item.sys.id}`)
		},
		async getProductCategories() {
			let categories = this.$store.state.selectedClient.fields.productCategories?.de?.length ? this.$store.state.selectedClient.fields.productCategories.de : []
			
			if (categories?.length) {
				this.productCategories = categories.filter(x => x?.fields?.mainCategory?.de === true)
				this.allCategories = this.productCategories
				this.categoryCount = this.productCategories.length
				let start = this.offset
				let end = this.offset + this.limit
				this.productCategories = this.productCategories.slice(start, end)
				this.productCategories.sort(this.compare)
			}
		},
	},
	async mounted() {
		this.state = 'categories'
		this.activeLink = 'categories'
		this.getProductCategories()
		this.setSections()
	},
}
</script>

<style lang="scss" scoped>
.btn-align { margin-left:10px; margin-top:-10px; height:56px !important}
.btn-spacing { margin-left: 70px }
.v-menu__content {
	min-width: 200px !important;
	max-width: 200px !important;
	width: 200px !important;
	right: 30px !important;
	left: calc(100% - 235px) !important;
}
</style>
