<template>
	<!-- TODO: we would actually want to do this:
		:disableConfirmButton="model?.total < model?.used"
		but that does not work, because the model is detached by the DataDialog..
	-->
	<DataDialog ref="dialog" width="80%" height="450"
		v-model="selectedSlotData"
		:title="$t('text.editContingent')"
		:onConfirm="confirm"
		:confirmLabel="$t('text.save')"
		:validator="validateModel"
		:disableConfirmButton="!valid"
		:reactOn="{ acceptOverbooking }"
	>
		<template #sidebar="{ model }">
			<div style="padding: 32px; display: flex; flex-direction: column; gap: 16px;">
				{{ $t('text.selectedItem') }}
				<ContingentSlotStandalone :modelValue="model" :day="selectedDay" :dates="dates" />
			</div>
		</template>
		<template #content="{ model }">
			<ContingentSlotForm :modelValue="model" :acceptOverbooking="acceptOverbooking" />
			<div v-if="model.total < model.used" style="color: red;">
				<mys-switch v-model="acceptOverbooking" :label="$t('text.acceptExistingOverbooking')" />
			</div>
		</template>
	</DataDialog>
</template>

<script lang="ts">
import ContingentSlotStandalone from './ContingentSlotStandalone.vue'
import ContingentSlotForm from './ContingentSlotForm.vue'
import DataDialog from '../../common/DataDialog.vue'
import Toast from '../../../mixins/Toast.vue'

export default {
	name: 'ContingentSlotDialog',
	components: { ContingentSlotStandalone, ContingentSlotForm, DataDialog },
	mixins: [ Toast ],
	props: {
		client: Object,
		product: Object,
		selectedDay: Object,
		selectedSlot: Object,
		dates: Object,
		onConfirm: Function,
	},
	data: () => ({
		// TODO: add all of them to the dialog sidebar?
		selectedDayData: null,
		selectedSlotData: null,
		loading: false,
		oldTotal: null,
		valid: true,
		acceptOverbooking: false,
	}),
	methods: {
		async load() {
			if (this.loading) return
			this.loading = true
			try {
				const res = await this.$httpGet(`/contingents/${ this.client.fields.clientId.de }/${ this.product.sys.id }/${ this.selectedDay.date }`)
				for (const d of res.availabilities) {
					d.time = d.from.substr(11, 5)
				}
				// TODO: does this handle all-day correctly?
				this.selectedSlotData = res.availabilities.find(a => a.time == this.selectedSlot.time)
				this.oldTotal = this.selectedSlotData.total
				this.selectedDayData = res.availabilities
			}
			catch (error) {
				console.log(error)
			}
			this.loading = false
		},
		async open() {
			this.selectedDayData = null
			this.selectedSlotData = null
			this.acceptOverbooking = false
			await this.load()
			// TODO: only open, if load was successful!
			this.$refs.dialog.open()
		},
		async save(newTotal) {
			if (this.loading) return
			this.loading = true
			try {
				const res = await this.$httpPost(`/contingents/${ this.client.fields.clientId.de }/${ this.product.sys.id }/${ this.selectedDay.date }/${ this.selectedSlot.time }`, {
					startDate: this.selectedSlotData.from,
					endDate: this.selectedSlotData.until,
					oldTotal: this.oldTotal,
					newTotal,
				})
				this.showSuccessToast()
			}
			catch (error) {
				console.log(error)
				this.showErrorToast()
			}
			this.loading = false
		},
		async confirm(newValue) {
			// TODO: cancel (return false) on error
			await this.save(newValue.total)

			// TODO: somehow updating does not work here..
			//       this means the ContingentSlot in the calendar does not update..
			//       weirdly when i set this in the beginning of load() it updates correctly..
			//       as soon as load() is running the async, this breaks
			//       not because the dialog opens then, tested without the open() and it still fails..
			//       also deep watching in the slot does not help.
			//       it really looks like the slot loses its reactivity..
			this.selectedSlot.total = newValue.total
			// TODO: is this right? what about the overbooking props?
			this.selectedSlot.free = newValue.total - newValue.used

			if (this.onConfirm) this.onConfirm(newValue)
		},
		validateModel(model) {
			if (!model) return
			const used = model.used - model.overbookFree
			if (!this.acceptOverbooking && model.total < used) {
				if (this.valid) this.valid = false
				return this.$t('text.totalContingentLessThanUsed')
			}
			if (!this.valid) this.valid = true
		},
		test() {
		},
	},
}
</script>

<style scoped>
</style>