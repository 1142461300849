<template>
	<div class="TextField" :class="{ error, 'localized': field?.localized }">
		<div class="input-wrapper">
			<input type="text" class="input"
				v-model="model"
				@focus="onFocus"
				@blur="onBlur"
				@input="updateModelValue"
				ref="input"
				:data-cy="dataCy"
				:disabled="disabled"
				:placeholder="placeholder"
			/>
			<AITranslation v-if="field?.localized && i18nModel" :locale="locale" :fields="[{ field, value: i18nModel, id: typeName + '-' + fieldName }]" />
		</div>
		<FieldConstraintsInfo :value="model" :error="error" :min="minSize" :max="maxSize" />
	</div>
</template>

<script lang="ts">
// TODO: Unique field - check if any other entry with same value
import { field } from './FieldMixin.js'
import FieldConstraintsInfo from './FieldConstraintsInfo.vue'
import AITranslation from '../common/AITranslation.vue'
import IconAi from "@/components/common/icons/IconAi.vue";
import { mapGetters } from 'vuex'

export default {
	components: {IconAi, AITranslation, FieldConstraintsInfo },
	mixins: [ field ],
	props: {
		modelValue: [ Number, String ],
		type: String,
		field: Object,
		disabled: Boolean,
		dataCy: String,
	},
	data: () => ({
		model: null,
	}),
	// TODO: convert validations structure into something more workable - where?
	computed: {
		minSize() {
			return this.validations.size?.min
		},
		maxSize() {
			if (this.field.type == 'Symbol')
				return this.validations.size?.max ?? 255
			return this.validations.size?.max ?? 50000
		},
	},
	watch: {
		modelValue(value) {
			this.model = value
			this.validate()
		},
	},
	methods: {
		updateModelValue({ target: { value } }) {
			this.$emit('update:modelValue', value)
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
	mounted() {
		this.model = this.modelValue
		this.validate()
	},
}
</script>
