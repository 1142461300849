<template>
	<span class="ClozeText">
		<span v-for="(part, p) of parts" :key="p">
			<template v-if="typeof part === 'string'">
				{{ part }}
			</template>
			<template v-else>
				<slot :name="part.name" />
			</template>
		</span>
		<span v-if="!parts.length">
			<span v-for="slot of slotNames" :key="slot">
				<slot :name="slot" />
			</span>
		</span>
	</span>
</template>

<script lang="ts">
export default {
	props: {
		textKey: String,
	},
	computed: {
		parts() {
			const key = 'text.' + this.textKey
			const messages = this.$i18n.messages[ this.$i18n.locale ]?.text
			let text = messages[ this.textKey ]
			if (text.startsWith('text.')) return []

			text = text.replace(/\{/g, '{VAR:')
			return text.split(/[\{\}]/)
				.map((part) => {
					if (part.startsWith('VAR:')) {
						return { name: part.slice(4) }
					}
					return part
				})
		},
		slotNames() {
			return Object.keys(this.$slots)
		},
	},
}
</script>

<style scoped>
</style>