import { CheckPolicy, Resource, RT } from '../../framework/permission-util'
import Executive from '../../framework/rmi-util'
import { register } from '../../framework/rmi-server-util'
import { assignContract, ContractTemplateForListView, deleteContractSequence, deleteContractTemplate, getContractor, getContractors, getContractorWithFields, getContractSequences, getContractTemplateDetails, getContractTemplates, getContractTemplateWithFields, importContractors, setContractNeeded, upsertContractTemplate, updateContractor, getContractsForContractor, signContracts, ContractorFilterItem, getContractorsWithFilters, getContractorSalesChannels } from './ContractTechnical'
import { Contractor, ContractSequence, ContractTemplate } from './contract.entities'

export default class ContractExecutive extends Executive {

	initServer() {
	}

	//@CheckPolicy()
	async importContractors(@Resource(RT.CLIENT) clientId: string, contractorType: string): Promise<void> {
		return await importContractors(clientId, contractorType)
	}

	//@CheckPolicy()
	async upsertContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) template: ContractTemplate): Promise<ContractTemplate> {
		return await upsertContractTemplate(clientId, template)
	}

	// @CheckPolicy()
	async getContractTemplates(@Resource(RT.CLIENT) clientId: string): Promise<ContractTemplateForListView[]> {
		return await getContractTemplates(clientId)
	}

	// @CheckPolicy()
	async getContractSequences(@Resource(RT.CLIENT) clientId: string): Promise<ContractSequence[]> {
		return await getContractSequences(clientId)
	}

	// @CheckPolicy()
	async getContractTemplateDetails(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<ContractTemplate> {
		return await getContractTemplateDetails(templateId)
	}

	// @CheckPolicy()
	async getContractTemplateWithFields(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string, options: { fields: string[], populate: string[] }): Promise<ContractTemplate> {
		return await getContractTemplateWithFields(templateId, options)
	}

	// @CheckPolicy()
	async assignContract(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) sequenceId: string, @Resource(RT.CONTRACTOR) contractorIds: string[]): Promise<void> {
		return await assignContract(sequenceId, contractorIds)
	}

	// @CheckPolicy()
	async signContracts(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorId: string, @Resource(RT.CONTRACT) contractIds: string[], signatureName: string, signaturePosition: string): Promise<void> {
		return await signContracts(contractIds, signatureName, signaturePosition)
	}

	// @CheckPolicy()
	async setContractNeeded(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[], needsContract: boolean): Promise<void> {
		return await setContractNeeded(contractorIds, needsContract)
	}

	// @CheckPolicy()
	async getContractsForContractor(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorId: string) {
		return await getContractsForContractor(contractorId)
	}

	//@CheckPolicy()
	async getContractors(@Resource(RT.CLIENT) clientId: string, fields: string[]): Promise<Contractor[]> {
		return await getContractors(clientId, fields)
	}

	//@CheckPolicy()
	async updateContractor(@Resource(RT.CONTRACTOR) contractor: Contractor, fieldsToUpdate: string[]): Promise<void> {
		await updateContractor(contractor, fieldsToUpdate)
	}

	//@CheckPolicy()
	async getContractor(@Resource(RT.CONTRACTOR) contractorId: string): Promise<Contractor> {
		return await getContractor(contractorId)
	}

	//@CheckPolicy()
	async getContractorWithFields(@Resource(RT.CONTRACTOR) contractorId: string, fields: string[]): Promise<Contractor> {
		return await getContractorWithFields(contractorId, fields)
	}

	//@CheckPolicy()
	async getContractorsWithFilters(@Resource(RT.CLIENT) clientId: string, fields: string[], @Resource(RT.CONTRACTORFILTERS) filters: ContractorFilterItem[]): Promise<Contractor[]> {
		return await getContractorsWithFilters(clientId, fields, filters)
	}

	//@CheckPolicy()
	async getContractorSalesChannels(@Resource(RT.CLIENT) clientId: string): Promise<any[]> {
		return await getContractorSalesChannels(clientId)
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	//@CheckPolicy()
	async deleteContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<void> {
		return await deleteContractTemplate(templateId)
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	//@CheckPolicy()
	async deleteContractSequence(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) sequenceId: string): Promise<void> {
		return await deleteContractSequence(sequenceId)
	}
}

register(ContractExecutive)
