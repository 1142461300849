<template>
	<div class="PersonalisationField">
		<div class="row">
			<v-menu>
				<template v-slot:activator="{ props }">
					<v-text-field v-bind="props" variant="outlined" hide-details density="compact" maxLength="35"
						v-model="personalisationField.fields.label[selectedLocale]"
						:error-messages="additionalPersonalisation?.fields.personalisationGroup?.errors?.title ?? ''"
						style="min-width: 150px; flex: 1;"
					/>
				</template>
				<v-list>
					<v-list-item v-for="(locale, l) in locales" :key="locale.code">
						<v-row align="center" no-gutters>
							<LanguageFlag v-model="locale.code" style="cursor:pointer; margin-right:-8px; margin-top:-24px" />
							<v-text-field @click.stop="" @keydown.stop="" variant="outlined" maxLength="35"
								v-model="personalisationField.fields.label[locale.code]"
								:autofocus="l === 0"
							/>
						</v-row>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-select variant="outlined" hide-details density="compact"
				v-model="personalisationField.fields.inputType.de"
				:error-messages="additionalPersonalisation?.fields.personalisationGroup.errors?.inputType ?? ''"
				:items="allowedTypes"
				:item-title="item => item.value ? $t('text.' + item.value) : ''"
				style="min-width: 150px; flex-grow: 0;"
			/>

			<div v-if="additionalPersonalisation"
				style="display: flex; gap: 10px; align-items: center;"
			>
				<mys-switch v-model="additionalPersonalisation.fields.required.de"
					:label="$t('text.mandatory')"
					style="flex-grow: 0;"
				/>

				<v-btn elevation="0" @click="$emit('remove')">
					<v-icon size="24px">mdi-delete</v-icon>
				</v-btn>
			</div>
		</div>
		<div v-if="personalisationField.fields?.options && personalisationField.fields.inputType.de === 'List'"
			style="padding: 12px; display: flex; flex-direction: column; gap: 8px; width: 350px;"
		>
			<!-- Option Item -->
			<div class="row" v-for="(option) in personalisationField.fields.options.de" :key="option.key"
				@click.stop=""
				@keydown.stop=""
			>
				<v-menu>
					<template v-slot:activator="{ props }">
						<div v-bind="props" class="row">
							<v-icon>mdi-circle-medium</v-icon>
							<v-text-field variant="outlined" density="compact" hide-details
								v-model="option.values[selectedLocale]"
								:error-messages="option.errors"
								@keydown.stop=""
							/>
							<v-icon @click.stop="removeOption(option)">mdi-delete</v-icon>
						</div>
					</template>

					<v-list>
						<v-list-item v-for="(locale, l) in locales" :key="locale.code">
							<v-row align="center" no-gutters>
								<LanguageFlag v-model="locale.code" style="cursor:pointer;margin-right:-8px;margin-top:-24px" />
								<v-text-field @click.stop="" @keydown.stop="" variant="outlined"
									:autofocus="l === 0"
									v-model="option.values[locale.code]"
								/>
							</v-row>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

			<div style="padding-left: 18px;">
				<v-btn class="gradientButton" style="margin-left: 15px;" elevation="0" @click.stop="addOption()">
					<v-icon>mdi-plus</v-icon>{{ $t('text.addOption') }}
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import LanguageFlag from '../../components/common/LanguageFlag.vue'
import Common from '../../mixins/Common.vue'

export default {
	components: { LanguageFlag },
	mixins: [ Common ],
	props: {
		additionalPersonalisation: Object,
		index: Number,
	},
	data: () => ({
		allowedTypes: [ { value: 'List' }, { value: 'Text' } ],
	}),
	computed: {
		personalisationField() {
			return this.additionalPersonalisation?.fields.personalisationGroup.de.fields.personalisationFields.de[0]
		},
		options() {
			return this.personalisationField.fields.options.de
		},
	},
	watch: {
		// we also need to update the title of the personalisation group..
		personalisationField: {
			deep: true,
			handler(n) {
				this.additionalPersonalisation.fields.personalisationGroup.de.fields.title = n.fields.label
			},
		},
		'personalisationField.fields.inputType.de'(n) {
			if (n === 'List' && !this.personalisationField.fields.options?.de) {
				this.personalisationField.fields.options = { de: [ { key: 0, values: { de: '', en: '', fr: '', it: '', nl: '' } } ] }
			}
		},
	},
	methods: {
		addOption() {
			let index = this.options.length
			this.options.push({ key: index, values: { de: '', en: '', fr: '', it: '', nl: '' } })
		},
		removeOption(option) {
			let index = this.options.findIndex(x => x == option)
			this.options.splice(index, 1)
			// update keys of remaining options
			for (let i = 0; i < this.options.length; i++) {
				this.options[i].key = i
			}
		},
	},
}
</script>

<style scoped>
.PersonalisationField { width: 100%; }
.row { display: flex; gap: 8px; align-items: center; width: 100%; }
.row > .Field { border-left: none; padding: 0; }
</style>