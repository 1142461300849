<template>
	<v-switch class="mys-switch" inset
		:class="{
			selected: !!model,
			nolabel: !label,
		}"
		v-model="model"
		:hide-details="hideDetails"
		:label="label"
		:disabled="disabled"
		@update="$emit('update', $event)"
		@update:modelValue="$emit('update:modelValue', $event)"
		data-cy="dataCy"
	/>
</template>

<script>
export default {
	props: {
		modelValue: Boolean,
		disabled: Boolean,
		label: String,
		hideDetails: { type: Boolean, default: true },
		dataCy: String,
	},
	data() { return {
		model: null,
	}},
	computed: {
		model: {
			get() { return this.modelValue },
			set(value) { this.model = value ?? false },
		},
	},
}
</script>

<style>
.mys-switch { margin: 0 !important; }
.mys-switch.nolabel { max-width: 44px; --v-input-control-height: 40px; }
.mys-switch .v-switch__track { background-color: #ccc; opacity: 1; }
.mys-switch.selected .v-switch__track { background-color: #00aeef; }
</style>