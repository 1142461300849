<template>
	<div class="TicketOptionExternalSystemSbbv3" v-if="ticketOption">
		<div class="row">
			<Field :title="$t('text.sbbv3id1')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.id1" 
					:error-messages="sbbv3Id1Error"
				/>
			</Field>
			<Field :title="$t('text.sbbv3id2')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.id2" 
					:error-messages="sbbv3Id2Error"
				/>
			</Field>
			<Field :title="$t('text.sbbv3id2')">
				<v-text-field variant="outlined" class="mt-3" hide-details style="background-color:#ffffff"
					v-model="ticketOption.fields.external.id3" 
					:error-messages="sbbv3Id3Error"
				/>
			</Field>
		</div>
	</div>
</template>

<script lang="ts">
import Common from '../../mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		ticketOption: Object,
	},
	computed: {
		sbbv3Id1Error(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.id1?.length ? this.$t('text.externalSystemRequired') : ''
		},
		sbbv3Id2Error(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.id2?.length ? this.$t('text.externalSystemRequired') : ''
		},
		sbbv3Id3Error(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.id3?.length ? this.$t('text.externalSystemRequired') : ''
		},
		valid() {
			return !this.sbbv3Id1Error && !this.sbbv3Id2Error && !this.sbbv3Id3Error
		},
	},
	methods: {
/*
			const external = this.modelValue.fields.ticket.de.fields.external
			if (this.modelValue.fields.ticket.de.fields.hasOwnProperty('external')) {
				if (this.modelValue.fields.ticket.de.fields.external.system === 'SBBv3') {
					ticketOption.fields.external = {
						id1: '',
						id2: '',
						id3: ''
					}
				}
			}*/
		async setExternalSystem() {
			this.ticketOption.fields.external = {
				id1: this.ticketOption?.fields.external.id1 ?? '',
				id2: this.ticketOption?.fields.external.id2 ?? '',
				id3: this.ticketOption?.fields.external.id3 ?? '',
			}
		},
	},
	beforeMount() {
		// TODO: also check if id1 actually exists, unify with the init in setExternalSystem
		if (!this.ticketOption?.fields.external) {
			this.ticketOption.fields.external = {
				id1: '',
				id2: '',
				id3: '',
			}
		}
	},
	async mounted() {
		await this.setExternalSystem()
	},
}
</script>

<style scoped>
.row { display: flex; gap: 10px; width: 100%; }
.row > * { flex: 1 0; }
.row > .Field { border-left: none; padding: 0; }
</style>