<template>
	<div class="chips-list" :class="{ disabled }" :data-cy="dataCy" @click="onClick" >
		<span v-for="(value, index) in items" :key="'chip-' + index" class="chips-list__chip" :class="{ disabled }" @click="onClick(value)">
			{{ value }}
		</span>
		<div v-if="$slots.default" class="chips-list__slot">
			<slot/>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'ChipsList',
	props: {
		items: Array<string>,
		disabled: Boolean,
		dataCy: String,
	},
	methods: {
		onClick(element: string = undefined) {
			if (this.disabled) {
				return
			}
			this.$emit('click', element)
		}
	}
}
</script>

<style scoped lang="scss">
.chips-list {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 6px 12px;
	flex-wrap: wrap;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	transition: all .3s cubic-bezier(.25, .8, .5, 1);
	cursor: pointer;
	min-height: 36px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

	&.disabled {
		background: #f4f4f4;
		cursor: not-allowed;
	}

	&:not(.disabled):hover {
		border: 1px solid #00aeef;
	}

	&__chip {
		color: #000;
		padding: 3px 8px;
		border-radius: 6px;
		align-items: center;
		background: #dbdbdb;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		font-size: 0.8125rem;
		line-height: 1.38;
		&.disabled {
			color: #9da0a5;
			cursor: not-allowed;
		}
	}

	&__slot {
		margin-left: auto;
		display: flex;
	}
}
</style>