<template>
	<Field class="ContingentSlotForm">
		<h2>{{ $t('text.totalContingent') }}</h2>
		{{ $t('text.totalContingentText') }}
		<h2><v-icon>mdi-information</v-icon> {{ $t('text.importantInfo') }}</h2>
		{{ $t('text.totalContingentInfo') }}

		<!-- TODO: get a warning ('Failed to resolve component: v-number-input') that this comp is unsupported
					should be in >3.5.10, but we are on 3.5.17..
		<v-number-input v-if="modelValue" control-variant="split" v-model="modelValue.free"></v-number-input>
		-->
		<Field
			style="border: 0; padding: 0;"
			variant="PLUSMINUS"
			v-model="model"
			:showTitle="false"
			:fieldOverride="{ id: 'totalContingent', name: 'totalContingent', type: 'Number', localized: false, required: true, validations }"
			:key="'f' + acceptOverbooking"
		/>

		<div>
			{{ $t('text.remainingFreeContingent', { free: '$F' }).split('$F')[0] }}
			<ContingentSlotFree class="remaining" :modelValue="modelValue" />
			{{ $t('text.remainingFreeContingent', { free: '$F' }).split('$F')[1] }}
		</div>
	</Field>
</template>

<script lang="ts">
import ContingentSlotFree from './ContingentSlotFree.vue'

export default {
	components: { ContingentSlotFree },
	name: 'ContingentSlotForm',
	props: {
		modelValue: Object, // slot
		acceptOverbooking: Boolean,
	},
	// TODO: this is awkward here - build a comp to handle this
	//       could be named <CustomField>?
	//       can we integrate this with <Field>?
	data: () => ({
		model: null,
	}),
	computed: {
		validations() {
			if (!this.modelValue || this.acceptOverbooking) return [ { range: { min: 0 } } ]
			return [ { range: { min: this.modelValue.used } } ]
		},
	},
	watch: {
		'model.de'(n) {
			if (!this.modelValue) return
			this.modelValue.total = n
		},
		'modelValue.total'(n) {
			if (!this.model) return
			this.model.de = n
		},
	},
	mounted() {
		this.model = { de: this.modelValue?.total }
	},
}
</script>

<style scoped>
.ContingentSlotForm { display: flex; flex-direction: column; gap: 8px; }
h2 { line-height: 1em; }
.remaining { font-size: inherit; font-weight: bold; }
</style>