<template>
	<v-select
		variant="outlined"
		density="compact"
		return-object
		:hide-details="errors.length === 0"
		:error-messages="errors"
		:disabled="disabled"
		:items="options"
		:item-title="itemTitle"
		v-model="model"
		data-cy="taxClass"
	/>
</template>

<script lang="ts">
// for: compare
import Common from '../../mixins/Common.vue'
// TODO: child dispatcher for errors

export default {
	mixins: [ Common ],
	props: {
		modelValue: Object,
		disabled: Boolean,
		taxClassErrors: Array,
	},
	data: () => ({
		model: null as any,
		options: [],
	}),
	watch: {
		modelValue() { this.model = this.modelValue },
		model(n) { this.$emit('update:modelValue', n) },
	},
	computed: {
		errors() {
			const r: string[] = []
			if (!this.model?.sys?.id) r.push(this.$t('text.taxClassRequired'))
			return r
		},
	},
	methods: {
		itemTitle(item: any) { return item?.fields?.title ? item.fields.title[ this.$store.state.serviceLocale ] : '' },
	},
	mounted() {
		this.model = this.modelValue
		this.options = this.$store.state.selectedClient.fields.taxClasses.de.sort(this.compare)
	},
}
</script>

<style scoped>
</style>