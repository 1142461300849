<template>
	<Disclosure :title="$t('text.linkServiceProvidersTitle')" :lock="!userMayEditFields" data-cy="linkServiceProviders" ref="SECTION_linkServiceProviders">
		<p class="subTitle" v-html="$t('text.linkServiceProvidersText')"/>
		<div class="field left-border">
			<div class="row">
				<v-text-field variant="outlined" density="compact" clearable hide-details
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					class="mt-0"
					v-model="searchString"
					@click:clear="clear()"
					@keyup.enter="search()"
				/>
				<v-btn class="gradientButton" elevation="0" @click="search()">{{$t('text.search')}}</v-btn>
			</div>
			<div class="searchResults" v-if="searchServiceProviders.length > 0">
				<div v-for="serviceProvider in searchServiceProviders" :key="serviceProvider.sys.id" class="spOption">
					<v-checkbox hide-details density="compact"
						v-model="selectedForAdd"
						:label="serviceProvider.fields.title.de"
						:value="serviceProvider"
						:value-comparator="(a, b) => a.sys.id === b.sys.id"
					/>
				</div>
				<v-btn class="greenButton" elevation="0" :disabled="!selectedForAdd.length" @click="linkServiceProviders()"><v-icon>mdi-link-variant</v-icon> {{$t('text.linkServiceProviders')}}</v-btn>
			</div>
		</div>
		<div class="field left-border" v-if="model.length > 0">
			<p class="subTitle" v-html="$t('text.linkedServiceProviders')"/>
			<div class="linkedOptions">
				<template v-for="serviceProvider in model">
					<div :key="serviceProvider.sys.id" class="spOption" v-if="serviceProvider?.fields?.title?.de">
						<v-checkbox hide-details density="compact"
							v-model="selectedForRemove"
							:label="serviceProvider.fields.title.de"
							:value="serviceProvider"
							:value-comparator="(a, b) => a.sys.id === b.sys.id"
						/>
					</div>
				</template>
				<v-btn class="redButton" elevation="0" :disabled="!selectedForRemove.length" @click="unlinkServiceProviders()"><v-icon>mdi-link-variant-off</v-icon> {{$t('text.unlinkServiceProviders')}}</v-btn>
			</div>
		</div>
	</Disclosure>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'

export default {
	components: { Disclosure },
	mixins: [ Common ],
	props: {
		modelValue: Object,
		serviceProvider: Object,
	},
	data: () => ({
		sectionMessage: { error: false, message: '' },
		searchString: '',
		searchServiceProviders: [],
		selectedForAdd: [],
		selectedForRemove: [],
	}),
	computed: {
		model: {
			get() { return this.modelValue },
			set(v) { this.$emit('update:modelValue', v) },
		},
	},
	methods: {
		linkServiceProviders() {
			this.model.push(...this.selectedForAdd)
			this.clear()
			this.$emit('changed')
		},
		unlinkServiceProviders() {
			this.model = this.model.filter(sp => this.selectedForRemove.find(spr => spr.sys.id === sp.sys.id) === undefined)
			this.selectedForRemove = []
			this.$emit('changed')
		},
		clear() {
			this.searchServiceProviders = []
			this.searchString = ''
		},
		async search() {
			this.$emit('loading', true)
			try {
				// Set searchString to empty string if it is null (it is null after Search field is cleared)
				this.searchString = this.searchString ? this.searchString : ''

				const res = await this.$httpPost('/search', {
					clientId: this.$store.state.selectedClient.sys.id,
					providerId: this.$store.state.selectedServiceProvider.sys.id,
					contentType: 'serviceProvider',
					searchString: this.searchString,
					homebaseOnly: true,
				})

				let r = res.serviceProviders ?? []
				r = r.filter(sp => sp.fields.title?.de)

				// MYS-1036: Remove current service provider from search results
				r = r.filter(sp => sp.sys.id !== this.serviceProvider.sys.id)

				// MYS-1258: Prevent the same SP being linked multiple times
				for (let selectedSP of this.model) {
					r = r.filter(sp => sp.sys.id !== selectedSP.sys.id)
				}

				r.sort(this.compare)
				this.searchServiceProviders = r
			}
			catch (error) {
				console.log(error)
				// if (error.response?.status === 401)
				// 	return this.$emit("show-login")
				// 	this.showError(error.response ? error.response?.data.error : error)
			}
			this.$emit('loading', false)
		},
	},
}
</script>

<style scoped>
.row { display: flex; gap: 10px; align-items: center; }
.searchResults,
.linkedOptions { display: flex; flex-direction: column; gap: 10px; }
.spOption { display: flex; height: 30px; }
.spOption > * { margin: 0 !important; }
</style>