<template>
	<div :class="{backdrop}" @click="backdropClick">
		<div class="dialog-container">
			<!-- Header -->
			<header class="dialog-header">
				<h2 class="dialog-title">{{ $t('text.ticketHistory') }}</h2>
				<v-btn icon class="close-btn" @click="closeDialog()" data-cy="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</header>

			<v-divider/>

			<!-- Content -->
			<div class="dialog-content">
				<!-- Ticket Header -->
				<div class="ticket-header">
					<div class="provider-name">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</div>
					<h3 class="ticket-name">{{ ticket.ticketInfo.productName }}</h3>
					<div class="status-badge redeemed">{{ $t('text.redeemed')}}</div>
				</div>

				<!-- Ticket Details Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.ticketDetails') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.product') }}</span>
							<span class="value">{{ ticket.ticketInfo.productName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.type') }}</span>
							<span class="value">{{ ticket.ticketInfo.ticketTypeName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.category') }}</span>
							<span class="value">{{ ticket.ticketInfo.consumerGroupName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.price') }}</span>
							<span class="value">{{ $store.state.selectedClient.fields.currency.de }} {{ formatPrice(ticket.ticketInfo.price?.value ? ticket.ticketInfo.price.value / 100 : 0) }}</span>
						</div>
					</div>
				</section>

				<!-- Validity Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.validity') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.validOn') }}</span>
							<span class="value">
								{{ formatDate(ticket.ticketInfo.validityDate) }}
								<template v-if="ticket.ticketInfo.validityEndDate">
									- {{ formatDate(ticket.ticketInfo.validityEndDate) }}
								</template>
							</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.totalUsages') }}</span>
							<span class="value">1</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.remainingUsages') }}</span>
							<span class="value">0</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.redeemedDate') }}</span>
							<span class="value">{{ ticket.usageDate.replace(' ', ', ') }}</span>
						</div>
					</div>
				</section>

				<!-- Order Section -->
				<section class="details-section">
					<h4 class="section-title">{{ $t('text.order') }}</h4>
					
					<div class="details-grid">
						<div class="detail-item">
							<span class="label">{{ $t('text.name') }}</span>
							<span class="value">{{ ticket.orderInfo.customerFirstName }} {{ ticket.orderInfo.customerLastName }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.order') }}</span>
							<span class="value">{{ ticket.orderInfo.orderId }}</span>
						</div>

						<div class="detail-item">
							<span class="label">{{ $t('text.orderDate') }}</span>
							<span class="value">{{ ticket.orderInfo.orderDate }}</span>
						</div>
					</div>
				</section>
			</div>

			<v-divider/>

			<!-- Footer -->
			<footer class="dialog-footer">
				<div class="footer-right">
					<v-btn class="action-btn"
						@click="closeDialog()"
						data-cy="cancel"
					>
						{{ $t('text.done') }}
					</v-btn>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import Dialog from '@/components/common/Dialog.vue'

export default {
	name: 'TicketHistory',
	components: {Loading, Dialog},
	mixins: [Common],

	props: {
		ticket: Object
	},

	data() {
		return {
			loading: false,
			hasError: false,
			isWarning: false,
			isError: false,
			isSuccess: false,
			isLoading: false,
			backdrop: true,
			closeOnOuterClick: false,
		}
	},

	methods: {
		closeDialog() {
			this.$emit('close-dialog')
		},
		backdropClick() {
			if (this.closeOnOuterClick) {
				this.closeDialog()
			}
		}
	},
}
</script>

<style scoped lang="scss">
.backdrop {
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
	top: 0;
	left: 0;
}

.dialog-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 700px;
	height: 75vh;
	max-width: calc(100vw - 20px);
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999;
	overflow: hidden;
}

.dialog-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	height: 64px;
	background: white;

	.dialog-title {
		font-size: 20px;
		font-weight: 500;
		margin: 0;
	}

	.close-btn {
		color: #999999;
	}
}

.dialog-content {
	flex: 1;
	overflow-y: auto;
	padding: 24px;

	.ticket-header {
		margin-bottom: 24px;

		.provider-name {
			color: #999999;
			font-size: 14px;
			margin-bottom: 8px;
		}

		.ticket-name {
			font-size: 20px;
			font-weight: 500;
			margin: 0 0 12px 0;
		}

		.status-badge {
			display: inline-flex;
			align-items: center;
			height: 32px;
			padding: 0 16px;
			border-radius: 4px;
			font-size: 14px;

			&.redeemed {
				background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%);
				color: white;
			}
		}
	}

	.details-section {
		margin-bottom: 24px;

		.section-title {
			font-size: 16px;
			font-weight: 500;
			margin: 0 0 16px 0;
			color: #000;
		}

		.details-grid {
			display: grid;
			grid-template-columns: 1fr;
			gap: 12px;

			.detail-item {
				display: flex;
				flex-direction: column;
				gap: 4px;

				.label {
					color: #666;
					font-size: 14px;
				}

				.value {
					color: #000;
					font-size: 14px;
				}
			}
		}
	}
}

.dialog-footer {
	height: 60px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: white;

	.footer-right {
		display: flex;
		gap: 12px;
	}

	.action-btn {
		height: 40px;
		padding: 0 24px;
		text-transform: none;
		font-weight: normal;
		background: linear-gradient(0deg, #f5f5f5 0%, #ffffff 100%);
		border: 1px solid #dedede;
		color: #000;
	}
}
</style>
