<template>
  <div class="FieldLayout" :class="'layout-' + layout">
		<div class="column-left">
			<slot name="descriptors"></slot>
			<slot v-if="!isInline" name="controls"></slot>
			<slot v-if="!isInline" name="validations"></slot>
			<slot name="help"></slot>
		</div>
		<div v-if="isInline" class="column-right">
			<slot name="controls"></slot>
			<slot name="validations"></slot>
		</div>
	</div>
</template>
<script lang="ts">
export default {
	props: {
		layout: { type: String, default: 'default' }
	},
	computed: {
		isInline() {
			return this.layout === 'inline'
		}
	}
}
</script>
<style lang="scss" scoped>
.FieldLayout.layout-inline {
	display: flex;
	align-items: center;
	.column-left {
		flex-grow: 1;
	}
}
</style>