<template>
  <FieldSet>
    <p class="subTitle" v-html="$t('text.translationConfig')"/>
    <p class="helpText" v-html="$t('text.translationConfigHelp')"/>
    <br/>
    <template v-if="model?.writingStyle !== undefined">
      <v-label>{{ $t('text.translationWritingStyle') }}</v-label>
      <p class="helpText" v-html="$t('text.translationWritingStyleHelp')"/>
      <div class="chip-field">
        <v-chip v-for="item in writingStyles" :key="`chip-${item}`" :class="{ active: model.writingStyle === item }" :color="model.writingStyle === item ? 'primary' : ''" label @click="model['writingStyle'] = model['writingStyle'] === item ? '' : item">
          <v-icon class="chip__icon">${{itemIconSrc[item]}}</v-icon>
          {{ $t(`text.writingStyle${item[0].toUpperCase() + item.slice(1)}`) }}
        </v-chip>
      </div>
      <br/>
    </template>
    <template v-if="model?.tone !== undefined">
      <v-label>{{ $t('text.translationTone') }}</v-label>
      <p class="helpText" v-html="$t('text.translationToneHelp')"/>
      <div class="chip-field">
        <v-chip v-for="item in tones" :key="`chip-${item}`" :class="{ active: model.tone === item }" :color="model.tone === item ? 'primary' : ''" label @click="model['tone'] = model['tone'] === item ? '' : item">
          <v-icon class="chip__icon">${{itemIconSrc[item]}}</v-icon>
          {{ $t(`text.tone${item[0].toUpperCase() + item.slice(1)}`) }}
        </v-chip>
      </div>
      <br/>
    </template>
    <template v-if="model?.customPrompt">
      <v-label>{{ $t('text.translationCustomPrompt') }}</v-label>
      <p class="helpText" v-html="$t('text.translationCustomPromptHelp')"/>
      <Field v-model="model.customPrompt" typeName="Freddy" fieldName="customPrompt" :showTitle="false" ></Field>
    </template>
  </FieldSet>
</template>

<script lang="ts">
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'

const FreddyWritingStyles = {
	SIMPLE: 'simple',
	BUSINESS: 'business',
	ACADEMIC: 'academic',
	CASUAL: 'casual'
}

const FreddyTones = {
	ENTHUSIASTIC: 'enthusiastic',
	FRIENDLY: 'friendly',
	CONFIDENT: 'confident',
	DIPLOMATIC: 'diplomatic'
}

export default {
  name: 'TranslationSettings',
  components: { FieldSet },
	props: {
		modelValue: Object,
	},
	data: () => ({
		model: null,
		writingStyles: Object.values(FreddyWritingStyles),
		tones: Object.values(FreddyTones),
	}),
  computed: {
		serviceProviderId() {
			return this.$store.state.selectedServiceProvider.sys.id
		},
    itemIconSrc() {
      return {
        'simple': 'iconMop',
        'business': 'iconWork',
        'academic': 'iconSchool',
        'casual': 'iconWavingHand',
        'enthusiastic': 'iconPersonCelebrate',
        'friendly': 'iconSentimentSatisfied',
        'confident': 'iconThumbUp',
        'diplomatic': 'iconHandshake',
      }
    }
  },
	watch: {
		model() {
			this.$emit('update:modelValue', this.model)
		},
		modelValue(modelValue) {
			this.model = modelValue
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}

</script>
<style lang="scss">
.chip-field {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
  .v-chip.active path {
    fill: #004660;
  }
  .chip__icon {
    margin-right: 8px;
  }
}
</style>