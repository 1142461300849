import { context } from '../../framework/context-util'
import { CheckPolicy, Resource, RT } from '../../framework/permission-util'
import { register } from '../../framework/rmi-server-util'
import Executive from '../../framework/rmi-util'
import { Role, User } from './core.entities'
import { autoUpsert } from '../../framework/orm-util'

export { User, Role } from '../entities'

// TODO: should we rename this file to core.executive.ts or core.interface.ts, or similar?
//       i dont really like that, because we mainly export the executive class,
//       but it would fit nicer with the other files.

export default class CoreExecutive extends Executive {

	// TODO: this is probably not needed anymore
	initServer() {
	}

	@CheckPolicy()
	async getUser(@Resource(RT.USER) id: string): Promise<User> {
		context.log.debug('getUser', id)
		return await context.db.user.findOneOrFail(id, { populate: [ 'role' ] })
	}

	// TODO: upsert mixes the create and update operations
	//       this makes permission checking awkward
	//       -> we will probably have to add a special case where when we cannot find an object,
	//          we will have to check if the user has permission to create it
	@CheckPolicy()
	async upsertUser(@Resource(RT.USER) data: User): Promise<void> {
		// TODO: could we do this more elegantly and add this function on the repo?
		await autoUpsert(context.db.user, data)
	}

	@CheckPolicy()
	async deleteUser(@Resource(RT.USER) id: string): Promise<void> {
		await context.db.user.nativeDelete({ id })
	}

	// TODO: add @CheckPolicy()
	async getRole(@Resource(RT.ROLE) id: string): Promise<Role> {
		return await context.db.role.findOneOrFail(id)
	}

	async upsertRole(@Resource(RT.ROLE) data: Role): Promise<void> {
		await autoUpsert(context.db.role, data)
	}

	async deleteRole(@Resource(RT.ROLE) id: string): Promise<void> {
		await context.db.role.nativeDelete({ id })
	}
}

register(CoreExecutive)