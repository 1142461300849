import 'reflect-metadata'

// ATT: when modifying this file, use TDD and keep tdd-entry.ts running!

export type ClassDefinition = {
	name: string
	// entry.js sets prototypes, so we need to recall it here
	class: typeof Object // Entry
	sys: { id: string, type: 'ContentType' }
	fields: { [ fieldName: string ]: FieldDefinition }
	displayField?: string
	table?: TableDefinition
	columns: { [ fieldName: string ]: ColumnDefinition }
	version: string
}

type MarkOld = 'history' | 'heading.1' | 'heading.2' | 'heading.3' | 'heading.4' | 'heading.5' | 'heading.6' | 'bold' | 'italic' | 'underline' | 'ul' | 'ol'
export const MARKS_OLD_ALL: MarkOld[] = ["history", "heading.1", "heading.2", "heading.3", "heading.4", "heading.5", "heading.6", "bold", "italic", "underline", "ul", "ol"]

type Mark = 'bold' | 'italic' | 'underline' | 'code' | 'superscript' | 'subscript'
export const MARKS_ALL: Mark[] = [ 'bold', 'italic', 'underline', 'code', 'superscript', 'subscript' ]
export const MARKS_TEXT: Mark[] = [ 'bold', 'italic', 'underline' ]

type NodeType = 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4' | 'heading-5' | 'heading-6' | 'ordered-list' | 'unordered-list' | 'hr' | 'blockquote' | 'embedded-entry-block' | 'embedded-asset-block' | 'table' | 'hyperlink' | 'entry-hyperlink' | 'asset-hyperlink' | 'embedded-entry-inline'
export const NODETYPES_ALL:NodeType[] = [ 'heading-1', 'heading-2', 'heading-3', 'heading-4', 'heading-5', 'heading-6', 'ordered-list', 'unordered-list', 'hr', 'blockquote', 'embedded-entry-block', 'embedded-asset-block', 'table', 'hyperlink', 'entry-hyperlink', 'asset-hyperlink', 'embedded-entry-inline' ]
export const NODETYPES_TEXT:NodeType[] = [ 'heading-1', 'heading-2', 'heading-3', 'heading-4', 'heading-5', 'heading-6', 'ordered-list', 'unordered-list' ]

type InValidation = { in: string[], message?: string }
type SizeValidation = { size: { min?: number, max?: number }, message?: string }
type RangeValidation = { range: { min?: number, max?: number }, message?: string }
type EnabledMarksValidation = { enabledMarks: Mark[] }
type EnabledNodeTypesValidation = { enabledNodeTypes: NodeType[] }

type Validation = InValidation | SizeValidation | RangeValidation | EnabledMarksValidation | EnabledNodeTypesValidation

export type FieldDefinition = {
	id?: string
	name: string
	type: FieldType
	localized?: boolean
	required?: boolean
	validations?: Validation[]
	order?: number
	items: {
		type: ItemType,
		validations: Validation[]
	}
	control?: Control
}

export type WidgetId = 'singleLine' | 'multipleLine' | 'inlineEntryArray' | 'radio' | 'dropdown' | 'tagEditor' | 'url' | 'email' | 'map' | 'countryPicker' | 'boolean' | 'switch' | 'mediaGallery'

export type Control = {
	widgetId: string | WidgetId
	settings?: {
		helpText?: string
		dateOnly?: boolean
		format?: string | 'dateonly'
		unit?: string
	}
}

export const metadata: { [ className: string ]: ClassDefinition } = {}

export function getMetadataForClass(target: any): ClassDefinition {
	if (typeof target == 'string') {
		let key = target
		return metadata[key]
	}
	let key = typeof target == 'function' ? target.name : target.constructor.name
	let md = metadata[key]
	if (!md) {
		md = {
			name: key,
			displayField: undefined,
			sys: { id: key, type: 'ContentType' },
			fields: {},
			columns: {},
			table: { name: snakeCase(key) },
			class: target,
			version: '2.0',
		}
		metadata[key] = md
	}

	return md
}

function getDefaultsForType(type: string | undefined): { type: string | undefined, control?: Control } {
	if (type == 'Symbol') return { type, control: { widgetId: 'singleLine' } }
	// TODO: more type defaults
	return { type }
}

export function getMetadataForField(target: any, name: string, type?: FieldType): FieldDefinition {
	// TODO: use the type information for some plausibility checks? or even to guess the type?
	//       this is very limited though, it only gives us the name of the main type, not infos about generics, etc.
	var ref = Reflect.getMetadata('design:type', target, name)

	const md = getMetadataForClass(target)
	const order = Object.keys(md.fields).length
	md.fields[name] = {
		id: name,
		order,
		localized: false,
		required: false,
		validations: [],
		...getDefaultsForType(type),
		...md.fields[name],
		name,
	}
	return md.fields[name]
}

export type FieldType = 'Symbol' | 'Text' | 'Number' | 'Integer' | 'Date' | 'Location' | 'Boolean' | 'Array' | 'Link' | 'RichText' | 'Object'
export type ItemType = 'Symbol' | 'Link'

// TODO: array (Symbol + Link) + item validations
type FieldOptions = {
	name?: string
	type?: FieldType
	required?: boolean
	localized?: boolean
	// TODO: move to separate decorator?
	// only specify when type == 'Array'
	items?: { type: ItemType }
}

export function Field(options: FieldOptions) {
	return function (target: Object | Function, name: string) {
		// class decorator
		if (typeof target == 'function') {
			const md = getMetadataForClass(target.prototype)
		}
		// field decorator
		if (typeof target == 'object') {
			let f = getMetadataForField(target, name, options.type) as any
			if (options.type == 'Array') {
				f.items = options.items
				if (!f.items) f.items = {}
				if (!f.items.type) f.items.type = 'Symbol'
				if (!f.items.validations) f.items.validations = []
			}
			for (const o in options) f[o] = options[o]
		}
	}
}

type ControlOptions = {
	widgetId?: WidgetId
	settings?: {
		dateOnly?: boolean
		format?: string | 'dateonly'
		helpText?: string
		unit?: string
	}
}

export function Content() {
	return function (target: Object | Function, name: string) {
		if (typeof target == 'object') {
			let f = getMetadataForField(target, name) as any
			f.contentOnly = true
		}
	}
}

export function Control(options: ControlOptions) {
	return function (target: Object | Function, name: string) {
		if (typeof target == 'object') {
			let f = getMetadataForField(target, name) as any
			f.control = options
		}
	}
}

export function DisplayField() {
	return function (target: Object | Function, name: string) {
		if (typeof target == 'object') {
			let c = getMetadataForClass(target) as any
			c.displayField = name
		}
	}
}

// validation decorators

export function validation<OptionsType>(validationName: string): (options: OptionsType) => (target: Object | Function, name: string) => void {
	return function (options: OptionsType) {
		return function (target: Object | Function, name: string) {
			if (typeof target == 'object') {
				let f = getMetadataForField(target, name) as any
				let message = (options as any)?.message
				delete (options as any)?.message
				if (validationName == 'items_size') {
					f.validations.push({ size: options, message })
				}
				else if (f.type == 'Array') {
					if (!f.items) f.items = {}
					if (!f.items.validations) f.items.validations = []
					if (validationName == 'in') {
						f.items.validations.push({ in: (options as any)?.in, message })
					}
					else {
						f.items.validations.push({ [ validationName ]: options, message })
					}
				}
				else if (validationName == 'in') {
					f.validations.push({ in: (options as any)?.in, message })
				}
				else {
					f.validations.push({ [ validationName ]: options, message })
				}
			}
		}
	}
}

type SizeOptions = { min?: number, max?: number, message?: string }
export const Size = validation<SizeOptions>('size')

type ItemsSizeOptions = { min?: number, max?: number, message?: string }
export const ItemsSize = validation<ItemsSizeOptions>('items_size')

type RangeOptions = { min?: number, max?: number, message?: string }
export const Range = validation<RangeOptions>('range')

type InOptions = { in: (string | '<PROVIDED>')[], message?: string }
export const In = validation<InOptions>('in')

type RegexpOptions = { pattern: string, message?: string }
export const Regexp = validation<RegexpOptions>('regexp')
export const ProhibitRegexp = validation<RegexpOptions>('prohibitRegexp')

type EnabledMarksOptions = Mark[]
export const EnabledMarks = validation<EnabledMarksOptions>('enabledMarks')

type EnabledNodeTypesOptions = NodeType[]
export const EnabledNodeTypes = validation<EnabledNodeTypesOptions>('enabledNodeTypes')

type EnabledMarksOptionsOld = MarkOld[];
export const EnabledMarksOld = validation<EnabledMarksOptionsOld>("enabledMarks");

type LinkContentTypeOptions = string[]
export const LinkContentType = validation<LinkContentTypeOptions>('linkContentType')

// TODO: call this ContentType or EntryClass instead?
export function Class() {
	return function (target: Object | Function, name: string | undefined = undefined) {
		if (typeof target == 'function') {
			const md = getMetadataForClass(target.prototype)
		}
	}
}

export const dtoClasses = {}

export function Dto() {
	return function (target: Object | Function, name: string | undefined = undefined) {
		if (typeof target == 'function') {
			const c = getMetadataForClass(target.prototype)
			dtoClasses[c.name] = target
		}
	}
}

// ORM decorators

type TableOptions = {
	name?: string
}

type TableDefinition = {
	name: string
}

export function Table(options?: TableOptions) {
	return function (target: Object | Function, name: string | undefined = undefined) {
		if (typeof target == 'function') {
			let c = getMetadataForClass(target) as any
			c.table = options ?? {}
			if (!c.table.name) c.table.name = snakeCase(c.name)
		}
	}
}

export function getMetadataForColumn(target: any, name: string): ColumnDefinition {
	// TODO: we could use this to guess the type of the field?
	//var ref = Reflect.getMetadata('design:type', target, name)

	const md = getMetadataForClass(target)
	const order = Object.keys(md.columns).length
	const id = snakeCase(md.fields?.[name]?.id ?? name)
	md.columns[id] = {
		id,
		name,
		order,
	}
	return md.columns[id]
}

export type ColumnDefinition = {
	id?: string // name of the field
	name?: string // name of the column
	type?: string
	default?: any
	order?: number
}

type ColumnOptions = {
	name?: string
	type?: string
	default?: any
}

export function Column(options?: ColumnOptions) {
	return function (target: Object | Function, name: string) {
		if (typeof target == 'object') {
			let c = getMetadataForColumn(target, name) as any
			for (const o in options) c[o] = options[o]
		}
	}
}

export function snakeCase(str: string): string {
	if (!str) return str
	return str
		.replace(/(([a-z])(?=[A-Z][a-zA-Z])|([A-Z])(?=[A-Z][a-z]))/g,'$1_')
		.toLowerCase()
		.replace(/[^a-zA-Z0-9_]/g, '_')
		.replace(/_+/g, '_')
}