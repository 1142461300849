import { Entity, EntityRepositoryType, PrimaryKey, Property, Enum, Unique } from '@mikro-orm/postgresql'
import { EntityRepository } from '@mikro-orm/postgresql'
import { BaseEntity } from '../core/core.entities'

class AiAssistantRepository extends EntityRepository<AiAssistant> {}

export class AiAssistantConfig {
	model: string
	temperature: number
	tools: string[]
	instructions: string
}

@Entity({ repository: () => AiAssistantRepository })
@Unique({ properties: [ 'externalId' ] })
export class AiAssistant extends BaseEntity {
	[ EntityRepositoryType ]?: AiAssistantRepository

	@Property()
	name: string

	@Property()
	externalId: string

	@Property({ type: 'json', nullable: true })
	config: AiAssistantConfig

	// TODO: instructions?

	constructor(name: string, externalId: string) {
		super()
		this.name = name
		this.externalId = externalId
	}
}

class AiThreadRepository extends EntityRepository<AiThread> {}

// fast and secure cyrb53 hash function
export function hash(str: string, seed = 0) {
	let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
	for(let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i)
		h1 = Math.imul(h1 ^ ch, 2654435761)
		h2 = Math.imul(h2 ^ ch, 1597334677)
	}
	h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
	h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)
	const h = 4294967296 * (2097151 & h2) + (h1 >>> 0)
	return h.toString(36)
}

@Entity({ repository: () => AiThreadRepository })
// TODO: for the index we have to use a hash of preprompt instead!
@Unique({ properties: [ 'clientId', 'contextKey', 'prePromptHash' ] })
export class AiThread extends BaseEntity {
	[ EntityRepositoryType ]?: AiThreadRepository

	@Property()
	clientId: string

	@Property()
	contextKey: string

	// NOTE: we dont have an update function on the hash, so the prePrompt is essentially immutable!
	@Property({ type: 'text' })
	prePrompt: string

	// TODO: the onCreate is not being executed.. why?
	@Property()
	prePromptHash: string

	@Property()
	externalId: string

	@Property()
	lastUsed: Date = new Date()

	@Property()
	usageCount: number = 0

	@Property()
	totalInputChars: number = 0

	@Property()
	totalOutputChars: number = 0

	constructor(clientId: string, contextKey: string, externalId: string) {
		super()
		this.clientId = clientId
		this.contextKey = contextKey
		this.externalId = externalId
	}
}