import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import HttpUtil from "./plugins/HttpUtil.js"
import VueFilePicker from 'vue-file-picker'
import router from './router'
import axios from "axios"
import VueAxios from "vue-axios"
import moment from 'moment'
import store from './stores/store.ts'
import vuetifyProTipTap from './plugins/tiptap'
import vMaskV3 from './directives/v-mask'
import { translationUtil } from './utils/translation-util'
import FreshdeskWidget from './utils/FreshdeskWidget'
import { DATE_FORMATS } from '@/constants'
import mysSwitch from '@/components/mys/mys-switch.vue'
import mysTags from '@/components/mys/mys-tags.vue'
import Field from '@/components/fields/Field.vue'

import '@/assets/css/materialdesignicons.min.css'

const app = createApp(App)

let host = window.location.origin

axios.defaults.baseURL = `${host}/api`

app.mixin({
	methods: {
		formatDate(value, format = DATE_FORMATS.DATE) {
			if (!value) return ''

			return moment(String(value)).format(format)
		},
		formatDateTime(value) {
			if (!value) return
			return moment(String(value)).format(DATE_FORMATS.DATE_TIME)
		},
		formatPrice(value) {
			if (!value) return '0.00'
			return parseFloat(value).toFixed(2)
		},
		truncate(text, length, suffix) {
			if (text.length > length) {
				return text.substring(0, length) + suffix
			}
			else {
				return text
			}
		},
	},
})

// filter out some warnings
const originalConsoleWarn = console.warn
console.warn = (...args) => {
	if (typeof args[0] == 'string') {
		if (args[0].startsWith('[Vue warn]: A plugin must')) return
		if (args[0].startsWith('[Vue warn]: injection "activePage"')) return
	}
	originalConsoleWarn(...args)
}

async function init() {
	await store.init()
	const i18n = await translationUtil.getI18n(store)

	app.use(store)
	app.use(HttpUtil)
	app.use(VueAxios, axios)
	app.use(VueFilePicker)
	app.use(vuetify)
	app.use(i18n)
	app.use(router)
	app.use(vuetifyProTipTap, {
		vuetify,
		iconsGroup: 'mdi'
	})

	app.component('mys-switch', mysSwitch)
	app.component('mys-tags', mysTags)
	app.component('Field', Field)
	app.directive('mask', vMaskV3)

	router.isReady().then(() => {
		app.mount('#app')
	})
	router.beforeEach((to, from, next) => {
		/*
		// TODO: this is not complete..
		//       how to check if the user is logged in here? can we access the store?
		if (to.meta?.requiresAuth === undefined || to.meta?.requiresAuth === true) {
			return { path: '/login', query: { redirect: to.fullPath } }
		}
		*/
		next()
	})

	// Freshdesk Widget
	const freshdeskWidgetApp = createApp(FreshdeskWidget)

	freshdeskWidgetApp.mount('#freshdesk-widget')
}
init()