<template>
	<div class="TicketOptionExternalSystemSkidata" v-if="ticketOption">
		<div class="row">
			<Field :title="$t('text.dtaProductId')">
				<v-text-field v-model="ticketOption.fields.external.dtaProductId"
					variant="outlined" hide-details style="background-color:#ffffff"
					:error-messages="skidataProductIdError"
				/>
			</Field>
			<Field :title="$t('text.dtaConsumerId')">
				<v-text-field v-model="ticketOption.fields.external.dtaConsumerId"
					variant="outlined" hide-details style="background-color:#ffffff"
					:error-messages="skidataConsumerIdError"
				/>
			</Field>
			<Field :title="$t('text.dtaConfiguration')">
				<v-select v-model="ticketOption.fields.external.dtaCustomerConfig"
					style="background-color:#ffffff"
					variant="outlined" hide-details
					:error-messages="skidataClientConfigError"
					:items="skidataClientConfig"
					:item-title="item => item?.id"
					:item-value="item => item?.id"
					data-cy="dtaCustomerConfig"
				/>
			</Field>
		</div>
	</div>
</template>

<script lang="ts">
import Common from '../../mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		ticketOption: Object,
	},
	data: () => ({
		loading: false,
		accessTypes: [] as any[],
		skidataClientConfig: [],
	}),
	computed: {
		skidataProductIdError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.dtaProductId?.length ? this.$t('text.externalSystemRequired') : ''
		},
		skidataConsumerIdError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.dtaConsumerId?.length ? this.$t('text.externalSystemRequired') : ''
		},
		skidataClientConfigError(): string {
			if (!this.userIsOperator && this.spTrustLevel <= 2) { return '' }
			return !this.ticketOption?.fields.external.dtaCustomerConfig?.length ? this.$t('text.externalSystemRequired') : ''
		},
		valid() {
			return !this.skidataProductIdError && !this.skidataConsumerIdError && !this.skidataClientConfigError
		},
	},
	methods: {
		async setExternalSystem() {
			this.getTicketAccessTypes()

			this.ticketOption.fields.external = {
				dtaProductId: this.ticketOption?.fields.external.dtaProductId ?? '',
				dtaConsumerId: this.ticketOption?.fields.external.dtaConsumerId ?? '',
				dtaCustomerConfig: this.ticketOption?.fields.external.dtaCustomerConfig ?? '',
			}
			await this.getSkidataClientConfig()
		},
		getTicketAccessTypes() {
			this.accessTypes = []
			this.accessTypes = [
				{ id: 'Standard', title: this.$t('text.accessTypeSkidataPermission') },
				{ id: 'Reservierung', title: this.$t('text.accessTypeSkidataPickup') },
			]
		},
		async getSkidataClientConfig() {
			if (this.skidataClientConfig?.length > 0) return
			
			this.loading = true
			const response = await this.$httpGet(`/peak/skidata-client-config/${this.$store.state.selectedClient.fields.installationId.de}`)
			this.skidataClientConfig = response ? Object.keys(response).map((key) => response[key]) : []
			this.loading = false
		},
	},
	beforeMount() {
		if (!this.ticketOption?.fields.external) {
			this.ticketOption.fields.external = {
				dtaProductId: '',
				dtaConsumerId: '',
				dtaCustomerConfig: '',
			}
		}
	},
	async mounted() {
		await this.setExternalSystem()
	},
}
</script>

<style scoped>
.row { display: flex; gap: 10px; width: 100%; }
.row > * { flex: 1 0; }
.row > .Field { border-left: none; padding: 0; }
</style>