import 'vuetify/styles'
import { createVuetify, IconAliases } from 'vuetify'
import { en, de, fr, it, nl  } from 'vuetify/locale'
import * as components from 'vuetify/components'

import iconAi from '@/assets/icons/icon-ai.svg?component';
import iconMop from '@/assets/icons/icon-mop.svg?component';
import iconWork from '@/assets/icons/icon-work.svg?component';
import iconSchool from '@/assets/icons/icon-school.svg?component';
import iconWavingHand from '@/assets/icons/icon-waving-hand.svg?component';
import iconPersonCelebrate from '@/assets/icons/icon-person-celebrate.svg?component';
import iconSentimentSatisfied from '@/assets/icons/icon-sentiment-satisfied.svg?component';
import iconThumbUp from '@/assets/icons/icon-thumb-up.svg?component';
import iconHandshake from '@/assets/icons/icon-handshake.svg?component';

import { aliases as defaultAliases } from 'vuetify/iconsets/mdi';

const aliases: IconAliases = {
	...defaultAliases,
	iconAi,
	iconMop,
	iconWork,
	iconSchool,
	iconWavingHand,
	iconPersonCelebrate,
	iconSentimentSatisfied,
	iconThumbUp,
	iconHandshake
};

export default createVuetify({
	locale: {
		locale: 'en',
		fallback: 'en',
		messages: { en, de, fr, it, nl },
	},
	components: {
		...components,
	},
	icons: {
		aliases,
	},
})