<template>
	<v-expansion-panels v-model="model" class="SideBarNavigation">
<!-- TODO: should this really be an expandable panel? -->
		<v-expansion-panel elevation="0">
			<v-expansion-panel-title>
				{{title}}
				<span v-if="titleErrorIcon" style="float:right"><v-icon :color="titleErrorIconColor">{{ titleErrorIcon }}</v-icon></span>
			</v-expansion-panel-title>
			<v-expansion-panel-text>
				<ul id="navlist">
					<a v-for="section of sections" :key="section.id"
						@click="setActive($event, section)"
						:href="'#' + section.id"
					>
						<li :class="{ active: section.id == activeLink }">
							{{ section.title ?? $t('text.' + section.id) }}
							<span v-if="section.state==='error'" style="float:right"><v-icon :color="section.id === activeLink ? '#fff' : section.errorIconColor ?? '#FDB537'">mdi-alert</v-icon></span>
							<span v-if="section.validIcon && section.state==='valid'" style="float:right"><v-icon :color="section.id === activeLink ? '#fff' : section.validIconColor ?? '#FDB537'">mdi-check</v-icon></span>
						</li>
					</a>
				</ul>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	props: {
		title: String,
		sections: Array,
		expanded: Boolean,
		preselectedActiveLink: String,
		titleErrorIcon: String,
		titleErrorIconColor: String,
		modelValue: String
	},
	data: () => ({
		model: -1,
		activeLink: '',
	}),
	methods: {
		open() {
			this.model = 0
		},
		close() {
			this.model = -1
		},
		toggle() {
			if (this.model === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
		setActive($event, section) {
			this.activeLink = section.id
			this.$emit('set-active', section.id)
			if (section.preventDefault)
				$event.preventDefault()
		},
	},
	watch: {
		modelValue() {
			this.activeLink = this.modelValue
		}
	},
	mounted() {
		if (this.expanded) {
			this.model = 0
		}
		if (this.preselectedActiveLink) {
			this.activeLink = this.preselectedActiveLink
		}
	},
}
</script>

<style scoped>
.v-expansion-panel-title { display: none; }
</style>