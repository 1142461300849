<template>
	<div v-if="resellerChannelSelected">
		<v-label>{{ $t('text.resellerCategories') }}</v-label>
		<div class="container" v-if="!items || items.length === 0">{{$t('text.noResellerCategories')}}</div>
		<div class="container" v-if="items && items.length > 0">
			<div v-for="item of items" :key="item.sys.id">
				<input type="checkbox" :id="'b2bCategory-' + item.sys.id"
					:checked="selected(item.fields.peakId.de)||item.fields.mandatory.de" 
					@click="toggleItem(item.fields.peakId.de)" 
					:disabled="item.fields.mandatory.de || !enableSelection || !isCurrentClientEditing"
				/>
				<label :for="'b2bCategory-' + item.sys.id">
					{{ item.fields.title.de }}
				</label>

				<div v-for="subCategory of item.fields.subCategories.de" :key="subCategory.peakId" class="indent">
					<input type="checkbox" :id="'b2bSubCategory-' + subCategory.peakId"
						:checked="selected(subCategory.peakId)" 
						@click="toggleItem(subCategory.peakId)"
						:disabled="!isCurrentClientEditing"
					/>
					<label :for="'b2bSubCategory-' + subCategory.peakId">
						{{ subCategory.title }}
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'ResellerCategorySelector',
	mixins: [ Common ],
	props: {
		modelValue: Array, // of salesChannelAssignment
		items: Array, // of resellerCategories
		isCurrentClientEditing: Boolean
	},
	data() {
		return {
		model: {}, // salesChannel.id => salesChannelAssignment
		modelBackup: {},
		itemsModel: [],
		showNoCatsMessage: false,
		showResellerChannelMessage: false,
		enableSelection: true
	}},
	watch: {
		modelValue(n) {
			this.model = this.valueToModel(n)
			if (this.items?.length > 0) {
				this.itemsModel = this.itemsToModel(this.items, n)
			}
		},
		items(n) {
			this.itemsModel = this.itemsToModel(n, this.modelValue)
		},
	},
	computed: {
		resellerChannelSelected() {
			for (const key of Object.keys(this.model)) {
				if (this.model[key].addl?.isResellerChannel == true || this.model[key].fields?.salesChannel?.de?.fields?.isResellerChannel?.de === true) {
					return true
				}
			}
			return false
		}
	},
	mounted() {
		this.model = this.valueToModel(this.modelValue)
		this.itemsModel = this.itemsToModel(this.items, this.modelValue)
		this.enableSelection = this.spEnabledSetting(this.$store.state.selectedClient.fields.marketplaceSettings?.de, 'spSalesChannelSelectionEnabled')
	},
	methods: {
		valueToModel(v) {
			const m = {}
			for (const o of v ?? []) {
				if (o.error) { console.warn(o); continue }
				m[o.fields.salesChannel.de.sys.id] = o
			}
			return m
		},
		modelToValue(m) {
			let v = []
			for (const i in m ?? {}) {
				v.push(m[i])
			}
			return v
		},
		itemsToModel(salesChannels, assignments) {
			let model = []
			let lookup = {} // salesChannel.id => salesChannel
			if (salesChannels?.length > 0) {
				for (let salesChannel of salesChannels) {
					const key = salesChannel.sys.id
					lookup[key] = salesChannel
					model.push(salesChannel)
				}
			}
			
			// TODO: do we really want to do this? seems weird to have invalid SC assignments..
			// add the items of the value that didnt come from client..
			if (assignments?.length > 0) {
				for (let a of assignments) {
					if (a.error) continue
					const salesChannel = lookup[a.fields.salesChannel.de.sys.id]
					if (!salesChannel) {
						// a.fields.salesChannel.de.info = 'NOT_ON_CLIENT'
						model.push(a.fields.salesChannel.de)
					}
				}
			}
			
			return model
		},
		toggleItem(itemId) {
			let key = ''

			for (const objKey of Object.keys(this.model)) {
				if (this.model[objKey].addl?.isResellerChannel === true || this.model[objKey].fields?.salesChannel?.de?.fields?.isResellerChannel?.de === true) {
					key = objKey
					break
				}
			}

			if (!this.model[key].fields.resellerCategories?.de) {
				this.model[key].fields["resellerCategories"] = { de : [] }
			}

			const index = this.model[key].fields.resellerCategories.de.findIndex(x => x === itemId)

			if (index > -1) {
				//Remove id
				this.model[key].fields.resellerCategories.de.splice(index,1)
			} else {
				//Add id
				this.model[key].fields.resellerCategories.de.push(itemId)
			}

			this.afterToggleItem()
		},
		afterToggleItem(item) {
			this.$emit('input', this.modelToValue(this.model))
		},
		selected(itemId) {
			let key = ''
			for (const objKey of Object.keys(this.model)) {
				if (this.model[objKey].addl?.isResellerChannel == true || this.model[objKey].fields?.salesChannel?.de?.fields?.isResellerChannel?.de === true) {
					key = objKey
					break
				}
			}

			if (this.model[key].fields?.resellerCategories?.de?.findIndex(x => x === itemId) > -1) {
				return true
			} else {
				return false
			}
		}
	},
}
</script>

<style scoped>
.container { border: 1px solid silver; border-radius: 5px; padding: 5px 10px; }
input[type="checkbox"] { width: 15px; height: 15px; }
label { padding-left: 5px; }
.indent { padding-left:25px }
</style>