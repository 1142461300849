import moment from 'moment'

// NOTE: this file is shared between server and client
//       i have noticed that the client does not (always?) hot reload this file
//       so when making changes, make sure to reload the browser application

// this model is used here:
//   product.seasonalPrices: PricingTimespan[]
// and the correlating UI component:
//   <PriceMatrixCalendar v-model="product.seasonalPrices" ... />

export class PricingTimespan {
	id: number
	color: string
	name: string
	start?: string
	end?: string
	weekdays?: number[]
	dates?: string[]
	prices: TicketPrice[]
}

export class TicketPrice {
	ticketType: string
	ticketCategory: string
	price: number
	// only set for merged prices
	sourceId?: number
}

class DayMinimal {
	date: string
	weekday: string
}

// the following functions are used at:
// - the client to calculate the display of the price matrix
// - the server side to convert the price matrix to a price list that Peaksolution needs

export function timespanMatches(timespan: PricingTimespan, day) {
	if (!timespan) return false
	return day.date >= timespan.start
		&& day.date <= timespan.end
		&& (timespan.weekdays.includes(day.weekday) || timespan.weekdays.length == 0)
}

// merges the pricematrig "source" into "target"
// keeps track of sourceId so we know the origin of a price later
export function mergePricesInto(sourceId: number, source: TicketPrice[], target: TicketPrice[]) {
	for (const price of source) {
		if (price?.price === undefined || price?.price === null || price?.price as any === '') continue
		const i = target.findIndex(p => p.ticketType == price.ticketType && p.ticketCategory == price.ticketCategory)
		if (i == -1)
			target.push({ ...price, sourceId })
		else
			target[i] = { ...price, sourceId }
	}
}

export function mergeProfilesInto(profiles: PricingTimespan[], target: TicketPrice[], day: DayMinimal) {
	for (const profile of profiles as PricingTimespan[]) {
		if (day && profile.dates) {
			if (profile.dates.includes(day.date) || profile.dates.includes(day.weekday)) {
				mergePricesInto(profile.id, profile.prices, target)
			}
			continue
		}
		if (!timespanMatches(profile, day)) continue
		mergePricesInto(profile.id, profile.prices, target)
	}
}

export function calcPriceMatrix(profiles: PricingTimespan[], basePrices: TicketPrice[], day: DayMinimal): TicketPrice[] {
	const prices: TicketPrice[] = []
	mergePricesInto(-1, basePrices, prices)
	const timespans = profiles
		.filter(p => !p.dates)
		.filter(p => !!p.start)
		.sort((a, b) => a.start?.localeCompare(b?.start))
	mergeProfilesInto(timespans, prices, day)
	const exceptions = profiles.filter(p => p.dates)
	mergeProfilesInto(exceptions, prices, day)
	return prices
}

export function intersects(a: PricingTimespan, b: PricingTimespan) {
	return a.end > b.start && a.start < b.end
}

export function calcOverlaps(profiles: PricingTimespan[]) {
	// copy the profiles so we can sort them
	profiles = [...profiles]
	profiles.sort((a, b) => (!a.start || !b.start) ? 0 : a.start.localeCompare(b.start))

	const r = {}

	// we do some preprocessing for performance reasons
	const dateWeekdays = {} // date -> weekday
	const profileInfos: { [ profileId: string ]: { realdates: string[], weekdays: string[] } } = {}
	for (const profile of profiles) {
		const info = { realdates: [], weekdays: [] }
		profileInfos[ profile.id ] = info
		if (profile.dates) {
			for (const date of profile.dates) {
				// TODO: also calc the weekday for every date
				if (date.match(/^\d/)) {
					info.realdates.push(date)
					dateWeekdays[date] = moment(date).format('dddd').toLowerCase()
				}
				else info.weekdays.push(date)
			}
		}
	}

	let ai = 0
	for (const a of profiles) {
		ai++
		let bi = 0
		for (const b of profiles) {
			bi++

			// no self-overlaps
			if (a == b) continue

			// filter out duplicates A-B / B-A
			if (ai > bi) continue

			// ignore any profiles that have mutually exclusive weekdays
			if (a.weekdays?.length && b.weekdays?.length && !a.weekdays.some(w => b.weekdays.includes(w))) continue

			// calculate the overlapping span
			let span = null
			if (a.start && b.start && a.end && b.end) {
				// ignore any profiles that don't overlap
				if (!intersects(a, b)) continue

				const start = a.start > b.start ? a.start : b.start
				const end = a.end < b.end ? a.end : b.end
				// TODO: actually we should only count the overlapping weekdays..
				//       this would be quite complex though..
				// we add 1 to the duration because the end date is inclusive
				const duration = moment(end).diff(start, 'days') + 1
				span = { start, end, duration }
			}

			// calculate the overlapping dates/weekdays
			let dates = []
			if (a.dates && b.dates) {
				const aInfo = profileInfos[ a.id ]
				const bInfo = profileInfos[ b.id ]

				// any of the dates or weekdays match
				dates.push(...a.dates.filter(d => b.dates.includes(d)))

				// the date from a matches with a weekday from b
				for (const aRealdate of aInfo.realdates) {
					if (!bInfo.weekdays.includes(dateWeekdays[aRealdate])) continue
					dates.push(aRealdate)
				}

				// a date from b matches with a weekday from a
				for (const bRealdate of bInfo.realdates) {
					if (!aInfo.weekdays.includes(dateWeekdays[bRealdate])) continue
					dates.push(bRealdate)
				}
			}
			if (!dates?.length) dates = null

			if (!span && !dates) continue

			if (!r[ a.id ]) r[ a.id ] = { profile: a, overlaps: [] }
			r[ a.id ].overlaps.push({ profile: b, span, dates })
		}
	}
	return Object.values(r)
}

export class PeaksolutionPriceListItem {
	fromDate: string
	toDate: string
	price: number
	metadata: string
}

export function calcBasePrices(ticketOptions): TicketPrice[] {
	return ticketOptions.map((option: any) => {
		const ticketType = option.fields?.ticketType?.de
		const ticketCategory = option.fields?.reduction?.de
		return {
			ticketType: ticketType?.sys?.id,
			ticketCategory: ticketCategory?.sys?.id,
			price: option.fields.price.de,
		}
	})
}

export function calcPeaksolutionPriceList(
	profiles: PricingTimespan[],
	basePrices: TicketPrice[],
	ticketType: String,
	ticketCategory: String,
): PeaksolutionPriceListItem[] | undefined {
	if (!profiles?.length) return undefined

	// TODO: find real first date (?)
	//       similar for last date
	const start = moment().format('YYYY-MM-DD')
	const end = moment().add(10, 'years').format('YYYY-MM-DD')

	// TODO: this approach is doing a lot of useless work because we calculate the matrix
	//       for every day, but then throw away everything except one matrix cell.
	//       it would be better to calculate for all variants at once and then split..

	// calculate the price matrix for every day in the range
	const dayMatrices: { day: DayMinimal, matrix: TicketPrice[] }[] = []
	for (let date = moment(start); date.isBefore(end); date.add(1, 'day')) {
		const day = {
			date: date.format('YYYY-MM-DD'),
			weekday: date.format('dddd').toLowerCase()
		}
		const matrix = calcPriceMatrix(profiles, basePrices, day)
		dayMatrices.push({ day, matrix })
	}

	const profileNameById = profiles.reduce((acc, profile) => { acc[profile.id] = profile.name; return acc }, {})

	// filter out the prices for the requested ticket type and category
	const dayPrices: { date: string, price: number, profileName: string }[] = []
	for (const dayMatrix of dayMatrices) {
		const price = dayMatrix.matrix.find(p => p.ticketType == ticketType && p.ticketCategory == ticketCategory)
		if (price === undefined || price === null || price as any === '') continue
		dayPrices.push({
			date: dayMatrix.day.date,
			price: price.price,
			profileName: profileNameById[ price.sourceId ] ?? 'BASE',
		})
	}

	const prices: PeaksolutionPriceListItem[] = []
	let currentPrice: PeaksolutionPriceListItem = null
	for (const dayPrice of dayPrices) {
		if (currentPrice && currentPrice.price == dayPrice.price
			&& currentPrice.metadata == dayPrice.profileName
		) {
			currentPrice.toDate = dayPrice.date
		}
		else {
			currentPrice = {
				fromDate: dayPrice.date,
				toDate: dayPrice.date,
				price: dayPrice.price,
				metadata: dayPrice.profileName
			}
			prices.push(currentPrice)
		}
	}
	return prices.filter(p => p.metadata != 'BASE')
}