<template>
	<Application :style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }">
		<SideBar class="dcm-sidebar" :navigation="sections" :preselectedActiveLink="activeLink"
			@action="handleAction($event)" />

		<template v-if="isContractorsView">
		</template>
		<ContractTemplatesView v-if="isContractsView" />
		<ContractorsView v-if="isContractorsView" />
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar.vue"
import Application from '../Application.vue'
import ContractTemplatesView from './ContractTemplatesView.vue'
import ContractorsView from './ContractorsView.vue'

export default {
	name: 'ContractManagementView',
	components: { SideBar, Application, ContractTemplatesView, ContractorsView },
	mixins: [Common],
	data() {
		return {
			sections: [],
			activeLink: ''
		}
	},

	computed: {
		isAlturosOperator() {
			return this.$store.state.loggedInUser?.fields?.email?.de === 'operator@alturos.com'
		},
		isContractsView() {
			return this.activeLink === 'contracts'
		},
		isContractorsView() {
			return this.activeLink === 'contractors'
		}
	},
	watch: {
	},
	methods: {
		setSections() {
			this.sections.push({ id: 'contractors', state: 'valid' })
			if (this.isAlturosOperator) {
				this.sections.push({ id: 'contracts', state: 'valid' })
			}
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setActive(key) {
			this.activeLink = key
		}
	},
	mounted() {
		this.setSections()
		if (this.$store.state.contractsViewActiveLink) {
			this.setActive(this.$store.state.contractsViewActiveLink)
		}
		else {
			this.setActive('contractors')
		}
	},
}
</script>