<template>
	<FilterCombo :modelValue="model" :fields="fields" />
</template>

<script lang="ts">
import FilterCombo from './FilterCombo.vue'
import ContractExecutive from '../../../../api2/src/modules/contract/ContractExecutive'

export default {
	components: { FilterCombo },
	props: {
		modelValue: Object,
		disabledFilters: { type: Array, default: () => [] },
	},
	data() {
		return {
			model: null as any,
			contractSequences: [] as any,
			salesChannels: [] as any,
		}
	},
	computed: {
		fields() {
			return [
				{ id: 'contractSequence', contentType: '_all', description: this.$t('text.contractorFilterContractSequenceName'), type: 'Symbol', scope: 'fields', in: this.contractSequencesMap, modes: ['eq'] },
				{ id: 'noEmail', contentType: '_all', description: this.$t('text.contractorFilterEmailMissing'), type: 'Boolean', scope: 'fields' },
				{ id: 'noContracts', contentType: '_all', description: this.$t('text.contractorFilterContractsMissing'), type: 'Boolean', scope: 'fields' },
				{ id: 'salesChannels', contentType: '_all', description: this.$t('text.contractorFilterContractorSalesChannels'), type: 'Symbol', scope: 'fields', in: this.contractorSalesChannelsMap, modes: ['eq'] },
				{ id: 'contractState', contentType: '_all', description: this.$t('text.contractorFilterContractState'), type: 'Symbol', scope: 'fields', in: [ 'NEW', 'SIGNED', 'SENT', 'EXPIRED' ] },
			].filter(f => !this.disabledFilters.includes(f.id))
		},
		contractSequencesMap() {
			const result = {}
			this.contractSequences.forEach(cs => {
				result[cs.id] = cs.name
			})
			return result
		},
		contractorSalesChannelsMap() {
			const result = {}
			this.salesChannels.forEach(cs => {
				result[cs.peakId] = cs.title
			})
			return result
		},
	},
	watch: {
		model: {
			deep: true,
			handler(n) {
				this.$emit('update:modelValue', this.mapComboFilterModel(n))
			},
		}
	},
	methods: {
		mapComboFilterModel(model: any) {
			return {
				...model,
				filters: model.filters?.map(f => ({
					field: f.field,
					mode: f.mode,
					value: f.type == 'Boolean' ? f.value == 'Yes' : f.value,
				})) || [],
			}
		},
		async loadContractSequences() {
			try {
				let contractExecutive = new ContractExecutive(this)
				this.contractSequences = await contractExecutive.getContractSequences(this.$store.state.selectedClient.sys.id)
			} catch (error) {
				console.log(error)
			}
		},
		async loadContractorSalesChannels() {
			try {
				let contractExecutive = new ContractExecutive(this)
				this.salesChannels = await contractExecutive.getContractorSalesChannels(this.$store.state.selectedClient.sys.id)
			} catch (error) {
				console.log(error)
			}
		},
	},
	created() {
		this.model = this.modelValue
	},
	async mounted() {
		await this.loadContractSequences()
		await this.loadContractorSalesChannels()
	}
}
</script>

<style scoped>
</style>