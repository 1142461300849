<template>
	<Application :isSideBarFixed="isSideBarFixed">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goBack">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<v-btn-toggle class="ml-2">
				<v-btn id="btnMobileApp" class="firstButton" elevation="0" @click="toggleView">
					<v-icon class="d-lg-none">mdi-cellphone</v-icon>
					<span class="d-none d-lg-inline">{{ $t('text.mobileApp') }}</span>
				</v-btn>
				<v-btn id="btnWebApp" class="lastButton_active" elevation="0" @click="toggleView">
					<v-icon class="d-lg-none">mdi-web</v-icon>
					<span class="d-none d-lg-inline">{{ $t('text.webApp') }}</span>
				</v-btn>
			</v-btn-toggle>
		</template>

		<SideBar :navigation="sections" @action="handleAction($event)" />

		<!-- How to use the web app -->
		<div id="howToUseWebApp" style="margin-top: 64px;">
			<Disclosure :title="$t('text.howToUseWebApp')" ref="SECTION_howToUseWebApp">
				<p v-html="$t('text.webAppLabel1')" class="override-default"/>
				<p class="helpText" v-html="$t('text.webAppDesc1')"/>
			
				<p v-html="$t('text.webAppLabel2')" class="override-default"/>
				<p class="helpText" v-html="$t('text.webAppDesc2')"/>

				<p v-html="$t('text.webAppLabel3')" class="override-default"/>
				<p class="helpText" v-html="$t('text.webAppDesc3')"/>

				<p v-html="$t('text.webAppLabel4')" class="override-default"/>
				<p class="helpText" v-html="$t('text.webAppDesc4')"/>

				<p class="helpText" v-html="$t('text.webAppHelp')"></p>
			</Disclosure>
		</div>

		<!-- Scan Code -->
		<div id="validateTickets">
			<Disclosure :title="$t('text.validateTickets')" ref="SECTION_validateTickets" :expanded="true">
				<div class="field left-border">
					<div style="max-width: 400px;">
						<v-label>{{ $t('text.selectGate') }}</v-label>
							<v-select
								variant="outlined"
								v-model="gateId"
								:items="ticketServiceData?.gates"
								item-title="description"
								item-value="gateKey"
								required
								class="mb-4"
							/>
					</div>
					<v-label>{{ $t('text.enterCode') }}</v-label>
					<div style="display: flex; gap: 10px;">
						<v-text-field variant="outlined" required 
							data-cy="ticketCode"
							v-model.trim="qrCodeData" 
							:hide-details="qrCodeDataError===''"
							:error-messages="qrCodeDataError" 
							@keyup.enter="validateQRCodeData()"
						/>
						<v-btn class="gradientButton btn-spacing" elevation="0" :disabled="qrCodeData===''" @click="clearQRCodeData()">{{$t('text.clear')}}</v-btn>
						<v-btn class="blueButton btn-spacing" elevation="0" theme="dark" :disabled="qrCodeData==='' || !gateId" @click="validateQRCodeData()">{{$t('text.validate')}}</v-btn>
					</div>
					<p class="helpText" v-html="$t('text.codeScanHelp')"></p>
				</div>
			</Disclosure>
		</div>

		<!-- History -->
		<div id="history">
			<Disclosure :title="$t('text.history')" ref="SECTION_history" :expanded="true">
				<v-text-field variant="outlined" density="compact" clearable hide-details
					data-cy="search"
					prepend-inner-icon="mdi-magnify"
					:placeholder="$t('text.searchTitle')"
					v-model="searchString"
					@keyup="searchRedemptionHistory()"
					@click:clear="clearSearch()"
				/>
				<v-data-table fixed-header
					v-model="selected"
					:headers="headers"
					:items="redemptionHistory"
					:items-per-page="limit"
					:mobile-breakpoint="700"
					item-key="name"
					:item-value="item => item"
				>
					<template v-slot:item="{ item }">
						<tr @click="viewTicketHistory(item)">
							<td class="padded-row" align="center">
								<img src="@/assets/icons/validity-checker/success.png" width="24px" height="24px"/>
							</td>
							<td class="padded-row">
								{{ truncate(item.ticketInfo.productName, 70, '...') }}<br/>
								<span class="generalLabel">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</span>
							</td>
							<td class="padded-row">
								{{ item.usageDate.split(' ')[0] }}<br/>
								<span class="generalLabel">{{ item.usageDate.split(' ')[1] }}</span>
							</td>
							<td class="padded-row">{{ item.ticketInfo.identificationId }}</td>
							<td class="padded-row">{{ getTypeFromSKU(item.ticketInfo.sku) }}</td>
						</tr>
					</template>
					<template #bottom></template>
				</v-data-table>
			</Disclosure>
		</div>

		<!-- Ticket Redemption Dialog -->
		<TicketRedemption v-if="showTicketRedemption" :isGuestcard="isGuestcard" :ticketServiceResponse="ticketServiceResponse" :gateId="gateId" :qrCodeData="qrCodeData" @close-dialog="updateView"/>

		<!-- Ticket History Dialog -->
		<TicketHistory v-if="showTicketHistory" :ticket="selectedTicket" @close-dialog="showTicketHistory=false"/>

		<!-- Message Dialog -->
		<MessageDialog 
			:show="showMessage"
			:confirmLabel="confirmLabel"
			:cancelLabel="this.$t('text.cancel')"
			:confirm-handler="confirmHandler"
			:cancel-handler="closeMessage"
			title=""
			:type="messageType"
			:messageTitle="messageTitle"
			:messageText="messageText" 
			:showRetry="canRetry"
		/>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar.vue"
import Disclosure from "@/components/common/Disclosure.vue"
import MessageDialog from '@/components/validityChecker/MessageDialog.vue'
import TicketRedemption from '@/components/validityChecker/TicketRedemption.vue'
import TicketHistory from '@/components/validityChecker/TicketHistory.vue'
import Application from '../Application.vue'
import Loading from '@/mixins/Loading.vue'

export default {
	mixins: [ Common, Loading ],
	components: { Disclosure, SideBar, MessageDialog, TicketRedemption, TicketHistory, Application },
	props: {
		ticketServiceData: {
			type: Object,
			default: null
		}
	},
	data() { return {
		hasError: false,
		activeLink: '',
		sections: [
			{ id: 'howToUseWebApp', state: 'valid' },
			{ id: 'validateTickets', state: 'valid' },
			{ id: 'history', state: 'valid' },
		],

		installationId: this.$store.state.selectedClient.fields.installationId?.de,
		serviceProviderGateGroups: [],
		gateId: '',
		qrCodeData: '',
		qrCodeDataError: '',
		ticketServiceResponse: null,

		showMessage: false,
		canRetry: false,
		messageType: '',
		messageTitle: '',
		messageText: '',
		confirmLabel: '',
		cancelLabel: '',

		showTicketRedemption: false,
		showTicketHistory: false,
		isGuestcard: false,

		searchString: '',
		redemptionHistory: [],
		limit: 999,
		selected: [],
		selectedTicket: null,
	}},
	computed: {
		headers () {
			return [
				{ key: 'validity', title: this.$t('text.validity'), width: '30px', sortable: false, cellClass: 'truncate' },
				{ key: 'productAndServiceProvider', title: this.$t('text.productAndServiceProvider'), width: '50%', sortable: false, cellClass: 'truncate' },
				{ key: 'date', title: this.$t('text.date'), width: '10%', sortable: false, cellClass: 'truncate' },
				{ key: 'id', title: this.$t('text.id'), width: '20%', sortable: false },
				{ key: 'type', title: this.$t('text.type'), width: '20%', sortable: false },
			]
		},
	},
  methods: {
    handleAction(event) {
			this[event.function](event.params)
		},
	async validateQRCodeData() {
		await this.loadingOverlay(async () => {
			try {
				this.ticketServiceResponse = await this.$httpPost('/validitychecker/validate-qrcode',
					{ 
						qrCodeData: this.qrCodeData,
						gateId: this.gateId,
						clientId: this.$store.state.selectedClient.sys.id
					})

				if (!this.ticketServiceResponse) {
					const text = this.ticketServiceResponse?.guestcard?.type ? this.$t('text.guestcardInvalid') : this.$t('text.ticketInvalid')
					this.showInvalidWarning(text)
				} else {
					if (this.ticketServiceResponse?.guestcard?.type) {
						this.isGuestcard = true
					}

					// Handle multiple permissions case
					if (this.ticketServiceResponse.resultInfo?.code === 2010) {
						// Convert identifications to expected format
						this.ticketServiceResponse.tickets = this.ticketServiceResponse.identifications.map(el => ({
							ticketInfo: el.ticketInfo,
							contingentInfo: el.contingentInfo,
							orderInfo: el.orderInfo
						}))
						this.showTicketRedemption = true
					} else if (this.ticketServiceResponse.ticketInfo) {
						// Handle single ticket case - wrap in array for consistent handling
						this.ticketServiceResponse.tickets = [{
							ticketInfo: this.ticketServiceResponse.ticketInfo,
							contingentInfo: this.ticketServiceResponse.contingentInfo,
							orderInfo: this.ticketServiceResponse.orderInfo
						}]
						this.showTicketRedemption = true
					} else {
						this.showInvalidWarning(this.$t('text.ticketInvalid'))
					}
				}

				this.getRedemptionHistory()
			} catch (e) {
				this.showGeneralError()
			}
		}, 'validateQRCodeData')
	},
    getRedemptionHistory() {
      if (this.ticketServiceResponse?.usages?.length) {
        for (const usage of this.ticketServiceResponse.usages) {
          usage.ticketInfo = this.ticketServiceResponse.ticketInfo
          this.redemptionHistory.push(...this.ticketServiceResponse.usages)
        }
      }
     
      if (this.ticketServiceResponse?.identifications?.length) {
        for (const identification of this.ticketServiceResponse.identifications) {
          if (!identification.usages?.length) continue

          for (let usage of identification.usages) {
            usage.ticketInfo = identification.ticketInfo
          }
          
          this.redemptionHistory.push(...identification.usages)
        }
      }
      return []
    },
    searchRedemptionHistory() {
      this.redemptionHistory = []
        this.getRedemptionHistory()

      if (this.searchString?.length) {
        const results = this.redemptionHistory.filter(x => x.ticketInfo?.productName.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1)
        this.redemptionHistory = results?.length ? results : []
      } 
    },
    viewTicketHistory(ticket) {
      this.selectedTicket = ticket
      this.selectedTicket.orderInfo = this.ticketServiceResponse.orderInfo
      this.showTicketHistory = true
    },
    showGeneralError() {
      this.messageType = 'error'
			this.messageTitle = this.$t('text.generalErrorTitle')
			this.messageText = this.$t('text.errorUnexpected')
			this.canRetry = true
			this.confirmLabel = ''
			this.showMessage = true
    },
    showInvalidWarning(text) {
      this.messageType = 'warning'
		  this.messageTitle = this.$t('text.invalidErrorTitle')
		  this.messageText = text
      this.confirmLabel = this.$t('text.done')
		  this.canRetry = false
		  this.showMessage = true
    },
    clearQRCodeData() {
      this.qrCodeData = ''
    },
    getTypeFromSKU(sku) {
      if (this.isGuestcard === true) {
        return this.$t('text.guestcardIncludedOffer')
      } else if (sku.indexOf('.ADDON.') > -1) {
        return this.$t('text.addon')
      } else if (sku.indexOf('SKIPASS.') > -1) {
        return this.$t('text.skipass')
      } else if (sku.indexOf('ACCESSORY.') > -1) {
        return this.$t('text.accessory')
      } else if (sku.indexOf('COUPON_') > -1) {
        return this.$t('text.coupon')
      } else {
        return this.$t('text.ticket')
      }
    },
    setActive(key) {
      this.activeLink = key
      if (this.$refs['SECTION_' + key]) {
        this.$refs['SECTION_' + key].open()
      }
    },
    confirmHandler() {
      this.showMessage = false
      
      if (this.canRetry === true) {
        this.validateQRCodeData()
      }
    },
    closeMessage() {
      this.showMessage = false
    },
	  toggleView() {
      this.$emit('toggle-view')
    },
	async updateView() {
		await this.loadingOverlay(async () => {
			this.showTicketRedemption = false
			
			this.ticketServiceResponse = await this.$httpPost('/validitychecker/validate-qrcode',
			{ 
				qrCodeData: this.qrCodeData,
				gateId: this.gateId,
				clientId: this.$store.state.selectedClient.sys.id
			})
			this.getRedemptionHistory()
		}, 'updateView')
	},
    goBack() {
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
.title { font-size: 24pt; color: #000000; font-weight: bold; }
.override-default { color: black !important; font-weight: bold; margin-bottom: 8px; font-size: 1.1rem !important; }
.btn-spacing { height: 56px !important; min-width: 100px !important; margin-top: 12px; }
.generalLabel { color: #999999 !important; font-size: 12pt;}
.padded-row { padding-top:10px !important; padding-bottom:10px !important }
</style>