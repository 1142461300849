<template>
	<Application :loading="loading || productKindsLoading"
		:style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px;"
				@click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<h1>{{ contractTemplateTitle }}</h1>
		</template>
		<SideBar class="dcm-sidebar" :actions="sidebarActions" :navigation="sections"
			:preselectedActiveLink="'contracts'" @action=" handleAction($event)" />

		<div style="margin-top: 60px;">
			<Disclosure :title="$t('text.contractInformation')" :error="!!childErrorMessage" expanded
				:message="childErrorMessage" ref="SECTION_contractInformation">
				<FieldSet>
					<Field typeName="ContractTemplate" fieldName="name" v-model="contractTemplate.name" dataCy="name" />
					<Field typeName="ContractTemplate" fieldName="startDate" v-model="contractTemplate.startDate"
						dataCy="startDate" />
					<Field typeName="ContractTemplate" fieldName="dueDays" v-model="contractTemplate.dueDays"
						dataCy="dueDays" :info-text="$t('text.contractTemplateDueDaysInfoText')" />
					<Field typeName="ContractTemplate" fieldName="text" v-model="contractTemplate.text" dataCy="text"
						:info-text="$t('text.contractTemplateTextInfoText')" />
				</FieldSet>

				<FieldSet :label="$t('text.miscellaneous')" :required="true">
					<Field typeName="ContractTemplate" fieldName="needsSignature"
						v-model="contractTemplate.needsSignature" dataCy="needsSignature"
						:info-text="$t('text.contractTemplateNeedsSignatureInfoText')" />
				</FieldSet>
				<!-- <FieldSet :label="$t('text.eCommerceCategory')"
					:info-text="$t('text.contractTemplateProductKindsInfoText')" :required="true">
					<Field v-model="contractTemplate.productKinds" typeName=" ContractTemplate" fieldName="productKinds"
						dataCy="productKinds">
						<ContractKindSelector v-model="contractTemplate.productKinds.de" :productKinds="productKinds" />
					</Field>
				</FieldSet> -->
			</Disclosure>
		</div>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar.vue"
import Application from '../Application.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import Disclosure from '@/components/common/Disclosure.vue'
import Field from '@/components/fields/Field.vue'
import ChildErrorReceiver from '../packageDesigner/ChildErrorReceiver.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import ContractKindSelector from '../../../components/contract/ContractKindSelector.vue'

export default {
	name: 'ContractTemplateDesigner',
	components: { SideBar, Application, Disclosure, Field, FieldSet, ContractKindSelector },
	mixins: [Common, ChildErrorReceiver],
	props: {
		id: String
	},
	data() {
		return {
			contractTemplate: {
				text: { }
			},
			sections: [],
			productKinds: [],
			loading: false,
			productKindsLoading: false,
			activeLink: '',
		}
	},

	computed: {
		contractTemplateTitle() {
			return this.contractTemplate?.name?.de
		},
		sidebarActions() {
			const actions = []
			actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'saveContractTemplate', params: 'save', datacy: "save", disabled: this.childErrorMessage })
			return actions
		}
	},
	methods: {
		goback() {
			this.$store.state.contractsViewActiveLink = 'contracts'
			this.$router.push({
				path: '/contractManagement'
			})
		},
		async getContractTemplate() {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractTemplateDetails(this.$store.state.selectedClient.sys.id, this.id)
				this.contractTemplate = result
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
		async saveContractTemplate() {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				await contractExecutive.upsertContractTemplate(this.$store.state.selectedClient.sys.id, this.contractTemplate)
				this.goback()
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
		async getProductKinds() {
			this.productKindsLoading = true
			try {
				const response = await this.$httpGet(`/peak/attribute-sets/${this.$store.state.selectedClient.fields.installationId.de}`)
				this.productKinds = response?.attributeSets.map(x => x.attribute_set_name)
			}
			catch (error) {
				console.error('cannot load attribute sets', error)
			}
			this.productKindsLoading = false
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setActive(key) {
			this.activeLink = key
		}
	},
	mounted() {
		this.sections.push({ id: 'contracts', state: 'valid' })
		this.getProductKinds()
		if (this.id) {
			this.getContractTemplate()
		}
	},
}
</script>