<template>
	<div class="TicketInfoExternalSystem" v-if="modelValue">
		<Field :title="$t('text.externalSystem')" :helpText="$t('text.externalSystemHelp')" required>
			<!--
				@update:modelValue="setExternalSystem"
			-->
			<!-- TODO: use model driven field -->
			<v-select variant="outlined" density="compact" return-object
				:disabled="disabled"
				:items="[ 'Skidata', 'Axess', 'SBBv3' ]"
				v-model="modelValue.fields.ticket.de.fields.external.system"
				:error-messages="modelValue.fields.ticket.de.fields.external.system ? [] : [ $t('text.externalSystemRequired') ]"
				:hide-details="modelValue.fields.ticket.de.fields.external.system"
				data-cy="externalSystem"
			/>
		</Field>
		<!--
			TODO: is this only meant for skidata?
			TODO: currently the access types are loaded in TicketOptionExternalSystemSkidata.vue
				move to separate comp and link here..
			TODO: uncomment when the PEAK Import API supports the access type for external events

			<div v-if="hasField('ticketInfo','externalSystem', model)" class="pt-4 pb-4">
				<v-label>{{ $t('text.ticketAccessType') }}</v-label>
				<v-select
						outlined density="compact" return-object hide-details
						:items="accessTypes"
						v-model="modelValue.fields.ticket.de.fields.external.accessType" 
						:item-text="item => item.title"
						data-cy="externalSystemAccessType"
				/>
				<p class="helpText" v-html="$t('text.ticketAccessTypeHelp')"/>
			</div> 
		-->
	</div>
</template>

<script lang="ts">
import Common from '../../mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		modelValue: Object,
		disabled: Boolean,
	},
	methods: {
	},
	async mounted() {
	},
}
</script>

<style scoped>
</style>