<template>
	<CommonTable :headers="headers" :items="items" :total="total" :sortBy="sortBy" :limit="limit" :offset="offset"
		@update:sortBy="$emit('update:sortBy', $event)" @update:limit="$emit('update:limit', $event)"
		@update:offset="$emit('update:offset', $event)" :noDataText="$t('text.noDigitalContractTemplatesAvailable')">
		<template #[`item.name`]="{ item }">
			<div>{{ cleanName(item.name) }}</div>
		</template>

		<template #[`item.startDate`]="{ item }">
			<div>{{ formatDate(item.startDate) }}</div>
		</template>

		<!-- <template #[`item.productKinds`]="{ item }">
			<div :style="'display: flex; gap: 10px; flex-wrap: wrap;'">
				<template v-for="(category, index) in item.productKinds">
					<Chip v-if="index < 2" :key="`category-${index}`" :title="category" />
				</template>
				<Chip v-if="item.productKinds.length > 2" :key="`category-${index}`" :title="`+ ${item.productKinds.length - 2} ${ $t('text.more') }`" />
			</div>
		</template> -->

		<template #[`item.actions`]="{ item }">
			<div>
				<v-btn class="my-2" variant="outlined" icon="mdi-pencil" rounded="lg"
					@click="$router.push('/contractTemplate?id=' + item.id)" />
			</div>
		</template>
	</CommonTable>
</template>

<script>
import Common from '../../mixins/Common.vue'
import CommonTable from '../common/tables/Table.vue'
import Chip from '../common/controls/Chip.vue'

export default {
	name: 'ContractTemplatesTable',
	components: { CommonTable, Chip },
	emits: [ 'update:limit', 'update:offset' ],
	props: {
		items: { type: Array, default: () => [] },
		total: Number,
		limit: Number,
		offset: Number,
		sortBy: Array
	},
	mixins: [Common],

	computed: {
		headers() {
			return [
				{ title: this.$t('text.name'), key: 'name', value: 'name' },
				{ title: this.$t('text.startDate'), key: 'startDate', value: 'startDate' },
				// { title: this.$t('text.eCommerceCategory'), key: 'productKinds', value: 'productKinds' },
				{ title: '', key: 'actions', value: 'actions', width: '50px', sortable: false },
			]
		}
	},
	methods: {
		cleanName(name) {
			return name?.replace(/<.+\/>/g, '')
		}
	}
}
</script>