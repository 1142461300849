<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<Navbar :fetchData="getProducts" :showLoader="showLoader" @filter-change="setFilter">
				<template v-if="!userIsOperator" #add-button>
					<div>
						<AddNewTypeDialog ref="addNewServiceDialogRef" :showLoader="showLoader" type="product" />
						<v-btn id="addService" class="gradientButton" size="small" elevation="0" @click="showAddService" data-cy="addService">
							<v-icon>mdi-plus</v-icon>
							<span class="d-none d-lg-inline">{{ $t('text.addService') }}</span>
						</v-btn>
					</div>
				</template>
			</Navbar>
		</template>

		<v-card class="tableCard">
			<v-data-table fixed-header
				:headers="headers"
				:items="products"
				:items-per-page="limit"
				:mobile-breakpoint="900"
				@update:sort-desc="sortTable($event)"
				style="border-radius: 0"
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr @click="getServiceDetail(item)">
						<td>
							<span v-if="hasListIcon(item)">
								<v-img style="opacity:0.5;max-width:28px;max-height:28px"
									width="28" height="28"
									:src="getAssetUrl(item.fields.serviceType.de.fields.listIcon.de)">
								</v-img>
							</span>
						</td>
						<td class="serviceTitle">
							<div class="type">
								<span class="source" v-if="getSource(item)">{{ getSource(item) }}</span>
								<span class="spName" v-if="userIsOperator">{{ item.fields.serviceProvider?.de?.fields?.title?.[selectedLocale] }}</span>
							</div>
							{{ item.fields.title[selectedLocale] }}
						</td>
						<td>{{ getFirstProductCategory(item) }}</td>
						<td style="line-height: 1;">
							<div style="display:inline-block" v-for="cai of item.fields.clientAssignments.de" :key="cai.sys.id">
								<span v-if="cai?.fields?.status?.de"
									class="clientAssignmentChip"
									:class="{ [cai?.fields?.status?.de]: true, }"
									:title="cai?.fields?.status?.de ?? ''"
								>
									{{ getClientId(cai) }}
								</span>
							</div>
						</td>
						<td><ApprovalStatus :status="getClientStatus(item)" /></td>
						<td align="center">
							<v-tooltip location="bottom">
								<template #activator="{ props }">
									<v-icon color="#64c823" v-if="item.addl?.dataStatus === 'complete'" v-bind="props">mdi-check-circle</v-icon>
									<v-icon color="#ffb400" v-if="item.addl?.dataStatus === 'incomplete'" v-bind="props">mdi-alert</v-icon>
									<v-icon color="#ffb400" v-if="!item.addl" v-bind="props">mdi-warning</v-icon>
								</template>
								<span class="tooltip">{{ itemStatusTooltip(item) }}</span>
							</v-tooltip>
						</td>
					</tr>
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" :limit="limit" :results="products" :total="productCount" @update:modelValue="getProducts({ filter: this.$store.state.filter })" />
				</template>
			</v-data-table>
		</v-card>
	</Application>
</template>

<script>
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import Application from '../Application.vue'
import { DATE_FORMATS } from '@/constants'
import uniqBy from 'lodash/uniqBy'
import Navbar from './Navbar.vue'
import AddNewTypeDialog from './AddNewTypeDialog.vue'

export default {
	name: 'ServicesView',
	components: { ApprovalStatus, TablePaginator, Application, Navbar, AddNewTypeDialog },
	mixins: [ Common ],
	data() {
		return {
			offset: this.$store.state.offset,
			limit: 15,
			loading: false,
			fullPage: true,
			errorTitle: '',
			errorDetail: '',
			products: [],
			productCount: 0,
			searchSubmitted: false,
			serviceProvider: this.$store.state.selectedServiceProvider,
			filter: this.$store.state.filter
		}
	},
	computed: {
		headers() {
			let serviceTitleWidth = '50%'
			if (this.$store.state.loggedInUser.fields?.type?.de === 'operator' && this.$store.state.isImpersonation === false) {
				serviceTitleWidth = '30%'
			}
			let headers = [
				{ title: '', key: 'list_icon', sortable: false },
				{ title: this.$t('text.service'), key: 'service' },
				{ title: this.$t('text.serviceType'), key: 'service_type', width: '15%', sortable: false },
				{ title: this.$t('text.clientAssignments'), key: 'client_assignments', width: '20%', sortable: false },
				{ title: this.$t('text.approval'), key: 'approval', width: '15%', sortable: false },
				{ title: this.$t('text.status'), key: 'status', width: '50px', align: 'center', sortable: false },
			]
			return headers
		},
		// we have 2 distinct appComponents that both trigger this view.
		// this app can be used as operator or as service provider
		// this function should return true for the operator case
		appIsServiceManager() {
			// TODO: we should use different paths for the 2 cases instead..
			return this.$store.state.selectedComponent?.fields?.title?.en == 'Manage Services'
		},
		searchString() {
			return this.$store.state.searchString
		},
	},
	methods: {
		setFilter(filter) {
			this.$store.commit('setFilter', filter)
		},
		async showAddService() {
			this.$refs.addNewServiceDialogRef.getServiceTypes()
		},
		showLoader(value = false) {
			this.loading = value
		},
		itemStatusTooltip(item) {
			if (item.addl?.dataStatus === 'complete') {
				return this.$t('text.complete')
			} else if (item.addl?.dataStatus === 'incomplete') {
				return this.$t('text.incomplete')
			} else {
				return this.$t('text.incomplete')
			}
		},
		hasListIcon(item) {
			return item?.fields?.serviceType?.de?.fields?.listIcon?.de
		},
		getSource(product) {
			let clientCA = product.fields?.clientAssignments?.de?.find(ca => ca.fields.isHomebase.de === true)
			if (!clientCA) { clientCA = product.fields.clientAssignments.de[0] }
			if (!clientCA?.fields?.sku?.de?.length || clientCA?.fields?.sku?.de?.indexOf('MYSERVICES') > -1) return ''
			if (clientCA?.fields?.sku?.de?.indexOf('SKIDATA') > -1) return 'MYS.SKIDATA'
			if (clientCA?.fields?.sku?.de?.indexOf('AXESS') > -1) return 'MYS.AXESS'
			if (clientCA?.fields?.sku?.de?.indexOf('SBBV3') > -1) return 'MYS.SBBV3'
			return clientCA?.fields?.sku?.de?.split('.')[1]
		},
		getFirstProductCategory(product) {
			let currentCA = product.fields.clientAssignments?.de?.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)

			if (!currentCA) {
				//Service has clientAssignments other than the home client
				currentCA = product.fields.clientAssignments?.de?.[0]
			}
			
			return currentCA?.fields?.productCategories?.de?.[0]?.fields?.title?.[this.selectedLocale] ?? ''
		},
		getClientStatus(product) {
			let currentCA = product.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)

			if (!currentCA) {
				//Service has clientAssignments other than the home client
				currentCA = product.fields.clientAssignments?.de?.[0]
			}

			return currentCA?.fields?.status?.de
		},
		getClientId(cai) {
			return cai.fields?.client?.de?.fields?.clientId?.de ? cai.fields.client.de.fields.clientId.de : ''
		},
		async sortTable(event) {
			await this.getProducts({ sortMode: event[0] })
		},
		async getProducts({ sortMode } = {}) {
			await this.$store.commit('setOffset', this.offset)
			
			var url = ""

			if (this.appIsServiceManager) {
				if (this.userIsOperator) {
					url = `/products?`
				} else {
					url = `/products/${this.serviceProvider.sys.id}?`
				}
			} else {
				url = `/products/${this.serviceProvider.sys.id}?`
			}

			url += `clientId=${this.$store.state.selectedClient.sys.id}`

			if (this.$store.state.isImpersonation === true) {
				if (this.serviceProvider.fields.tags?.de) {
					url += `&tags=${this.serviceProvider.fields.tags.de}`
				}
			} else if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
				url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
			}
			console.log('filter', this.$store.state.filter)
			if (this.$store.state.filter) {
				url += `&filter=${JSON.stringify(this.$store.state.filter)}`
			}
			if (this.searchString?.length > 0) {
				url += `&searchString=${encodeURIComponent(this.searchString)}`
			}

			this.loading = true

			try {
				url += `&skip=${this.offset}&limit=${this.limit + 1}`
				const res = await this.$httpGet(url)
				this.loading = false
				this.products = res?.products
				this.productCount = res?.total ? res.total : 0
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			this.loading = false
		},
		async getServiceDetail(item) {
			if (this.userIsOperator) {
				this.loading = true
				try {
					await this.$store.commit('setSelectedServiceProvider', item.fields.serviceProvider.de)
					await this.$store.commit('setSelectedProduct', { sys: { id: item.sys.id } })
					this.$router.push(`/services/${ item.sys.id }`)
				}
				catch (error) {
					this.errorTitle = this.$t('text.ERROR');
					this.errorDetail = error.response ? error.response.error : error
				}
				this.loading = false
			}
			else {
				await this.$store.commit('setSelectedProduct', { sys: { id: item.sys.id } })
				this.$router.push(`/services/${ item.sys.id }`)
			}
		},
	},
	created () {
		FreshdeskWidget.methods.prefill('MyServices')
	},
	async mounted() {
		await Promise.all([this.$store.commit('setSelectedServiceType', {}), this.getProducts()])
	},
}
</script>

<style scoped>
.scrollable { overflow-y: auto; overflow-x: hidden; }

td { font-size: 12pt !important; }
.serviceTitle .type { font-size: 10pt; line-height: 1em; opacity: 0.4; margin-top: 4px; }
.serviceTitle .type .source { margin-right: 5px; }
</style>
