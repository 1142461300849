<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<v-text-field variant="outlined" density="compact" clearable hide-details prepend-inner-icon="mdi-magnify"
				:placeholder="$t('text.search')" v-model="searchString" @keyup.enter="search()" @click:clear="search()"
				data-cy="search" />
			<v-spacer />
			<!-- <v-btn data-cy="addTemplateButtons" size="small" class="blueButton" elevation="0"
				@click="$router.push('/contractTemplate')">
				<v-icon>mdi-plus</v-icon>
				<span class="d-none d-md-inline">{{ $t('text.addTemplate') }}</span>
			</v-btn> -->
		</template>

		<ContractTemplatesList :items="displayedTemplates" :total="total" :limit="limit" :offset="offset"
			:sort-by="sortBy" @update:limit="updateLimit" @update:offset="updateOffset" @update:sortBy="updateSortBy"
			style="margin-top: 64px;" />
	</Application>
</template>

<script>
import ContractTemplatesList from "../../../components/contract/ContractTemplatesList.vue"
import Application from '../Application.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'

export default {
	name: 'ContractManagementView',
	components: { ContractTemplatesList, Application },
	data() {
		return {
			templates: [],
			displayedTemplates: [],
			loading: false,
			errorTitle: '',
			errorDetail: '',
			limit: 10,
			offset: 0,
			total: 0,
			searchString: '',
			sortBy: []
		}
	},
	methods: {
		goback() {
			this.$router.push('/')
		},
		async getTemplates() {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractSequences(this.$store.state.selectedClient.sys.id)
				this.templates = result.flatMap(x => x.contractTemplates)
				this.total = this.templates.length
				this.applyFilters()
			} catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.loading = false
			}
		},
		applyFilters() {
			let filteredTemplates = [...this.templates]

			if (this.searchString) {
				const searchTerm = this.searchString.toLowerCase()
				filteredTemplates = filteredTemplates.filter(template =>
					template.name.toLowerCase().includes(searchTerm)
				)
			}

			if (this.sortBy && this.sortBy.length) {
				filteredTemplates.sort((a, b) => {
					for (const { key, order } of this.sortBy) {
						const valueA = a[key]
						const valueB = b[key]

						if (valueA == null && valueB == null) continue
						if (valueA == null) return order === "asc" ? 1 : -1
						if (valueB == null) return order === "asc" ? -1 : 1

						if (Array.isArray(valueA) && Array.isArray(valueB)) {
							const firstA = valueA[0] || ''
							const firstB = valueB[0] || ''
							if (firstA < firstB) return order === "asc" ? -1 : 1
							if (firstA > firstB) return order === "asc" ? 1 : -1
						} else {
							if (valueA < valueB) return order === "asc" ? -1 : 1
							if (valueA > valueB) return order === "asc" ? 1 : -1
						}
					}
					return 0
				})
			}

			const start = this.offset
			const end = this.offset + this.limit
			this.displayedTemplates = filteredTemplates.slice(start, end)
			this.total = filteredTemplates.length
		},
		updateLimit(limit) {
			this.limit = limit
			this.offset = 0
			this.applyFilters()
		},
		updateOffset(offset) {
			this.offset = offset
			this.applyFilters()
		},
		updateSortBy(sortBy) {
			this.sortBy = sortBy
			this.offset = 0
			this.applyFilters()
		},
		search() {
			this.offset = 0
			this.applyFilters()
		},
	},
	mounted() {
		this.getTemplates()
	},
}
</script>