<script lang="ts">
import eventBus from '../utils/eventBus.js'

export default {
	methods: {
		showSuccessToast(message = 'changesSuccessfullyProcessed') {
			const toastMessage = message.startsWith('text.') ? this.$t(message) : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'success')
		},
		showErrorToast(message = 'errorProcessingChanges') {
			const toastMessage = message.startsWith('text.') ? this.$t(message) : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'error')
		},
		showWarningToast(message = 'genericWarning') {
			const toastMessage = message.startsWith('text.') ? this.$t(message) : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'warn')
		},
	},
}
</script>