<template>
	<v-container class="pa-0">
		<div v-if="mediaAsset.fields.file">
			<Disclosure :title="mediaAsset.fields.file?.upload?.filename ?? ''" :small-title="true" :expanded="true" :delete-icon="mediaAsset.fields.isURLUpload" @remove="removeFile"
				:warning="mediaHasPoorQuality"
				:message="$t('text.poorQualityWarning')"
			>
				<template #header-accessory>
					<div style="flex: 1;" />
					<EnhanceImageButton :mediaHasPoorQuality="mediaHasPoorQuality" @show-improve-image-dialog="showImproveImageDialog" />
				</template>

				<v-col>
					<v-row>
						<v-col>
							<img class="thumbnail" v-if="mediaAsset.fields.file" :src="mediaAsset.fields.file.dataURL" :key="thumbnailHash" style="width: 100%; height: auto; max-width:350px;border-radius:4px"/>
						</v-col>

						<v-col>
							<v-label>{{$t('text.title')}}&nbsp;<span class="error-text">({{$t('text.required')}})</span></v-label>
							<LanguageFlag v-model="serviceLocale" class="flag" />
							<v-text-field variant="outlined" density="compact" counter="256" maxLength="256"
							id="title"
							v-model.trim="mediaAsset.fields.title[serviceLocale]"
							:error-messages="mediaAsset.titleError"
							></v-text-field>
						
							<v-label>{{$t('text.altText')}}</v-label>
							<LanguageFlag v-model="serviceLocale" class="flag" />
							<v-text-field variant="outlined" density="compact" counter="256" maxLength="256"
							v-model="mediaAsset.fields.altText[serviceLocale]" 
							persistent-hint></v-text-field>
							<p class="helpText" v-html="$t('text.altTextHelp')"/>
						</v-col>
					</v-row>

					<div>
						<v-label>{{$t('text.description')}}</v-label>
						<LanguageFlag v-model="serviceLocale" class="flag" />
						<v-textarea variant="outlined" counter="180" maxLength="180"
						v-model="mediaAsset.fields.description[serviceLocale]"></v-textarea>
					</div>

					<!-- Image editing section -->
					<div v-if="mediaAsset.fields.file">
						<div class="mt-4">
							<v-label>{{$t('text.imageEditing')}}</v-label>
							<p class="helpText" v-html="$t('text.imageEditingHelp')"/>

							<div>
								<v-btn class="gradientButton" @click="$refs.mediaDialog.show = true" :disabled="mediaAsset.fields.isURLUpload === true"><v-icon>mdi-crop</v-icon> {{$t('text.openEditor')}}</v-btn>
								<p cv-if="mediaAsset.fields.isURLUpload === true" class="helpText" v-html="$t('text.uploadImageBeforeEdit')"/>
								<br/>
							</div>

							<div>
								<p class="helpText" v-html="$t('text.cropsPreview')"/>

								<v-row class="mt-2">
									<v-col
										v-for="aspectRatio in aspectRatios"
										:key="aspectRatio.value"
										class="mb-4"
										cols="3"
									>
										<div>
											<div class="text-subtitle-2">{{ $t(`text.${aspectRatio.text}`) }}</div>
											<div class="image-container" >
												<v-img width="300" cover :aspect-ratio="aspectRatio.value" :src="mediaAsset.fields.file.dataURL" style="border-radius: 4px;"></v-img>
												<div class="grid-container">
												<div v-for="(position, index) in positions" :key="index" :class="['grid-item', { active: focalPointForImage === position }]" ></div>
												</div>
											</div>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</div>
				</v-col>
			</Disclosure>
		</div>

				<!-- Edit Image -->
				<template v-if="mediaAsset.fields.media">
					<div v-if="mediaHasPoorQuality">
						<div class="warning-icon" style="display: flex; align-items: center; padding-bottom: 10px;">
							<v-icon color="warning">mdi-alert</v-icon>
							<p class="warning-text">{{$t('text.poorQualityWarning')}}</p>
						</div>
					</div>
					<v-row no-gutters class="pt-4" style="position: relative;">
					<v-col class="thumbnail col-12 col-md-6 pl-0">
						<div style="display: flex; align-items: center;">
							<EnhanceImageButton :mediaHasPoorQuality="mediaHasPoorQuality" @show-improve-image-dialog="showImproveImageDialog" />
						</div>
						<input type="file" ref="file" id="fileBrowser" accept="image/jpg, image/jpeg, image/png" style="display: none" @change="chooseFile()">
						<img ref="imageDataRef" :src="imgData" style="width: 100%; height: auto; max-width:350px;border-radius:4px"/>
					</v-col>

					<v-col class="col-12 col-md-6 pr-0">
						<div>
						<div>
							<v-label>{{$t('text.title')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
							<LanguageFlag v-model="serviceLocale" class="flag" />
							<v-text-field class="mt-1" variant="outlined" density="compact" counter="256" maxLength="256" 
							id="title"
							v-model.trim="mediaAsset.fields.title[serviceLocale]"
							:error-messages="mediaAsset.titleError"></v-text-field>
						</div>

						<div>
							<v-label>{{$t('text.altText')}}</v-label>
							<LanguageFlag v-model="serviceLocale" class="flag" />
							<v-text-field class="mt-1 " variant="outlined" density="compact" counter="256" maxLength="256"
							v-model="mediaAsset.fields.altText[serviceLocale]"
							persistent-hint></v-text-field>
							<p class="helpText" v-html="$t('text.altTextHelp')"/>
						</div>
						</div>
					</v-col>

					<div style="width: 100%" class="pt-4">
						<v-label>{{$t('text.description')}}</v-label>
						<LanguageFlag v-model="serviceLocale" class="flag" />
						<v-textarea class="mt-1" variant="outlined" counter="180" maxlength="180"
						v-model="mediaAsset.fields.description[serviceLocale]" ></v-textarea>
					</div>

					<!-- Image editing section -->
					<div>
						<div class="mt-4">
						<v-label>{{$t('text.imageEditing')}}</v-label>
						<p class="helpText" v-html="$t('text.imageEditingHelp')"/>
						<br/>

						<div>
							<v-btn class="gradientButton" @click="$refs.mediaDialog.show = true"><v-icon>mdi-crop</v-icon> {{$t('text.openEditor')}}</v-btn>
							<br/>
						</div>

						<div>
							<p class="helpText" v-html="$t('text.cropsPreview')"/>

							<v-row class="mt-2">
								<v-col
									v-for="aspectRatio in aspectRatios"
									:key="aspectRatio.value"
									class="mb-4"
									cols="3"
								>
									<div>
										<div class="text-subtitle-2">{{ $t(`text.${aspectRatio.text}`) }}</div>
										<div class="image-container" >
											<v-img width="300" cover :aspect-ratio="aspectRatio.value" :src="imgData" style="border-radius:4px !important"></v-img>
											<div class="grid-container">
											<div v-for="(position, index) in positions" :key="index" :class="['grid-item', { active: focalPointForImage === position }]" ></div>
											</div>
										</div>
									</div>
								</v-col>
							</v-row>

						</div>

						</div>
					</div>
					</v-row>
				</template>
				<MediaEditorDialog ref="mediaDialog"
					:showClose="true"
					:confirmLabel="$t('text.confirmSelection')"
					:cancelLabel="$t('text.discardChanges')"
					:nextStepLabel="$t('text.nextStep')"
					:previousStepLabel="$t('text.previousStep')"
					:confirm-handler="confirmMedia"
					:cancelHandler="cancelMedia"
					:title="$t('text.imageEditor')"
					:subtitle="imageSubtitle"
					:stepper="true"
					@step-changed="(val) => step = val"
					:height="'80vh'"
					:width="'70vw'"
					:isValid="!goodQualityImage"
					:fixIndexes="fixIndexes">
					<template #content>
						<ImageEditor
							ref="imageEditor"
							:selectedImage="mediaAsset"
							:edit="imgData ? true : false"
							:step="step"
							@poor-quality="(val) => goodQualityImage = val"
						/>
					</template>
				</MediaEditorDialog>

		<Dialog ref="improveImageConfirmDialog"
			data-cy="improveImageConfirmDialog"
			:confirmLabel="$t('text.enhanceImage')"
			:cancelLabel="$t('text.useWithoutEnhancement')"
			:confirm-handler="confirmImproveImageQuality"
			:cancel-handler="cancelImproveImageQuality"
			:showClose="false"
			:title="$t('text.lowImageQualityTitle')"
			:height="'360px'"
			:width="'540px'"
		>
			<template #content>
			<v-row justify="center" align="center" style="padding: 10px">
				<v-col class="warning-icon-col">
				<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
				</v-col>
				<v-col class="text-col">
				<span class="dialogText">{{$t('text.lowImageQualityText')}}</span>
				</v-col>
			</v-row>
			</template>
		</Dialog>

		<Dialog ref="originalVsImprovedImageDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="acceptImprovedImage"
			:cancel-handler="rejectImprovedImage"
			:showClose="false"
			:title="$t('text.originalVsImprovedImageTitle')"
			width="800px"
		>
			<template #content>
			<div class="image-comparison-container">
				<ImageComparison ref="imageComparison"
					:beforeImage="originalImage.dataURL"
					:afterImage="improvedImage.base64"
					:value="50"
					:step="0.1"
				/>
			</div>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaEditorDialog from '@/components/common/MediaEditorDialog.vue'
import Dialog from '@/components/common/Dialog.vue'
import ImageEditor from './ImageEditor.vue'
import ImageComparison from './ImageComparison.vue'
import Loading from '@/mixins/Loading.vue'
import Toast from '@/mixins/Toast.vue'
import EnhanceImageButton from './EnhanceImageButton.vue'

export default {
	name: 'MediaItem',
	components: { LanguageFlag, Disclosure, MediaEditorDialog, Dialog, ImageEditor, ImageComparison, EnhanceImageButton },
	mixins: [Common, Loading, Toast],
	emits: ['remove-media-file'],
	props: {
		mediaAsset: { type: Object },
		fixIndexes: {
		type: Boolean,
		default: true
	  },
	  isURLUpload: {
		type: Boolean,
		default: false
	  }
	},

  data() {
      return {
        imgData: '',
        step: 1,
        thumbnailHash: '',
        croppedImageUrl: '',
        aspectRatios: [
        { text: 'square1x1', value: 1 },
        { text: 'landscape16x9', value: 16/9 },
        { text: 'landscape4x3', value: 4/3 },
        { text: 'landscape3x2', value: 3/2 },
        { text: 'portrait3x4', value: 3/4 },
        ],
        positions: [
          'top left',
          'top',
          'top right',
          'left',
          'center',
          'right',
          'bottom left',
          'bottom',
          'bottom right',
        ],
        goodQualityImage: false,
      }
  },

	computed: {
		mediaHasPoorQuality() {
			return this.mediaAsset.fields.isPoorQuality?.de || 
				(this.mediaAsset.fields.media?.de?.fields?.file?.de?.details?.image?.width < 1200 || 
				this.mediaAsset.fields.media?.de?.fields?.file?.de?.details?.image?.height < 1000) || 
				(this.mediaAsset.fields.file?.width < 1200 || 
				this.mediaAsset.fields.file?.height < 1000)
		},
		focalPointForImage() {
			return this.mediaAsset.fields.imageFocusArea?.de || 'center'
		},
		imageSubtitle() {
			// edit image
			if (this.imgData) {
				const width = this.mediaAsset.fields.media?.de?.fields?.file?.de?.details?.image?.width
				const height = this.mediaAsset.fields.media?.de?.fields?.file?.de?.details?.image?.height
				if (!width || !height) return ''
				// return this.mediaAsset.fields.media.de.fields.file.de.fileName + '(' + width + 'x' + height + ')'
				return this.mediaAsset.fields.title[this.serviceLocale] + '(' + width + 'x' + height + ')'
			}
			// new image
			else {
				const filename = this.mediaAsset.fields.file?.upload?.filename ?? ''
				const width = this.mediaAsset.fields.file?.upload?.width ?? ''
				const height = this.mediaAsset.fields.file?.upload?.height ?? ''
				return filename + '(' + width + 'x' + height + ')'
			}
		},
	},

	methods: {
		cropImage(url) {
			// New upload
			if (!this.imgData) {
				this.mediaAsset.fields.file.dataURL = url
				this.thumbnailHash = Math.random()
			}
			// Edit existing image
			else {
				this.imgData = url
				this.mediaAsset.fields.imageData = url
				// the actually url on the object is updated in the parent component when the image is saved
				// this.mediaAsset.fields.media.de.fields.file.de.url = url
				this.thumbnailHash = Math.random()
			}
		},
		removeFile() {
			this.$emit('remove-media-file', this.mediaAsset.fields.file)
		},
		setImageData(data) {
			let imageData = this.mediaAsset.fields.media.de.fields.file.de.url

			if (data) {
				imageData = data
			}
			return imageData
		},
		chooseFile() {
			const tmpThis = this

			const fileList = document.getElementById("fileBrowser").files;
			const fileReader = new FileReader();
			if (fileReader && fileList && fileList.length) {
				fileReader.readAsArrayBuffer(fileList[0]);
				fileReader.onload = function () {
				const imageData = fileReader.result;
				tmpThis.imgData = 'data:image/jpg;base64,' + tmpThis.arrayBufferToBase64(imageData)
				};
			}
		},
		arrayBufferToBase64( buffer ) {
			var binary = ''
			const bytes = new Uint8Array( buffer )
			const len = bytes.byteLength

			for (var i = 0; i < len; i++) {
				binary += String.fromCharCode( bytes[ i ] )
			}

			return window.btoa( binary )
		},
		cancelMedia() {
			this.$refs.mediaDialog.show = false
		},
		confirmMedia() {
			this.$refs.mediaDialog.show = false
			
			// we trigger the getCroppedImageUrl method in the child component - ImageEditor to get the cropped image URL
			const croppedImageUrl = this.$refs.imageEditor.getCroppedImageUrl()
			this.cropImage(croppedImageUrl)

			const focalPoint = this.$refs.imageEditor.focalPoint
			// we call it imageFocusArea because James expects this model name
			// this.mediaAsset.fields.imageFocusArea = { de: focalPoint }
			this.mediaAsset.fields.imageFocusArea = { de: focalPoint }
		},

		showImproveImageDialog() {
			this.$refs.improveImageConfirmDialog.show = true
		},

		confirmImproveImageQuality() {
			this.$refs.improveImageConfirmDialog.show = false
			this.improveImageQuality()
		},

		cancelImproveImageQuality() {
			this.$refs.improveImageConfirmDialog.show = false
			},

		async improveImageQuality() {
			try {
				this.$emit('loading', true)
				let imageData, width, height

				if (this.mediaAsset.fields.media) {
					const fileUrl = this.mediaAsset.fields.media.de.fields.file.de.url
					imageData = await this.fetchImageAsDataURLProxy(fileUrl)
					width = this.mediaAsset.fields.media.de.fields.file.de.details.image.width
					height = this.mediaAsset.fields.media.de.fields.file.de.details.image.height
				}
				else {
					const file = this.mediaAsset.fields.file
					imageData = file.dataURL
					width = file.width
					height = file.height
				}

				await this.upscaleImageServerSide({ dataURL: imageData, width, height })
			}
			catch (error) {
				console.error('Error improving image quality:', error)
				this.$emit('error', this.$t('text.imageImprovementError'))
			}
			finally {
				this.$emit('loading', false)
			}
		},

		async fetchImageAsDataURLProxy(url) {
			const proxyUrl = '/proxy-image?url=' + encodeURIComponent(url)
			const response = await this.$httpGet(proxyUrl)
			return response.dataURL
		},

		async upscaleImageServerSide(file) {
			await this.loadingOverlay(async () => {
				try {
					const { targetWidth, targetHeight } = this.calculateDimensions(file.width, file.height)
					const data = {
						imageFile: {
						dataURL: file.dataURL,
						width: file.width,
						height: file.height,
						},
						targetWidth,
						targetHeight,
					}
	
					const response = await this.$httpPost('/clipdrop/upscale', data)
					
					this.originalImage = file
					this.improvedImage = {
						base64: response.data.dataURL,
						type: response.data.contentType,
						size: response.data.imageSize,
						width: response.data.width,
						height: response.data.height,
					}
	
					this.$refs.originalVsImprovedImageDialog.show = true
					this.$nextTick(() => this.$refs.imageComparison?.resizeHandler?.())
					this.showSuccessToast('improvementSuccess')
				}
				catch (error) {
					console.error('Error improving image quality:', error)
					if (error.response && error.response.status === 402 && error.response.statusText === 'Payment Required') {
						this.showErrorToast('clipdropApiMissingTokens')
					} else {
						this.showErrorToast('improvementError')
					}
				}
			}, 'upscaleImageServerSide')
		},

		calculateDimensions(width, height, maxWidth = 2560, maxHeight = 1440) {
			const aspect = width / height
			if (aspect > 1) {
				return { targetWidth: maxWidth, targetHeight: Math.round(maxWidth / aspect) }
			}
			else {
				return { targetWidth: Math.round(maxHeight * aspect), targetHeight: maxHeight }
			}
		},

		async acceptImprovedImage() {
			if (this.mediaAsset.fields.media) {
				this.mediaAsset.fields.imageData = this.improvedImage.base64
				this.mediaAsset.fields.media.de.fields.file.de.details.image.width = this.improvedImage.width
				this.mediaAsset.fields.media.de.fields.file.de.details.image.height = this.improvedImage.height
			}
			else {
				this.mediaAsset.fields.file.dataURL = this.improvedImage.base64
				this.mediaAsset.fields.file.width = this.improvedImage.width
				this.mediaAsset.fields.file.height = this.improvedImage.height
			}
			// Needed for the upload from URL use-case
			if (this.isURLUpload) this.$emit('new-url', this.mediaAsset.fields.file.dataURL)
			// Quick edit use-case from Media Viewer (Service Designer & Business Profile)
			if (this.imgData) {
				this.imgData = this.improvedImage.base64
			}
			this.mediaAsset.fields.isPoorQuality = { de: false }
			this.$refs.originalVsImprovedImageDialog.show = false

			this.$emit('image-improved')
		},

		rejectImprovedImage() {
			this.$refs.originalVsImprovedImageDialog.show = false
		},
	},
  created() {
    this.imgData = this.mediaAsset.fields.media?.de?.fields?.file?.de?.url ? this.mediaAsset.fields.media?.de.fields.file.de.url : ''
  },
  mounted() {
    this.mediaAsset.fields["title"] = this.mediaAsset.fields.title ? this.mediaAsset.fields.title : { de: '', en: '', fr: '', it: '', nl: '' }
    this.mediaAsset.fields["description"] = this.mediaAsset.fields.description ? this.mediaAsset.fields.description : { de: '', en: '', fr: '', it: '', nl: '' }
    this.mediaAsset.fields["altText"] = this.mediaAsset.fields.altText ? this.mediaAsset.fields.altText : { de: '', en: '', fr: '', it: '', nl: '' }
  }
}
</script>

<style scoped>
.v-label { display: block; }
.flag { position: absolute; z-index: 10; margin-top: 15px; margin-left: -10px; }
.title {
	border-radius: 4px;
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #f2f2f2;
	margin-top: 10px;
	margin-bottom: 10px;
}

.image-container {
  margin: auto;
  position: relative;
}

.grid-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  
}

.grid-item {
	cursor: auto;
}

.grid-item.active {
  background-color: rgba(255, 180, 0, 0.4);
}

</style>
