<template>
	<div>
		Fastify Test: {{ result }}<br />
		Fastify Test Restricted: {{ resultRestricted }}<br />
		Fastify Core: {{ user }}<br />
		<Api2FastifyUserForm v-model="user" v-if="user" />
	</div>
</template>

<script lang="ts">
import FastifyTestExecutive, { User } from '../../../../api2/src/modules/test/FastifyTestExecutive'
import CoreExecutive from '../../../../api2/src/modules/core/CoreExecutive'
import { metadata } from '../../../../api2/src/framework/model-util'
import Api2FastifyUserForm from './Api2FastifyUserForm.vue'

export default {
	components: { Api2FastifyUserForm },
	data: () => ({
		result: null as any as string,
		resultRestricted: null as any as string,
		user: null as any as User,
	}),
	async mounted() {
		console.log('metadata', metadata)
		new User('aaa') // make sure, treeshaking does not remove the User class

		/*
		const u = new User('name')
		console.log('u', u)

		const cc = new FastifyTestExecutive(this)
		this.result = await cc.test('3')
		this.resultRestricted = await cc.testRestricted('9c7f0079-0e8b-488f-96ab-31f4540bfb8b')
		*/

		const ex = new CoreExecutive(this)
		const user = await ex.getUser('9c7f0079-0e8b-488f-96ab-31f4540bfb8b')
		this.user = user
	},
}
</script>