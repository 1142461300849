<template>
	<div class="AvailabilityColorCalendar" style="display: flex; gap: 10px;">
		<div style="flex: 1;">
			<AddButton buttonText="timeSpan" @click="addTimespan" dataCy="add-timespan" style="padding-top: 2rem;"/>
			<table style="width: 100%;" v-if="timespans?.length">
				<tr>
					<th style="display: flex; gap: 10px; flex-wrap: wrap;">
						<div style="width: 130px;">{{ $t('text.startDate') }}</div>
						<div style="width: 130px;">{{ $t('text.endDate') }}</div>
					</th>
					<th></th>
				</tr>
				<tr class="timespan" v-for="timespan, ts of timespans" :key="ts"
					:class="{
						selected: selectedTimespan == timespan,
					}"
					@mousedown="selectTimespan(timespan)"
				>
					<!-- TODO: how to cleverly "connect" the date inputs with the calendar?
						OK cursor in field -> highlight the date range in the calendar
						- click one of the selected dates -> add it to the exceptions
						- click one of the unselected dates -> add it to the available list
						- click one of the exceptions -> remove it from the exceptions
						- click one of the weekday headers -> add/remove all of that timespan.weekdays
						OK focus on start or end field -> mark that date specially on the calendar + navigate calendar there
						- how to set start/end dates? when focus is in the start / end field? day menu + set start / set end?
						OK on focus -> bring the relevant date into view
						OK on select -> bring the relevant start date into view
						OK on change of date (start / end) -> jump there in calendar
						- mark start / end input red on invalid input
						- marking a date -> select the corresponding timespan
					-->
					<td style="display: flex; gap: 10px; flex-wrap: wrap;">
						<input type="date" style="width: 130px;" v-model="timespan.start" :min="min" :max="max" @focus="timespanFocus = 'start'; go(timespan.start); selectedDay = null" @blur="timespanFocus = null" @keyup="go(timespan.start)" />
						<input type="date" style="width: 130px;" v-model="timespan.end" :min="min" :max="max" @focus="timespanFocus = 'end'; go(timespan.end); selectedDay = null" @blur="timespanFocus = null" @keyup="go(timespan.end)" />
						<slot name="timeslotDetail" v-bind:timespan="timespan" v-bind:weekdays="weekdays"></slot>
						<!-- TODO: use detail-slot in a wrapper comp once this is finished -->
						<mys-tags style="flex: 1 0; min-width: 200px;"
							v-model="timespan.weekdays"
							:options="weekdays"
							:itemLabel="(wd) => typeof wd == 'string' ? $t('text.' + wd)?.substring?.(0, 2)?.toUpperCase?.() : wd"
							emptyLabel="allWeekdaysClosed"
						/>
					</td>
					<td><v-icon @click="timespans.splice(ts, 1)">mdi-delete</v-icon></td>
				</tr>
			</table>
		</div>
		<div class="color-calendar">
			<v-btn variant="text" @click="showCalendar = !showCalendar" color="#088ec0" class="button-right">
				{{ showCalendar ? $t('text.hideCalendar') : $t('text.showCalendar') }}
			</v-btn>
			<div v-show="showCalendar">
				<ColorCalendar
					ref="calendar"
					:calendar="calendar"
					:dates="dates"
					:profiles="profiles"
					:focusStart="focusStart"
					:focusEnd="focusEnd"
					@focusRangeChanged="focusStart = $event.focusStart; focusEnd = $event.focusEnd"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import ColorCalendar from './ColorCalendar.vue'
import AddButton from '../../views/applications/packageDesigner/AddButton.vue'

export default {
	components: { ColorCalendar, AddButton },
	props: {
		modelValue: Array,
		min: String,
		max: String,
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		timespans: [],
		selectedTimespan: null,
		timespanFocus: null, // 'start' | 'end' | 'weekdays' | null
// TODO: this concern is distributed - actually we only want to deal with months and the ColorCalendar
//       prescribes that, but we also have to implement it here
		focusStart: moment().startOf('month'),
		// TODO: can we get rid of the focusEnd?
		focusEnd: moment().endOf('month'),
		profiles: [
			{ id: 1, color: '#e8f5dc', name: 'available' },
			{ id: 2, color: '#f8e1e1', name: 'unavailable' },
		],
		showCalendar: window.innerWidth > 1000,
	}),
	computed: {
		// TODO: move some common concerns of these functions into exports of ColorCalendar
		dates() {
			// find min and max date
			let minDate = this.min
			let maxDate = this.max
			this.timespans.forEach(ts => {
				if (!ts.start || !ts.end) return
				const from = ts.start.substring(0, 10)
				if (!minDate || from < minDate) minDate = from
				const to = ts.end.substring(0, 10)
				if (!maxDate || to > maxDate) maxDate = to
			})
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day').startOf('month')
			const toDate = moment(maxDate).endOf('day').endOf('month')
			const fromMonday = fromDate.clone().startOf('isoWeek')
			const toSunday = toDate.clone().endOf('isoWeek')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = todayDate.startOf('month').startOf('isoWeek').diff(fromDate, 'days')
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			if (!this.timespans) return []
/*
			const tsLookup = {}
			this.availabilityDays.forEach(d => {
				// TODO: timezone?
				const from = moment(d.start)
				const date = d.start.substr(0, 10) //from.format('YYYY-MM-DD')
				if (!tsLookup[date]) tsLookup[date] = {}
				const time = d.start.substr(11, 5) //from.format('HH:mm')

				tsLookup[date][time] = { ...d, time }
			})
*/
			const enabled = this.profiles[0]
			const disabled = this.profiles[1]
			const days = []
			let d = -1
			for (let currentDay = this.focusStart.clone().startOf('isoWeek');
				currentDay <= this.focusEnd.clone().endOf('isoWeek');
				currentDay = currentDay.add(1, 'days')
			) {
				d++
				const day: { day: any, [ i: string ]: string | boolean } = { day: currentDay.clone()}
				day.weekday = day.day.format('dddd').toLowerCase()
				day.date = day.day.format('YYYY-MM-DD')
				day.dateObject = day.day.toDate()
				day.year = day.day.format('YYYY')
				day.inRange = day.date >= this.dates.minDate && day.date <= this.dates.maxDate
				day.open = false // TODO?
				day.selected = this.isSelected(day)
				day.anyTimespanMatches = this.anyTimespanMatches(day)
				day.fieldFocus = this.isFieldFocussed(day)
				day.profile = day.anyTimespanMatches ? disabled : enabled
				if (this.min && day.date < this.min) day.profile = disabled
				if (this.max && day.date > this.max) day.profile = disabled
//				const timespan = Object.values(tsLookup[date] ?? {})
				days.push(day)
			}
			return days
		},
	},
	watch: {
		timespans: {
			handler(n) {
				this.$emit('update:modelValue', n)
			},
			deep: true,
		},
	},
	methods: {
		go(day) {
			this.$refs.calendar.go(day)
		},
		timespanMatches(timespan, day) {
			if (!timespan) return false
			return day.date >= timespan.start
				&& day.date <= timespan.end
				&& (!timespan.weekdays.includes(day.weekday) || timespan.weekdays == '')
		},
		isSelected(day) {
			return this.timespanMatches(this.selectedTimespan, day)
		},
		isFieldFocussed(day) {
			if (!this.selectedTimespan) return false
			if (this.timespanFocus == 'start' && this.selectedTimespan.start == day.date) return true
			if (this.timespanFocus == 'end' && this.selectedTimespan.end == day.date) return true
			return false
		},
		anyTimespanMatches(day) {
			return this.timespans.some(ts => this.timespanMatches(ts, day))
		},
		selectTimespan(timespan) {
			if (this.selectedTimespan == timespan) return
			this.selectedTimespan = timespan
			this.go(timespan.start)
			this.timespanFocus = null
		},
		addTimespan() {
			this.timespans.push({ weekdays: [] })
		},
	},
	mounted() {
		this.timespans = this.modelValue ?? []
		this.go('today')

		if (this.timespans?.length) this.showCalendar = true
	},
}
</script>

<style scoped>
.AvailabilityColorCalendar { --sel: lightskyblue; --focus: #295dc7; }

.timespan {}
.timespan.selected { outline: 2px solid var(--sel); border-radius: 5px; }
table th { border-bottom: 1px solid #ddd; }
table th,
table td { text-align: left; padding: 10px; }
table input { border: 1px solid #ddd; border-radius: 5px; padding: 3px 6px; }
.color-calendar { display: flex; flex-direction: column; gap: 10px;}
.color-calendar .button-right { margin-left: auto; }
</style>