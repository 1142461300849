<template>
	<Disclosure :title="$t('text.businessHours')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="businessInfo" ref="SECTION_businessInfo" :expanded="expanded">
		<p class="subTitle" v-if="app === 'BusinessProfile'" v-html="$t('text.businessProfileInfoDesc')"/>
		<p class="subTitle" v-if="app === 'ServiceDesigner'" v-html="$t('text.serviceDesignerInfoDesc')"/>
		<!-- Business Hours -->
		<div class="field left-border">
			<div v-if="app === 'BusinessProfile'">
				<v-label>{{$t('text.averageDurationOfStayInMinutes')}}</v-label>
				<v-text-field
					variant="outlined" density="compact" hide-details v-mask="'####'"
					v-model="averageDurationOfStayInMinutesLocal"
					style="width:15%;"
					@update:modelValue="$emit('average-duration-changed', averageDurationOfStayInMinutesLocal)"/>
				<p class="helpText" v-html="$t('text.averageDurationOfStayInMinutesDesc')" style="margin-left:0px"/>
			</div>
			<br/>
			<p class="subTitle" v-html="$t('text.businessHours')"/>
			<p class="helpText" v-if="!showCopyOption" v-html="$t('text.businessHoursHelp')"/>
			<p class="helpText" v-if="showCopyOption" v-html="$t('text.businessHoursServiceHelp')"/>

			<div v-if="showCopyOption">
				<v-radio-group v-model="copy" col>
					<v-radio style="height:48px" :label="$t('text.copyBusinessHours')" :value="true" ></v-radio>
					<hr/>
					<v-radio style="height:48px" :label="$t('text.newBusinessHours')" :value="false"></v-radio>
				</v-radio-group>
			</div>

			<div style="background: #eee; border-radius: 5px; padding: 10px; padding-right: 30px; margin: 10px 0; display: flex; gap: 10px;">
				<div>
					<p class="subTitle" v-html="businessHoursText"/>
					<p  v-html="businessHoursHelpText"/>
				</div>
				<mys-switch
					v-model="haveBusinessHours"
					@update:modelValue="toggleHaveBusinessHours($event)" 
				/>
			</div>

			<fieldset :disabled="copy" style="border: 0;" :style="{ opacity: copy ? '0.4' : 'initial' }">
				<div v-for="(businessHours, businessHoursIndex) in formattedBusinessHours" :key="businessHours.sys.id">
					<div style="padding: 10px; margin-bottom: 15px;" :style="errorIndexes.length > 0 && errorIndexes.findIndex(x => x === businessHoursIndex) > -1 ? 'border:1px solid red; border-radius: 5px;' : 'border:1px solid #9e9e9e; border-radius: 5px;'">
							<v-row style="padding-left:10px;padding-right:10px">
								<v-col style="padding-left:0px !important">
									<p class="helpText" style="padding-bottom:12px;margin-bottom:12px" v-html="$t('text.validFrom')"/>
									<v-btn class="gradientButton" elevation="0" style="width:100%;justify-content: left !important"
									@click="editBusinessHours(businessHoursIndex, false, 0)">{{ formatDate(businessHours.fields.validFromDate.de) }} <v-spacer/><v-icon>mdi-menu-down</v-icon></v-btn>
								</v-col>
								<v-col>
									<p class="helpText" style="padding-bottom:12px;margin-bottom:12px" v-html="$t('text.validUntil')"/>
									<v-btn class="gradientButton" elevation="0" style="width:100%;justify-content: left !important;"
									@click="editBusinessHours(businessHoursIndex, false, 0)">{{ formatDate(businessHours.fields.validToDate.de) }} <v-spacer/><v-icon>mdi-menu-down</v-icon></v-btn>
								</v-col>
							</v-row>

							<hr class="my-3" />

							<!-- Display View -->
							<table style="width: 100%;">
								<tr v-for="(displayItem, di) in businessHours.displayArray" :key="displayItem.label">
									<td style="width: 50%;">
										<v-chip style="float: left; margin-right: 5px;" flat class="chip" @click="editBusinessHours(businessHoursIndex, false, di)">{{ displayItem.daysLabel }}</v-chip>
									</td>
									<td style="width: 50%;">
										<span v-for="timeLabel in displayItem.timeLabels" :key="timeLabel">
											<v-chip style="float: left; margin-right: 5px;" flat class="chip" @click="editBusinessHours(businessHoursIndex, false, di)">{{timeLabel}}</v-chip>
										</span>
									</td>
								</tr>
							</table>

							<!-- Display View for Exceptions -->
							<div v-if="businessHours.fields.exceptions && businessHours.fields.exceptions.de.length > 0">
								<hr />
								<div v-for="(exception, exceptionIndex) in businessHours.fields.exceptions.de" :key="exceptionIndex">
									<v-row style="padding:10px" no-gutters>
										<v-col :style="'max-width:'+colCount*50+'px;margin-right:10px;padding-right:10px'">
											<v-row>
												<v-chip flat v-for="(date, index) in exception.dates" :key="index" class="chip mr-2 my-1" @click="editException(businessHoursIndex, exceptionIndex)">
													{{ date }}
												</v-chip>
											</v-row>
										</v-col>
										<v-col>
											<v-row>
												<span v-for="(time,timeIndex) in exception.times" :key="timeIndex">
													<v-chip v-if="time.openTime !== '' && time.closeTime !== ''" flat class="chip mr-2 my-1" @click="editException(businessHoursIndex, exceptionIndex)">{{time.openTime}} - {{ time.closeTime }}</v-chip>
													<v-chip v-else flat class="chip mr-2 my-1" @click="editException(businessHoursIndex, exceptionIndex)">{{$t('text.closed')}}</v-chip>
												</span>
												<v-spacer/>
											</v-row>
										</v-col>
									</v-row>
									<hr v-if="exceptionIndex !== businessHours.fields.exceptions.de.length-1"/>
								</div>
							</div>

							<hr class="my-3" />

							<v-row style="padding:10px">
								<v-btn class="gradientButton mr-4 my-1" elevation="0"  @click="editBusinessHours(businessHoursIndex, false, 0)"><v-icon>mdi-pencil</v-icon>{{$t('text.editBusinessHours')}}</v-btn>
								<v-btn class="gradientButton mr-4 my-1" elevation="0"  @click="addException(businessHoursIndex)"><v-icon>mdi-plus</v-icon>{{$t('text.addException')}}</v-btn>
								<v-btn class="gradientButton mr-4 my-1" elevation="0"  v-if="businessHoursIndex > 0" @click="removeBusinessHours(businessHoursIndex, false)"><v-icon color="#fb3640">mdi-delete</v-icon> {{$t('text.deleteTimeSpan')}}</v-btn>
							</v-row>
					</div>
					<v-btn v-if="businessHoursIndex === formattedBusinessHours.length-1" class="gradientButton" elevation="0" style="margin-right:10px" @click="addBusinessHours(false)"><v-icon>mdi-plus</v-icon>{{$t('text.addAnotherTimeFrame')}}</v-btn>
				</div>
			</fieldset>
		</div>

		<!-- Add Opening Option -->
		<Dialog ref="openingTimesDialog"
			:showClose="false"
			:confirmLabel="$t('text.confirmSelection')"
			:cancelLabel="$t('text.discardChanges')"
			:confirm-handler="confirmBusinessHours"
			:cancelHandler="cancelOpeningTimes"
			:title="$t('text.businessHours')"
			:width="'800px'">
			<template #content>
				<OpeningTimes ref="openingTimesDetail"
					:businessHours="selectedBusinessHours"
					:selectedTime="selectedTime"
					:error="businessHoursError"
					@business-hours-changed="(val) => selectedBusinessHours = val"
				/>
			</template>
		</Dialog>

		<!-- Upsert Exception -->
		<Dialog ref="exceptionDialog"
			:showClose="false"
			:confirmLabel="$t('text.confirmSelection')"
			:cancelLabel="$t('text.discardChanges')"
			:deleteLabel="$t('text.delete')"
			:confirm-handler="confirmException"
			:cancelHandler="cancelException"
			:deleteHandler="deleteException"
			:title="$t('text.exceptions')"
			:width="'800px'">
			<template #content>
				<BusinessHourExceptions ref="exceptionDetail"
					:selectedBusinessHours="selectedBusinessHours"
					:selectedException="selectedException"
					:error="exceptionError"
					@exception-changed="(val) => selectedException = val"
				/>
			</template>
		</Dialog>
	</Disclosure>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import OpeningTimes from './OpeningTimes.vue'
import BusinessHourExceptions from './BusinessHourExceptions.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import { DATE_FORMATS } from '@/constants'

export default {
	name: 'BusinessHours',
	mixins: [ Common ],
	components: { Dialog, OpeningTimes, Disclosure, BusinessHourExceptions },
	props: {
		averageDurationOfStayInMinutes: Number,
		showCopyOption: Boolean,
		copyBusinessHours: Boolean,
		businessHours: Array,
		app: String,
		updateModel: Boolean,
		expanded: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		copy: false,
		selectedWeekdays: [],
		selectedBusinessHours: {},
		formattedBusinessHours: [],
		selectedBusinessHoursIndex: 0,
		weekdays: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],

		originalBusinessHours: {},

		sectionMessage: {
			error: false,
			message: ''
		},

		closedDays: false,
		maxDayCount: 7,
		businessHoursError: {},
		colCount: 0,
		hover: [],
		errorIndexes: [],
		selectedTime: 0,
		haveBusinessHours: true,
		addBusinessHoursClicked: false,
		averageDurationOfStayInMinutesLocal: 0,
		businessHoursLocal: [],
		initBusinessHours: [],
		initData: {},
		selectedExceptionIndex: 0,
		selectedException: {},
		originalException: {},
		addExceptionsClicked: false,
		exceptionError: {},
	}),
	computed: {
		businessHoursText() {
			if (this.app === "BusinessProfile") {
				return this.$t("text.haveBusinessHoursSP")
			} else if (this.app === "ServiceDetail") {
				return this.$t("text.haveBusinessHoursService")
			}
			return ""
		},
		businessHoursHelpText() {
			if (this.app === "BusinessProfile") {
				return this.$t("text.haveBusinessHoursSPHelp")
			} else if (this.app === "ServiceDetail") {
				return this.$t("text.haveBusinessHoursServiceHelp")
			}
			return ""
		}
	},
	watch: {
		updateModel() {
			this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
			this.initBusinessHours = JSON.parse(JSON.stringify(this.businessHours))
			this.setInitData()
		},
		selectedLocale() {
			this.formattedBusinessHours = []
			this.formatBusinessHours()
		},
		copy() {
			if (this.copy === true) {
				this.haveBusinessHours = false
			}
			if (this.haveBusinessHours === true) {
				if (this.copy) {
					this.$emit('copy-business-hours')
				} else {
					this.$emit('new-business-hours')
				}
			} else  {
				this.initData.businessHours = []
				this.businessHoursLocal = []
				this.formattedBusinessHours = []
			}
		},
		businessHours() {
			this.formatBusinessHours()
			this.haveBusinessHours = this.businessHours?.length > 0 ? true : false
			this.$forceUpdate()
		},
	},
	methods: {
		setInitData() {
			this.initData = {
				businessHours: this.initBusinessHours,
				averageDurationOfStay: this.averageDurationOfStayInMinutes
			}

		},
		sendData() {
			let businessHoursData = JSON.parse(JSON.stringify(this.businessHoursLocal))
			if (businessHoursData?.length) {
				for (let businessHours of businessHoursData) {
					delete businessHours.dayCount
					delete businessHours.displayArray
				}
			}

			let data = {
				businessHours: {de: businessHoursData}
			}

			data.changed = !isEqual(businessHoursData, this.initData.businessHours) || this.averageDurationOfStayInMinutesLocal !== this.initData.averageDurationOfStay
			
			return data
		},
		validateAllFields() {
			let isValid = true
			this.resetSectionError(this.sectionMessage)

			if (!this.validateBusinessHours(false)) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.businessHoursOverlapError'))
			}
			
			return isValid
		},
		toggleHaveBusinessHours(event) {
			if (event &&  event === true) {
				if (!this.businessHoursLocal || this.businessHoursLocal.length === 0) {
					this.businessHoursLocal.push(this.getNewBusinessHours(false))
					this.formatBusinessHours()
				}
			} else {
				this.copy = false
				this.businessHoursLocal = []
				this.formattedBusinessHours = []
			}
			this.$emit("toggle-business-hours", event)
		},
		toggleHover(index, active) {
			this.hover[index] = active
			this.$forceUpdate()
		},
		formatDate(date) {
			if (date !== "") {
				return moment(String(date.split("T")[0])).format(DATE_FORMATS.DATE)
			} else {
				return date
			}
		},
		formatBusinessHours() {
			this.formattedBusinessHours = []

			if (this.businessHoursLocal?.length > 0) {
				let displayArrayKey = 0

				for (const businessHoursEntry of this.businessHoursLocal) {
					if (businessHoursEntry.fields) {
						let displayMap = new Map()
						let displayArray = []
						let dayCount = 0
						
						for  (const weekday of this.weekdays) {
							const weekdayTimes = businessHoursEntry.fields.businessTimes.de[weekday]
							let mapKey = ''

							if (weekdayTimes.times?.length > 0) {
								for (const times of weekdayTimes.times) {
									mapKey+=times.openTime+'-'+times.closeTime+'|'
								}
								mapKey = mapKey.substring(0,mapKey.length-1)

								if (!displayMap.get(mapKey)) {
									displayMap.set(mapKey, [weekday])
								} else {
									displayMap.get(mapKey).push(weekday)
								}	
							}
						}

						if (displayMap.size > 0) {
							for (let [key, value] of displayMap) {
								displayArrayKey++

								let daysLabel = ''
								let days = []
								let timeLabels = []
								let times = []
								let text = ''

								if (key !== '-') {
									for (const element of value) {
										dayCount++
										days.push(element)
										text = 'text.'+element
										daysLabel += (this.$t(text)).substring(0,3) + ' • '
									}

									daysLabel = daysLabel.substring(0, daysLabel.length-2)
									timeLabels = key.split("|")

									if (timeLabels?.length > 0) {
										for (const timeLabel of timeLabels) {
											times.push({openTime:timeLabel.split("-")[0],closeTime:timeLabel.split("-")[1]})
										}
									}
									
									displayArray.push({daysLabel: daysLabel, days:days, timeLabels:timeLabels, times:times, closed:false, key:displayArrayKey})
									this.colCount = this.colCount < days.length ? days.length+1 : this.colCount
								}
							}

							if (displayMap.get("-")) {
								let daysLabel = ''
								let days = []
								let text = ''
								for (const element of displayMap.get("-")) {
									dayCount++
									days.push(element)
									text = 'text.'+element
									daysLabel += (this.$t(text)).substring(0,3) + ' • '
								}

								daysLabel = daysLabel.substring(0, daysLabel.length-2)

								displayArray.push({daysLabel: daysLabel, days:days, timeLabels:[this.$t('text.closed')], times: days.length < 7 ? [] : [{openTime:'',closeTime:''}], closed: true, key: displayArrayKey})
								this.colCount = this.colCount < days.length ? days.length+1 : this.colCount
							}
						}
						
						businessHoursEntry["displayArray"] = displayArray
						businessHoursEntry["dayCount"] = dayCount
						this.formattedBusinessHours.push(businessHoursEntry)
					}
				}
			}
		},
		addBusinessHours() {
			if (!this.businessHoursLocal) {
				this.businessHoursLocal = []
			}
			this.businessHoursLocal.push(this.getNewBusinessHours())
			this.formatBusinessHours()
			this.$forceUpdate()
			this.editBusinessHours(this.businessHoursLocal.length-1, true, 0)

			this.addBusinessHoursClicked = true
		},
		removeBusinessHours(index, isException) {
			this.formattedBusinessHours.splice(index, 1)
			this.businessHoursLocal.splice(index, 1)
		},
		editBusinessHours(businessHoursIndex, isException, itemIndex) {
			this.addBusinessHoursClicked = false
			this.businessHoursError = {}
			this.selectedBusinessHoursIndex = businessHoursIndex
			this.selectedTime = itemIndex
			
			this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedBusinessHours[businessHoursIndex]))
			this.originalBusinessHours = JSON.parse(JSON.stringify(this.selectedBusinessHours))

			this.$refs.openingTimesDialog.show = true
		},
		addException(businessHoursIndex) {
			this.selectedBusinessHours = this.formattedBusinessHours[businessHoursIndex]
			if (!this.selectedBusinessHours.fields.exceptions) {
				this.selectedBusinessHours.fields.exceptions = { de: [] }
			}
			
			this.selectedBusinessHours.fields.exceptions.de.push(this.getNewExceptions())
			this.businessHoursLocal[businessHoursIndex] = this.selectedBusinessHours

			this.formatBusinessHours()
			this.$forceUpdate()

			this.editException(businessHoursIndex, this.selectedBusinessHours.fields.exceptions.de.length-1)

			this.addExceptionsClicked = true
		},
		editException(businessHoursIndex, itemIndex) {
			this.addExceptionsClicked = false
			this.exceptionError = { message: '' }

			this.selectedBusinessHours = this.formattedBusinessHours[businessHoursIndex]
			this.selectedExceptionIndex = itemIndex
			this.selectedBusinessHoursIndex = businessHoursIndex

			this.selectedException = JSON.parse(JSON.stringify(this.selectedBusinessHours.fields.exceptions.de[itemIndex]))
			this.originalException = JSON.parse(JSON.stringify(this.selectedException))

			this.$refs.exceptionDialog.show = true
		},
		getNewBusinessHours() {
			const counter = Math.random()

			let today = moment(new Date()).format('YYYY-MM-DD')
			let validToDate = moment(today).add(1, 'month').format('YYYY-MM-DD')

			return {
				sys: { id: 'id_bh_' + counter },
				fields: {
					validFromDate: { de: today },
					validToDate: { de: validToDate },
					isException: {de: false},
					businessTimes: {
						de: {
							monday:    { times: [{ openTime: '', closeTime: '' }] },
							tuesday:   { times: [{ openTime: '', closeTime: '' }] },
							wednesday: { times: [{ openTime: '', closeTime: '' }] },
							thursday:  { times: [{ openTime: '', closeTime: '' }] },
							friday:    { times: [{ openTime: '', closeTime: '' }] },
							saturday:  { times: [{ openTime: '', closeTime: '' }] },
							sunday:    { times: [{ openTime: '', closeTime: '' }] },
							holidays:  { times: [{ openTime: '', closeTime: '' }] },
						},
					},
				},
			}	
		},
		getNewExceptions() {
			return {
				dates: [],
				closed: true,
				times: [{ openTime: '', closeTime: '' }],
			}
		},
		confirmException() {
			this.exceptionError = { message: '' }

			let isValidException = this.validateException()

			if (isValidException.message.length > 0) {
				this.exceptionError.message = isValidException.message
				// scroll to top of dialog for better UX
				const dialogContentEl = this.$refs.exceptionDialog.$el.querySelector('.scrollable');
      			dialogContentEl.scrollTop = 0;
				return
			}
			
			this.selectedBusinessHours.fields.exceptions.de[this.selectedExceptionIndex] = JSON.parse(JSON.stringify(this.selectedException))
			this.formatBusinessHours()
			this.$forceUpdate()
			this.$refs.exceptionDialog.show = false
		},
		cancelException() {
			this.exceptionError = { message: '' }

			this.selectedException = JSON.parse(JSON.stringify(this.originalException))

			if (this.addExceptionsClicked) {
				this.selectedBusinessHours.fields.exceptions.de.splice(this.selectedExceptionIndex, 1)
			}
			else {
				this.selectedBusinessHours.fields.exceptions.de[this.selectedExceptionIndex] = JSON.parse(JSON.stringify(this.originalException))
			}

			this.$forceUpdate()

			this.$refs.exceptionDialog.show = false
		},
		deleteException() {
			this.selectedBusinessHours.fields.exceptions.de.splice(this.selectedExceptionIndex, 1)
			this.formatBusinessHours()
			this.$forceUpdate()
			this.$refs.exceptionDialog.show = false
		},
		confirmBusinessHours() {
			this.confirmOpeningTimes()
		},
		confirmOpeningTimes() {
			this.businessHoursError = {message:''}
			this.businessHoursLocal[this.selectedBusinessHoursIndex] = JSON.parse(JSON.stringify(this.$refs.openingTimesDetail.businessHoursLocal))

			//Might remove this "else" if overlapping exceptions need to be validated
			let isValidBusinessHours = this.validateBusinessHours(this.$refs.openingTimesDetail.businessHoursLocal.fields.isException?.de)

			const isValidSelectedBusinessHours = this.validateSelectedBusinessHours()
	
			if (!isValidBusinessHours) {
				this.businessHoursError = {message: this.$t('text.businessHoursOverlapError')}
			}

			if (!isValidSelectedBusinessHours) {
				this.businessHoursError = {message: this.$t('text.selectedBusinessHoursError')}
			}
			
			if (this.businessHoursError.message.length > 0) return

			let openingTimes = this.$refs.openingTimesDetail.businessHoursLocal.displayArray
			
			this.businessHoursError = this.validateOpeningDates(this.$refs.openingTimesDetail.businessHoursLocal)
			if (this.businessHoursError.message.length > 0) return

			this.businessHoursError = this.validateOpeningTimes(openingTimes)
			if (this.businessHoursError.message.length > 0) return
		
			let openDays  = []
			//Format into business hours model
			this.$refs.openingTimesDialog.show = false
			let businessHours
			
			businessHours = this.businessHoursLocal[this.selectedBusinessHoursIndex]
			
			for (const openingTime of openingTimes) {
				for (const day of openingTime.days) {
					openDays.push(day)
					let validTimes = []
					for (let time of openingTime.times) {
						if (time.openTime !== '' || time.closeTime !== '') { validTimes.push(time) }
					}

					if (validTimes.length > 0) {
						businessHours.fields.businessTimes.de[day].times = validTimes
					} else {
						businessHours.fields.businessTimes.de[day].times = [{openTime:'',closeTime:''}]
					}	
				}
			}

			if (openDays.length < 8) {
				//Set missing days to closed
				for (const weekday of this.weekdays) {
					if (openDays.findIndex(x => x === weekday) === -1) {
						businessHours.fields.businessTimes.de[weekday].times = [{openTime:'',closeTime:''}]
					}
				}
			}

			this.formatBusinessHours()
		},
		cancelOpeningTimes() {
			this.businessHoursError = {}

			if (this.formattedBusinessHours[this.selectedBusinessHoursIndex].fields.isException.de === true) {
				this.formattedBusinessHours[this.selectedBusinessHoursIndex].fields.isException.de = false
			}

			this.formatBusinessHours()
			this.selectedBusinessHours = JSON.parse(JSON.stringify(this.formattedBusinessHours[this.selectedBusinessHoursIndex]))

			// when Add another timeframe clicked
			if (this.selectedBusinessHoursIndex > 0 && this.addBusinessHoursClicked) {
				this.removeBusinessHours(this.selectedBusinessHoursIndex, false)
			}
			// when edit business hours clicked
			else if (!this.addBusinessHoursClicked) {
				// overwrite the model with the original model
				if (this.originalBusinessHours) {
					this.selectedBusinessHours = JSON.parse(JSON.stringify(this.originalBusinessHours))
					let index = this.businessHoursLocal.findIndex(x => x.sys.id === this.originalBusinessHours.sys.id)

					this.businessHoursLocal[index] = JSON.parse(JSON.stringify(this.originalBusinessHours))
					this.formattedBusinessHours[index] = JSON.parse(JSON.stringify(this.originalBusinessHours))
		
					this.$forceUpdate()
				}
			}
		},
		validateOpeningDates(businessHours) {
			let error = {message:'',displayIndex:-1}

			if (businessHours.fields.validFromDate.de > businessHours.fields.validToDate.de) {
				error.message = this.$t('text.fromDateAfterToDateError')
			}
			return error
		},
		validateOpeningTimes(openingTimes) {
			let error = {message:'',displayIndex:-1}

			if (openingTimes?.length > 0) {
				for (let i=0; i < openingTimes.length; i++) {
					const openingTime = openingTimes[i]

					if (openingTime.days.length === 0) {
						//Times specified but no days selected
						for (let time of openingTime.times) {
							if (time.openTime !== '' && time.closeTime !== '') {
								error.message = this.$t('text.missingDaySelection')
								error.displayIndex = i
								break
							}
						}
					} else {
						if (openingTime.times?.length > 0 && !openingTime.closed) {
							//Validate time inputs
							for (const time of openingTime.times) {
								if (time.openTime.length > 0 && time.closeTime.length > 0) {
									time.openTime = time.openTime.substring(0,5)
									time.closeTime = time.closeTime.substring(0,5)
									if (!this.validateHhMm(time.openTime) || !this.validateHhMm(time.closeTime)) {
										error.message = this.$t('text.invalidTime')
										
									}
								}
							}
						}
					}
					
					if (openingTime.times?.length > 0 && !openingTime.closed) {
						for (const time of openingTime.times) {
							time["errors"] = ""
							if (time.openTime.length > 0 && time.closeTime.length > 0) {
								if (time.openTime > time.closeTime) {
									error.message = this.$t('text.invalidTime')
									time["errors"] = this.$t('text.invalidTime')
								}
							}
						}
					}
				
				}
			}
			return error
		},
		validateHhMm(input) {
			var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(input);
			return isValid;
		},
		validateSelectedBusinessHours() {
			// MYS-3891: Validation case when days are selected but no times are selected or the open/closed checkbox is not checked
			const hasOnlyOneOpeningTimeBlock = this.selectedBusinessHours.displayArray.length === 1
			const firstOpeningTimesBlock = this.selectedBusinessHours.displayArray[0]
			const hasSelectedOneDay = firstOpeningTimesBlock.days.length < 7
			const hasNoOpenTime = firstOpeningTimesBlock.times[0].openTime === ""
			const hasNoCloseTime = firstOpeningTimesBlock.times[0].closeTime === ""

			if (hasOnlyOneOpeningTimeBlock && hasSelectedOneDay && hasNoOpenTime && hasNoCloseTime) {
				this.errorIndexes.push(this.selectedBusinessHours.key)
				return false
			}

			// MYS-3891: Validation case when the the open/closed checkbox is checked but no times are selected - here we need to cater for the case when the user has selected multiple opening time blocks
			for (const openingHour of this.selectedBusinessHours.displayArray) {
				if (!openingHour.closed) {
					for (const time of openingHour.times) {
						if (time.openTime.length === 0 || time.closeTime.length === 0) {
							this.errorIndexes.push(this.selectedBusinessHours.key)
							return false
						}
					}
				}
			}

			return true
		},
		validateBusinessHours() {
			let businessHours = this.businessHoursLocal

			if (businessHours?.length > 0) {
				this.errorIndexes = []
				for (let i=0; i < businessHours.length; i++) {
					const businessHoursOne = businessHours[i]
					for (let j=i; j < businessHours.length; j++) {
						const businessHoursTwo = businessHours[j]
						if (businessHoursOne != businessHoursTwo) {
							const invalidFromDateOne = moment(businessHoursOne.fields.validFromDate.de.split("T")[0]).isBetween(businessHoursTwo.fields.validFromDate.de.split("T")[0], businessHoursTwo.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidToDateOne = moment(businessHoursOne.fields.validToDate.de.split("T")[0]).isBetween(businessHoursTwo.fields.validFromDate.de.split("T")[0], businessHoursTwo.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidFromDateTwo = moment(businessHoursTwo.fields.validFromDate.de.split("T")[0]).isBetween(businessHoursOne.fields.validFromDate.de.split("T")[0], businessHoursOne.fields.validToDate.de.split("T")[0], undefined, '[]');
							const invalidToDateTwo = moment(businessHoursTwo.fields.validToDate.de.split("T")[0]).isBetween(businessHoursOne.fields.validFromDate.de.split("T")[0], businessHoursOne.fields.validToDate.de.split("T")[0], undefined, '[]');
							
							if (invalidFromDateOne || invalidToDateOne || invalidFromDateTwo || invalidToDateTwo) {
								this.errorIndexes.push(j)
								this.errorIndexes.push(i)
							}
						}
					}	
				}
				
				if (this.errorIndexes.length > 0) {
					return false
				}
			}
			return true
		},
		validateException() {
			/* the exception should not be valid if: 
			1. this.selectedException.dates is empty
			2. this.selectedException.times is empty when this.selectedException.closed is false
			3. there are overlapping times in this.selectedException.times (object looks like this: {openTime: "08:00", closeTime: "12:00"})
			4. the dates in this.selectedException overlap with the dates for other exceptions
			5. If for a selected business hours entry, all days are anyways closed, the user cannot add an exception for specific dates in which the business is closed (as it is anyways closed every day in that period). In this case only exceptions with a valid time-range are accepted.
			*/
			let error = {message:'',displayIndex:-1}
			let exception = this.selectedException

			if (exception.dates.length === 0) {
				error.message = this.$t('text.missingDateSelection')
			} else {
				if (exception.times?.length > 0 && !exception.closed) {
					//Validate time inputs
					for (const time of exception.times) {
						if (time.openTime === '' && time.closeTime === '') {
							error.message = this.$t('text.missingTimeSelection')
						}
						else if (time.openTime.length > 0 && time.closeTime.length > 0) {
							time.openTime = time.openTime.substring(0,5)
							time.closeTime = time.closeTime.substring(0,5)
							if (!this.validateHhMm(time.openTime) || !this.validateHhMm(time.closeTime)) {
								error.message = this.$t('text.invalidTime')
							}
							else if (time.openTime > time.closeTime) {
								error.message = this.$t('text.invalidTime')
							}
						}
						else if (time.openTime.length > 0 && time.closeTime.length === 0) {
							error.message = this.$t('text.missingTimeSelection')
						}
						else if (time.openTime.length === 0 && time.closeTime.length > 0) {
							error.message = this.$t('text.missingTimeSelection')
						}
					}
				}
				// if all the week days in the current business hours are closed, then the exception for a closed day should not be valid
				if (exception.closed) {
					let daysClosed = 0
					
					for (const [key, day] of Object.entries(this.selectedBusinessHours.fields.businessTimes.de)) {
						if (day.times[0].openTime === '' && day.times[0].closeTime === '') {
							daysClosed++
						}
					}
					
					if (daysClosed >= 7) {
						error.message = this.$t('text.allDaysClosed')
					}
				}
			} 

			return error
		}
	},
	created() {
		this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
		this.initBusinessHours = JSON.parse(JSON.stringify(this.businessHours))
		this.setInitData()

		this.copy = this.copyBusinessHours
		this.haveBusinessHours = this.businessHours?.length > 0 ? true : false
		
		if (this.businessHoursLocal?.length >  0) {
			for (let businessHours of this.businessHoursLocal) {
				if (businessHours.fields.validFromDate.de === '' && businessHours.fields.validToDate.de === '') {
					businessHours.fields.validFromDate.de = moment(new Date()).format('YYYY-MM-DD')
					businessHours.fields.validToDate.de = moment(new Date()).add(1, 'year').format('YYYY-MM-DD')
				}
			}
		}
		
		this.formatBusinessHours()

		this.averageDurationOfStayInMinutesLocal = this.averageDurationOfStayInMinutes
	},
}
</script>

<style scoped>
.card {
	border:1px solid #c0c0c0;
	border-radius: 10px;
}
hr { border: 1px solid #c0c0c0 !important; height: 1px; }
.v-text-field > .v-input__control > .v-input__slot::before { border-style: none !important;}
.v-text-field { padding-bottom: 12px !important; }
.v-chip.v-size--default { 
	border-radius: 5px !important;
	background-color: #ffffff !important;
	border: thin #dddddd solid !important;
	text-decoration: none !important;
	font-weight: normal !important;
}
.chip {
	 font-family: 'Inter', sans-serif;
  min-height: 32px !important;
  max-height: 32px !important;
  height: 32px !important;
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
  display: flex !important;
  /*justify-content: space-around !important;*/
  align-items: center !important;
  border-radius: 5px !important;
  font-family: "Inter";
  font-size: 12pt !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  cursor: pointer !important;
}

</style>
