<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail" v-model:successTitle="successTitle" :loading="loading">
		<template #navbar>
			<v-text-field variant="outlined" density="compact" clearable hide-details
				prepend-inner-icon="mdi-magnify"
				:placeholder="$t('text.search')"
				v-model="searchString"
				@keyup.enter="search()"
				@click:clear="clearSearch()"
			/>

			<!-- Filters -->
			<FilterMenu v-model="this.$store.state.filter" @clear="clearFilter" @apply="applyFilter">
				<FilterFieldWrap>
					{{ $t('text.tags') }}
					<!-- TODO: make a reusable select comp with custom option render -->
					<v-select
						multiple
						ref="filterTags"
						v-model="selectedTags"
						:items="uniqueTags"
						variant="outlined"
						density="compact"
						style="width: 350px;"
					>
						<template #prepend-item>
							<v-list-item title="Select All" @click="toggle">
								<template #prepend>
									<v-checkbox-btn
										:indeterminate="selectedTags.length > 0 && selectedTags.length < uniqueTags.length"
										:model-value="selectedTags.length > 0"
									/>
								</template>
							</v-list-item>
						</template>

						<template #selection="{ item, index }">
							<Tag :value="item.title" v-if="index < 4" />
							<span v-if="index == 4" style="margin-left: 5px;">
								(+{{ selectedTags.length - 4 }})
							</span>
						</template>

						<template #item="{ item, props }">
							<v-list-item v-bind="props" style="border-top: 1px solid #ddd;">
								<template #title></template>
								<div style="display: flex; gap: 5px; align-items: center;">
									<v-checkbox-btn style="flex-grow: 0;" :model-value="selectedTags.includes(item.value)" />
									<Tag :value="item.title" />
									<div>{{ item.title }}</div>
								</div>
							</v-list-item>
						</template>
					</v-select>
				</FilterFieldWrap>
			</FilterMenu>
				
			<!-- Add New User -->
			<!-- <v-btn class="gradientButton" small elevation="0" @click="showAddUser(newUserType)">
				<v-icon>mdi-plus-circle</v-icon>
				<span class="d-none d-md-inline">{{ $t('text.addOperator') }}</span>
			</v-btn> -->
		</template>

		<v-card class="tableCard">
			<v-data-table fixed-header disable-sort :key="refreshCount"
				v-model="selected"
				:headers="headers"
				:items="users"
				:items-per-page="limit"
				item-key="name"
				class="elevation-0"
				:item-value="item => item"
			>
				<template #item="{item}">
					<tr @click="showEditPermissions(item)">
						<td><v-icon>mdi-account</v-icon></td>
						<td>{{ item.fields.email.de }}</td>
						<td>{{ item.fields.name.de }}</td>
						<td>
							<v-menu class="menu" open-on-hover>
								<template #activator="{ props }">
									<Tag v-for="tag of item.fields.canManageTags.de" :key="tag" :value="tag" v-bind="props" />
								</template>
								<v-list>
									<span v-for="(tag, index) in item.fields.canManageTags.de" :key="tag">
										<v-list-item>
											<v-list-item-title>
												<Tag :value="tag" v-bind="props" />
												<span style="font-size: 14pt !important; padding-left: 10px">{{ tag }}</span>
											</v-list-item-title>
										</v-list-item>
										<v-divider v-if="index < item.fields.canManageTags.de.length-1" style="border-color: #000000 !important"/>
									</span>
								</v-list>
							</v-menu>
						</td>
					</tr>
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" :limit="limit" :total="userCount" @update:modelValue="getUsers()" :results="users" />
				</template>
			</v-data-table>
		</v-card>

		<Dialog ref="editPermissionsDialog"
			:title="dialogTitle"
			:confirm-label="$t('text.confirmSelection')"
			:confirm-handler="dialogConfirm"
			:cancel-label="$t('text.cancel')"
			:showClose="false"
			:cancel-handler="dialogCancel"
		>
			<template #content>
				<TagAssignment ref="tagAssignmentComp" :user="selectedUser" :availableTags="uniqueTags"/>
			</template>
		</Dialog>
	</Application>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'
import TagAssignment from './TagAssignment.vue'
import Application from '../Application.vue'
import Tag from '../../../components/common/Tag.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	name: 'MyOrganisationView',
	mixins: [ Common ],
	components: { Loading, Alert, TablePaginator, Dialog, TagAssignment, Application, Tag, FilterMenu, FilterFieldWrap },
	data() { return {
		Action: Action,
		
		loading: false,
		limit: 15,
		offset: 0,
		searchString: this.$store.state.searchString ? this.$store.state.searchString : '',
		errorTitle: '',
		errorDetail: '',
		successTitle: '',
		successDetail: '',

		selected: [],
		users: [],
		userCount: 0,
		selectedTags: [],
		showTags: {sys:{id:''}},

		uniqueTags: [],

		dialogTitle: '',
		selectedUser: null,
		
		refreshCount: 0,
		selectAllTags: false,
	}},
	computed: {
		headers () {
			return [
				{ title: 'Type', key: "fields.type.de", width: '32' },
				{ title: 'Login E-Mail', key: "fields.email.de", width: '25%', cellClass: "truncate" },
				{ title: 'Name', key: "fields.name.de", width: '25%', cellClass: "truncate" },
				{ title: 'Permissions', key: "tags", width: '50%' },
			]
		},
	},
	watch: {
		selectAllTags() {
			this.selectedTags = this.uniqueTags
		},
	},
	methods: {
		resetShowTags() {
			this.showTags = {sys:{id:''}}
		},
		async getUsers() {
			this.loading = true
			try {
				let url = `/users?skip=${this.offset}&limit=${this.limit}&searchString=${encodeURIComponent(this.searchString)}&clientId=${this.$store.state.selectedClient.sys.id}`
				
				if (this.$store.state.filter?.tags?.length) {
					url += `&filter=${JSON.stringify(this.$store.state.filter)}`
				}

				let res = await this.$httpGet(url)
				this.users = res.users?.length ? res.users : []
				this.userCount = res.total 

				if (!this.uniqueTags.length && res.uniqueTags?.length) {
					let tags = []
					for (let i = 0; i < res.uniqueTags.length; i++) {
						tags.push({
							label: res.uniqueTags[i].substring(0,3).toUpperCase(),
							value: res.uniqueTags[i],
							title: res.uniqueTags[i],
						})
					}
					tags = tags.sort((a, b) => a.label.localeCompare(b.label))
					tags = tags.filter(tag => tag.label != 'ALL')
					this.uniqueTags = tags
				}

				this.refreshCount++
			} catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
			this.loading = false
		},
		showEditPermissions(user) {
			this.selectedUser = user
			this.dialogTitle = `${this.$t('text.editPermissions')}<p style="font-size:10pt;color:#646464">${user.fields.email.de}</p>`
			this.$refs.editPermissionsDialog.show = true
		},
		async dialogConfirm() {
			this.loading = true
			const selectedTags = this.$refs.tagAssignmentComp.availableTags.filter(tag => tag.selected === true)
			const tags = selectedTags.length ? selectedTags.map(e => e.value) : []

			const data = {
				userId: this.selectedUser.sys.id,
				tags: tags
			}

			try {
				const res = await this.$httpPut(`/tag-assignments`, data)
				await this.getUsers()
				
				this.successTitle = this.$t('text.SUCCESS')
				this.successDetail = this.$t('text.tagAssignmentSuccess')

			} catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}

			this.loading = false
		},
		dialogCancel() {
			this.$refs.editPermissionsDialog.show = false
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getUsers()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', '')
			
			this.getUsers()
		},
		toggle () {
			this.$nextTick(() => {
				if (this.selectedTags.length == this.uniqueTags.length) {
					this.selectedTags = []
				}
				else {
					this.selectedTags = this.uniqueTags
				}
			})
		},
		async applyFilter() {
			this.offset = 0
			let tags = this.selectedTags

			if (tags[0]?.label) {
				tags = this.selectedTags.map(el => el.value)
			}
			const filter = {
				tags: tags
			}

			await this.$store.commit('setFilter', filter)
			await this.getUsers()
		},
		async clearFilter() {
			this.selectedTags = []
			this.selectAllTags = false
			this.offset = 0
			await this.$store.commit('setFilter', null)
			await this.getUsers()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterTags?.focus?.()
				this.dropdownOpen = true
			} else {
				this.$refs.filterTags?.blur?.()
				this.dropdownOpen = false
			}
		},
	},
	mounted() {
		this.getUsers()
	},
}
</script>

<style scoped>
.v-data-table__mobile-row { word-break: break-all; }
.tags { min-height: 60px !important; height: 60px !important }
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; }
</style>
