<template>
	<div class="pro-feature-tag" :class="{ inline }">
		<v-tooltip :text="!hasCustomTooltipActivator ? $t('text.proFeature_help') : undefined" :disabled="disabled || !inline && !hasCustomTooltipActivator" max-width="400px">
			<template v-slot:activator="{ props }">
				<div v-bind="props">
					<template v-if="hasCustomTooltipActivator">
						<slot name="default"></slot>
					</template>
					<div v-else class="chip">
						<v-icon size="small">mdi-alert-decagram</v-icon>
						{{ $t('text.proFeature') }}
					</div>
				</div>
			</template>
			<template v-if="hasCustomTooltipActivator" v-slot:default>
				<div class="pro-feature-tag tooltip">
					<ProFeatureTag :inline="false" :hideHelpIcon="true" class="tooltip__feature" />
					<div class="tooltip__title">{{ $t('text.proFeature_helpTitle') }}</div>
					<div class="tooltip__subtitle">{{ $t('text.proFeature_help') }}</div>
				</div>
			</template>
		</v-tooltip>
		<HelpIcon v-if="!inline && !hideHelpIcon" keyPrefix="proFeature" />
	</div>
</template>

<script lang="ts">
import HelpIcon from './HelpIcon.vue'
import IconAi from './icons/IconAi.vue'

export default {
	components: { HelpIcon, IconAi },
	props: {
		disabled: Boolean,
		inline: Boolean,
		hideHelpIcon: Boolean,
	},
	computed: {
		hasCustomTooltipActivator() {
			return this.$slots.default
		},
	}
}
</script>

<style scoped lang="scss">
.pro-feature-tag { 
	font-size: 12pt;
	display: flex;
	align-items: center;
	gap: 12px;
	color: #0b222f;
	&.inline {
		font-size: 10pt;
		gap: 4px;
	}

	.chip {
		display: flex;
		gap: 4px;
		align-items: center;
		padding: 2px 7px;
		border-radius: 4px;
		background: rgb(162, 226, 249);
		font-size: smaller;
		white-space: nowrap;
	}
}
</style>
<style lang="scss">
.pro-feature-tag.tooltip {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&__feature {
		margin-left: auto;
	}
}
</style>