<template>
	<div class="enhance-image-button">
		<div class="pro-badge-container" v-if="!hasFeature">
			<ProFeatureTag inline />

		</div>

		<v-btn 
			v-if="mediaHasPoorQuality" 
			variant="outlined" 
			style="margin-right: 15px;"
			@click="handleClick"
			:disabled="!hasFeature"
		>
			<v-icon style="margin-right: 5px;">mdi-creation</v-icon>
			<span>{{ $t('text.enhanceImage') }}</span>
		</v-btn>
	</div>
</template>

<script>
import ProFeatureTag from '@/components/common/ProFeatureTag.vue';
import Common from '@/mixins/Common.vue';

export default {
	name: 'EnhanceImageButton',
	components: { ProFeatureTag },
	mixins: [Common],
	props: {
		mediaHasPoorQuality: {
			type: Boolean,
			required: true
		}
	},

	computed: {
		hasFeature() {
			return this.featureEnabled('AI-image-enhancer')
		}
	},

	methods: {
		handleClick() {
			if (this.hasFeature) {
				this.$emit('show-improve-image-dialog')
			}
		}
	}
}
</script>

<style scoped>
.enhance-image-button {
	display: inline-block;
	position: relative;
}

.pro-badge-container {
	position: absolute;
	top: -16px;
	right: -72px;
	z-index: 1;
}

.pro-badge {
	font-size: 10px;
	background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
	color: white;
	font-weight: bold;
	text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	border: none;
}
</style>
