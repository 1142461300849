<template>
	<div class="MediaCard" :class="{
		cardActive: media.checked,
	}">
		<v-img width="100%" :height="showTitle ? '80%' : '100%'" style="margin:auto" :src="mediaSource()"/>
		<p :class="media.checked ? 'labelActive' : 'label'" v-if="showTitle">{{ formattedTitle() }}</p>

		<!-- Overlay if component is disabled -->
		<div class="leftCorner" v-if="canSelect">
			<v-checkbox dark
				v-model="media.checked"
				data-cy="selectImageCard"
				color="info" 
				false-icon="mdi-checkbox-blank"
				true-icon="mdi-checkbox-marked"
				style="margin-left: -11px; margin-top: 0px;"
				hide-details
				@update:modelValue="checkboxChange()"
			/>
		</div>
		<div class="ribbon" v-if="mediaHasPoorQuality">
			<v-tooltip :text="$t('text.lowImageQualityTitle')">
				<template v-slot:activator="{ props }">
					<v-icon v-bind="props">mdi-alert</v-icon>
				</template>
			</v-tooltip>
		</div>

		<div class="lowerRightCorner">
			<v-btn v-if="canEdit" data-cy="editImage" @click="showUpdate()" icon elevation="0"><v-icon size="24px">mdi-pencil</v-icon></v-btn>
		</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'MediaCard',
	emits: ['show-media-update', 'checkbox-change',],
	props: {
		media: { type: Object },
		canEdit: Boolean,
		canSelect: Boolean,
		showTitle: Boolean
	},
	mixins: [ Common ],

	computed: {
		mediaHasPoorQuality() {
			return this.media.fields.media?.de?.fields?.file?.de?.details?.image?.width < 1200 || this.media.fields.media?.de?.fields?.file?.de?.details?.image?.height < 1000
		}
	},

  methods: {
    showUpdate() {
      this.$emit("show-media-update", this.media)
    },
    checkboxChange() {
      this.$emit("checkbox-change", this.media)
    },
    mediaSource() {
      if (this.media.fields?.media?.de?.fields?.file?.de?.url) {
        //Regular user/system uploaded image
        return this.media.fields.media.de.fields.file.de.url
      } else if (this.media.fields.file?.de?.url) {
        //Application Icon
        return this.media.fields.file.de.url
      } else if (this.media.fields.file?.dataURL) {
        //Image from file upload
        return this.media.fields.file.dataURL
      } else {
        return '@/assets/icons/loading.svg'
      }
    },
    formattedTitle() {
      if (this.media.fields?.title?.[this.selectedLocale]?.length) {
        if (this.media.fields?.title?.[this.selectedLocale]?.length >= 40) {
          return this.media.fields.title[this.selectedLocale].substring(0,40)
        } else {
          return this.media.fields.title[this.selectedLocale]
        }
      }
      return ''
    }
  }
}
</script>

<style scoped>
.MediaCard {
	position: relative;
	padding: 0px !important;
	background-color: #ffffff;
	border: solid 1px #dbdbdb !important;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

	overflow: hidden; /* required for the ribbon */
}
.icon {
	width: 24px !important;
	height: 24px !important;
	max-width: 24px !important;
	max-height: 24px !important;
	min-width: 24px !important;
	min-height: 24px !important;
	border-radius: 10px !important;
}
.cardActive {
	background-color: #00aeef !important;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.label { font-size: 10pt; color: #000000; padding: 10px; overflow: hidden; }
.labelActive { font-size: 10pt; color: #ffffff; padding: 10px; overflow: hidden; }
.leftCorner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 0 !important;
  border-bottom-right-radius: 10px !important;
  background-color: rgba(128,128,128,0.5);
}
.rightCorner {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;

}
.editButton {
  margin-top:-7px;
  margin-right:0px;
  cursor: pointer !important;
  max-width: 35px !important;
  max-height: 35px !important;
  background-color: transparent !important;
}
.lowerRightCorner {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 0px;
  bottom: 0px;
}
.topRightCorner {
  position: absolute;
  top: 20px;
  right: -30px;
  transform: rotate(45deg);
  z-index: 3;
  background-color: #ffb400;
  color: white;
  padding: 5px 30px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ribbon {
	/* if you want a ribbon style (not filling in the top right corner) then set margin to 0 and remove padding */
	margin: -10px;
	background-color: #ffb400;
	color: white;
	padding: 0.5em 0;
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(90%) translateY(0%) rotate(45deg);
	transform-origin: top left;
	width: 150px;
}
.ribbon:before,
.ribbon:after {
	content: '';
	position: absolute;
	top: 0;
	margin: 0 -1px;
	width: 100%;
	height: 100%;
	background-color: #ffb400;
}
.ribbon:before {
	right: 100%;
}

.ribbon:after {
	left: 100%;
}
</style>