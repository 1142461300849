<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.176 6.26c0 3.66 2.231 5.623 5.623 5.623.47 0 .47.704 0 .704-3.383 0-5.623 1.976-5.623 5.623 0 .469-.704.469-.704 0 0-3.647-2.24-5.623-5.623-5.623-.47 0-.47-.704 0-.704 3.392 0 5.623-1.963 5.623-5.623 0-.47.704-.47.704 0zm-8.505.485c0 .998.608 1.533 1.533 1.533.128 0 .128.192 0 .192-.923 0-1.533.539-1.533 1.534 0 .128-.193.128-.193 0 0-.995-.61-1.534-1.533-1.534-.128 0-.128-.192 0-.192.925 0 1.533-.535 1.533-1.533 0-.128.193-.128.193 0zm3.952-5.147c0 1.664 1.014 2.556 2.556 2.556.214 0 .214.32 0 .32-1.537 0-2.556.898-2.556 2.556 0 .214-.32.214-.32 0 0-1.658-1.018-2.556-2.556-2.556-.213 0-.213-.32 0-.32 1.542 0 2.556-.892 2.556-2.556 0-.213.32-.213.32 0z" :fill="color" fill-rule="evenodd"/>
  </svg>
</template>

<script lang="ts">
export default {
  name: 'IconAi',
  props: {
    color: { type: String, default: '#00AEEF' }
  }
}
</script>