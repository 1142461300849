<template>
	<v-container fluid style="padding-bottom: 70px;" class="Application" :class="{ external, isSideBarFixed }">
		<Alert v-model="errorTitleModel">{{ errorText }}</Alert>
		<Alert v-model="successTitleModel" type="success">{{ successDetail }}</Alert>
		<Alert v-model="toastTitle" :type="toastType">{{ toastText }}</Alert>
		<Loading :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading"></Loading>
		<!-- we dont render the navbar, if the slot is not given.
			this is to allow click-through on AppHost -->
		<div class="navbar" v-if="$slots.navbar">
			<slot name="navbar"></slot>
		</div>
		<div class="content">
			<slot></slot>
		</div>
	</v-container>
</template>

<!-- TODO: i'd like this to be a TS block but Loading does not cooperate.. -->
<script lang="js">
// TODO: use common mixin?
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'

// TODO: auto-loading indicator until content slot is filled - is that possible?
export default {
	name: 'Application',
	components: { Loading, Alert },
	props: {
		// TODO: pass in an app definition?
		// TODO: what to do with name?
		//       should we render app-info here instead of an overlay?
		name: String,
		// TODO: magically take these via common mixin in comp?
		loading: Boolean,
		errorTitle: String,
		errorDetail: [ String, Error ],
		successTitle: String,
		successDetail: String,
		external: { type: Boolean, default: false },
		isSideBarFixed: { type: Boolean, default: false },
	},
	data: () => ({
		errorTitleModel: null,
		successTitleModel: null,

		// this new error handling is eventbased and should successively replace the old prop-based one.
		// with this we will not need the v-model:errorTitle and v-model:successTitle anymore,
		// instead the integrator has to
		// - integrate ChildEventDispatcher mixin
		// - call this.applicationError(e, error, detail) or this.applicationSuccess(e, error, detail)
		toastType: null,
		toastTitle: null,
		toastDetail: null,
	}),
	computed: {
		errorText() {
			return this.errorDetail?.message ?? this.errorDetail
		},
		toastText() {
			return this.toastDetail.message ?? this.toastDetail
		},
	},
	watch: {
		errorTitle() {
			this.errorTitleModel = this.errorTitle
		},
		successTitle() {
			this.successTitleModel = this.successTitle
		},
		errorTitleModel() {
			this.$emit('update:errorTitle', this.errorTitleModel)
		},
		successTitleModel() {
			this.$emit('update:successTitle', this.successTitleModel)
		},
	},
	// TODO: set page title
	mounted() {
		const el = this.$el
		el.addEventListener('applicationError', (e) => {
			const childError = e.detail
			this.toastType = 'error'
			this.toastTitle = childError?.title
			this.toastDetail = childError?.detail
		})
		el.addEventListener('applicationSuccess', (e) => {
			const childError = e.detail
			this.toastType = 'success'
			this.toastTitle = childError?.title
			this.toastDetail = childError?.detail
		})
	},
}
</script>

<style scoped>
.Application.isSideBarFixed { padding-left: 310px !important; }
.navbar { display: flex; gap: 10px; align-items: center; position: fixed; z-index: 901; left: 300px; top: 0; right: 64px; height: 64px; background: white; }
.external .navbar { background: transparent; }

@media screen and (max-width: 660px) {
	.navbar { left: 50px; }
	
}
</style>

<style>
/* TODO: move elsewhere or better solve header differently */
header { background: transparent !important; }
.navbar h1 { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
</style>