<template>
	<div class="contractsList">
		<Table
			v-model:selected="selectedModel"
			v-model:page="page"
			v-model:limit="limit"
			v-model:offset="offset"
			v-model:sortBy="sortBy"
			:headers="headers"
			:items="contractors"
			:total="contractors.length"
			:show-select="contractors.length > 0"
			select-strategy="all"
			sort-asc-icon="mdi-menu-up"
			sort-desc-icon="mdi-menu-down"
			:noDataText="$t('text.noResultsFound')"
		>
			<!-- <template v-slot:item.productKinds="{ item }">
				<div :style="'display: flex; gap: 10px; flex-wrap: wrap;'">
					<Chip v-for="(category, index) in item.productKinds" :key="`category-${index}`" :title="category" />
				</div>
			</template> -->
			<template v-slot:item.contractorStatus="{ item }">
				<v-tooltip :text="`text.contractorStatus${getStatusIcon(item).tooltip}`">
					<template v-slot:activator="{ props }">
						<Chip v-bind="props" :leadingIcon="getStatusIcon(item).icon" :backgroundColor="getStatusIcon(item).bgColor" :iconColor="getStatusIcon(item).iconColor" />
					</template>
				</v-tooltip>
			</template>
			<template v-slot:item.salesChannels="{ item }">
				{{ getSalesChannelsString(item) }}
			</template>
			<template v-slot:item.contractStatus="{ item }">
				<div v-if="sequenceFilterSet && item.contracts.length > 0">
					<v-tooltip>
						<template #activator="{ props }">
							<div v-bind="props">{{ item.contracts.length > 1 ? $t('text.contractsStatusMultiple') : getContractStatusText(item.contracts[0].status) }}</div>
						</template>
						<div style="display: flex; flex-direction: column; gap: 10px; max-height: fit-content;">
							<ContractCard v-for="contract in item.contracts" :key="`contract-${contract.id}`" :contract="contract"></ContractCard>
						</div>
					</v-tooltip>
				</div>
				<div v-else>-</div>
			</template>	
			<template v-slot:item.edit="{ item }">
				<v-btn @click="openDialog(item)" variant="outlined">
					<v-icon size="20">mdi-pencil</v-icon>
				</v-btn>
			</template>
		</Table>
		<DialogV2
			ref="editContractorDialog"
			:title="$t('text.editContractorDialogTitle')"
			:onConfirm="onConfirm"
			:disable-confirm-button="!editItem?.name?.length || !editItem?.email?.length"
		>
			<template #content>
				<FieldSet>
					<v-label>{{ $t('text.editContractorDialogCompanyDetailsTitle')}}</v-label>
					<Field v-if="editItem" typeName="Contractor" fieldName="name" :title="$t('text.editContractorDialogCompanyDetailsName')" v-model="editItem.name" />
					<Field v-if="editItem" typeName="Contractor" fieldName="email" :title="$t('text.editContractorDialogCompanyDetailsEmail')" v-model="editItem.email" />
				</FieldSet>
			</template>
		</DialogV2>
	</div>
</template>
<script lang="ts">
import Chip from '../common/controls/Chip.vue'
import Table from '../common/tables/Table.vue'
import DialogV2 from '../common/DialogV2.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'
import ContractCard from './ContractCard.vue'

export default {
	components: { Chip, Table, DialogV2, FieldSet, ContractCard },
	props: {
		contractors: { type: Array, default: () => [] },
		sequenceFilterSet: Boolean,
	},
	data: () => ({
		selectedModel: [],
		sortBy: [],
		limit: 10,
		offset: 0,
		page: 1,
		editItem: null,
	}),
	computed: {
		headers() {
			return [
				{ title: this.$t('text.name'), key: 'name', value: 'name', sortable: true },
				// { title: this.$t('text.eCommerceCategories'), key: 'productKinds', value: 'productKinds', sortable: false },
				{ title: this.$t('text.salesChannels'), key: 'salesChannels', value: 'salesChannels', sortable: false },
				{ title: this.$t('text.contractorStatus'), key: 'contractorStatus', value: 'contractorStatus', sortable: false },
				{ title: this.$t('text.contractStatus'), key: 'contractStatus', value: 'contractStatus', sortable: false },
				{ title: '', key: 'edit', value: 'edit', sortable: false },
			]
		},
	},
	methods: {
		getStatusIcon(item) {
			if (item.noEmail) return { icon: 'mdi-email', bgColor: '#FDE0E0', iconColor: '#922929', tooltip: 'MailMissing' }
			else if (item.contractorStatus === 'assigned') return { icon: 'mdi-gavel', bgColor: '#D4F1FC', iconColor: '#004660', tooltip: 'ContractsAssigned' }
			else return { icon: 'mdi-gavel', bgColor: '#FFF2D4', iconColor: '#996C00', tooltip: 'ContractsMissing' }
		},
		getSalesChannelsString(item) {
			let result = item.salesChannels?.[0] || ''
			if (item.salesChannels.length > 1) result += ', ' + item.salesChannels[1]
			if (item.salesChannels.length > 2) result += ' +' + (item.salesChannels.length - 2)
			return result
		},
		openDialog(item) {
			this.editItem = JSON.parse(JSON.stringify(item))
			this.$refs.editContractorDialog.open()
		},
		onConfirm() {
			this.$emit('updateContractor', this.editItem)
		},
		getContractStatusText(status: string) {
			return this.$t(`text.contractStatus${status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}`)
		},
	},
}
</script>
