<template>
	<Application :isSideBarFixed="isSideBarFixed">
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goBack">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<v-btn-toggle class="ml-2">
				<v-btn id="btnMobileApp" class="firstButton_active" elevation="0" @click="toggleView">
					<v-icon class="d-lg-none">mdi-cellphone</v-icon>
					<span class="d-none d-lg-inline">{{ $t('text.mobileApp') }}</span>
				</v-btn>
				<v-btn id="btnWebApp" class="lastButton" elevation="0" @click="toggleView">
					<v-icon class="d-lg-none">mdi-web</v-icon>
					<span class="d-none d-lg-inline">{{ $t('text.webApp') }}</span>
				</v-btn>
			</v-btn-toggle>
		</template>

		<SideBar :navigation="sections" @action="handleAction($event)" />

		<div class="pa-3" style="margin-top: 64px;">
        <!-- What is the Validity Checker -->
        <v-row id="howToUseMobileApp">
          <Disclosure :title="$t('text.howToUseMobileApp')" ref="SECTION_howToUseMobileApp">
            <div class="field left-border">
              <p class="override-default">{{ $t('text.validateTickets') }}</p>
              <p class="helpText">{{ $t('text.validateTicketsDescription') }}</p>
              <v-carousel
                  v-show="sliderImages.length > 0"
                  cycle
                  height="auto"
                  hide-delimiter-background
                  show-arrows="hover"
                  class="mt-3"
              >
                <v-carousel-item
                    v-for="(image, idx) in sliderImages"
                    :key="idx"
                    :src="image"
                >
                </v-carousel-item>
              </v-carousel>
            </div>
          </Disclosure>
        </v-row>

        <!-- How do I use the Validity Checker -->
        <v-row id="setupValidityChecker">
          <Disclosure :title="$t('text.setupValidityChecker')" ref="SECTION_setupValidityChecker" :expanded="true">
            <div class="field left-border">
              <p class="override-default">{{ $t('text.downloadConsumptionChecker') }}</p>
              <p>{{ $t('text.downloadConsumptionCheckerDescription') }}</p>
				<div style="display: flex; gap: 10px; margin-top: 10px; align-items: center;">
					<!-- Apple Store -->
					<a target="_blank" href="https://apps.apple.com/at/app/alturos-validity-checker/id1444260979?itsct=apps_box_badge&amp;itscg=30200">
						<img height="50" :alt="$t('text.downloadOnTheAppStore')"
							:src="'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/' + this.selectedLocale"
						/>
					</a>

					<!-- Google Store -->
					<a target="_blank" href="https://play.google.com/store/apps/details?id=app.alturos.consume">
						<img height="60" :alt="$t('text.getItOnGooglePlay')"
							:src="'https://play.google.com/intl/' + this.selectedLocale + '/badges/static/images/badges/' + this.selectedLocale + '_badge_web_generic.png'"
						/>
					</a>
				</div>

            </div>

            <div class="field left-border">

				<p class="override-default">{{ $t('text.scanConfigurationCode') }}</p>
              <p class="helpText">{{ $t('text.scanConfigurationCodeDescription') }}</p>

              <v-row style="margin-bottom: 20px;">
                <v-col v-for="qrCode of qrCodes" :key="qrCode.gateId">
                  <v-row align="center" class="flex-column" style="max-width:300px; padding-top: 30px;">
					{{ qrCode.gateId }}
                    <a :download="'QR-Code_' + qrCode.gateId" :href="qrCode.qrCodeData"
                      :title="$t('text.downloadQRCodes')">
                      <v-img :src="qrCode.qrCodeData" max-width="300px" alt="QR-Code" />
	                    {{ $t('text.downloadQRCodes') }}
                    </a>
                    <v-btn class="mb-3" @click="share(qrCode.gateId, qrCode.qrCodeData)" v-if="isWebShareApiAvailable">
                      <v-icon start>mdi-share-variant</v-icon>
                      {{ $t('text.share') }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert class="mt-3" v-show="hasError" type="warning">{{ $t('text.errorQRCodeLoading') }}</v-alert>
              <v-alert class="mt-3" v-show="qrCodes.length === 0 && !hasError" type="info">
                {{ $t('text.infoNoQRCodes') }}
              </v-alert>
              <p class="mt-3" v-show="qrCodes.length > 0">{{ $t('text.validityCheckerQRCodeDescription') }}</p>
            </div>

            <div class="field left-border">
              <p class="override-default">{{ $t('text.startScanning') }}</p>
              <p class="helpText">{{ $t('text.startScanningDescription') }}</p>
            </div>
          </Disclosure>
        </v-row>
		</div>
	</Application>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar.vue"
import Disclosure from "@/components/common/Disclosure.vue"
import Application from '../Application.vue'
import Loading from '@/mixins/Loading.vue'

export default {
	mixins: [ Common, Loading ],
	components: { Disclosure, SideBar, Application },
	props: {
		qrCodes: {
			type: Array,
			default: () => []
		},
		hasError: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		hasError: false,
		sliderImages: [],
		qrCodes: [],
		activeLink: '',
		sections: [
			{ id: 'howToUseMobileApp', state: 'valid' },
			{ id: 'setupValidityChecker', state: 'valid' },
		],
		isWebShareApiAvailable: navigator.canShare,
	}),
  methods: {
    handleAction(event) {
			this[event.function](event.params)
		},
	async getSliderImages() {
		await this.loadingOverlay(async () => {
			try {
				this.sliderImages = await this.$httpGet(`/validitychecker/slider-images/${this.$store.state.selectedComponent.sys.id}`)
			} catch (error) {
				console.log(error)
			}
		}, 'getSliderImages')
	},
    setActive(key) {
      this.activeLink = key
      if (this.$refs['SECTION_' + key]) {
        this.$refs['SECTION_' + key].open()
      }
    },
    async share(gateId, qrCodeData) {
      const response = await fetch(qrCodeData)
      const blob = await response.blob()
      const filesArray = [new File([blob], gateId + '.png', {type: 'image/png', lastModified: new Date().getTime()})]
      const shareData = {
        files: filesArray,
      }
      await navigator.share(shareData)
    },
    toggleView() {
      this.$emit('toggle-view')
    },
    goBack() {
      this.$router.push('/')
    }
  },
	mounted() {
		this.getSliderImages()
	},
}
</script>

<style scoped>
.title { font-size: 24pt; color: #000000; font-weight: bold; }
.override-default {
  color: black !important;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 1.1rem !important;
}
</style>