<template>
	<div>
		<Field :title="$t('text.availabilityExceptions')" :helpText="$t('text.availabilityExceptions_help')"
			style="padding-left: 0; border: 0;"
		>
			<MultiDatePicker v-if="model"
				v-model="model"
				:disabled="disabled"
				:min="min"
				:max="max"
				:dataCy="dataCy"
				:profile="profile"
				:profiles="[ profile ]"
				:calcProfiles="calcProfiles"
				:multiple="true"
			/>
		</Field>
		<!--
		<v-label class="pb-3">{{ $t('text.additionalDateSettings') }}</v-label>
		<v-menu width="auto" :disabled="disabled" location="bottom" :close-on-content-click="false" transition="none">
			<template v-slot:activator="{ props }">
				<div v-bind="props" class="chips-selector" ref="selector" :data-cy="dataCy">
					<div class="selected-chips">
						<div v-for="chip in chips" :key="'selected_exception_' + chip.id" class="chip">
							{{ chipsLabel(chip) }}
							<v-icon @click.stop="remove(chip.id)">mdi-close</v-icon>
						</div>
					</div>
				</div>
			</template>

			<div class="availability-picker-wrapper">
				<v-date-picker hide-actions elevation="0" color="red-lighten-1"
					v-model="newDate"
					@update:modelValue="addDate(newDate)"
					:allowed-dates="isAllowedDate"
					:min="min"
					:max="max"
				/>
				<div>
					<v-btn id="btnEditWeekday" v-for="weekday in daysOfWeek" :key="weekday"
						@click="addWeekday(weekday)"
						class="day"
						:class="{
							inactive: !containsWeekday(weekday)
						}"
					>
						{{ $t(`text.${ weekday.toLowerCase() }`) }}
					</v-btn>
				</div>
				<p class="helpText" style="max-width: 300px;" v-html="$t('text.exceptionsHelp')"/>
			</div>
		</v-menu>
		<div>
			<p class="helpText" v-html="$t('text.exceptionsHelp')"/>
		</div>
		-->
	</div>
</template>

<script lang="ts">
import Dialog from '@/components/common/Dialog.vue'
import moment from 'moment'
import MultiDatePicker from '../calendar/MultiDatePicker.vue'

export default {
	components: { Dialog, MultiDatePicker },
	props: {
		dataCy: String,
		disabled: Boolean,
		modelValue: Array, // example: [ 2023-01-01, 2023-01-02, 'Monday', 'Tuesday' ]
		min: String,
		max: String,
	},
	data: () => ({
		daysOfWeek: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ],
		newDate: null,
		profile: { id: 1, color: '#faaaaa', name: 'summer', weekdays: [], start: '2024-10-01', end: '2024-10-31' },
		model: [] as string[],
	}),
	computed: {
		chips() {
			if (!this.modelValue) return []
			const chips = this.modelValue.map(ex => {
				return {
					title: ex,
					id: ex,
					sort: ex.startsWith('20') ? ex : 'z' + this.daysOfWeek.indexOf(ex),
				}
			})
			chips.sort((a, b) => a.sort.localeCompare(b.sort))
			return chips
		},
	},
	watch: {
		modelValue(v) {
			const m = this.valueToModel(v)
			if (JSON.stringify(m) === JSON.stringify(this.model)) return
			this.model = m
		},
		model: {
			deep: true,
			handler(m) {
				const v = this.modelToValue(m)
				if (JSON.stringify(v) === JSON.stringify(this.modelValue)) return
				this.$emit('update:modelValue', v)
			},
		},
	},
	methods: {
		// TODO: vuetify picker currently does not support this - maybe in the future..
		isAllowedDate(date) {
			const d = moment(date).format('YYYY-MM-DD')
			console.log(d)
			return !this.modelValue.includes(d)
		},
		addWeekday(day) {
			if (this.modelValue.includes(day)) return
			this.modelValue.push(day)
		},
		containsWeekday(weekday) {
			return this.modelValue.includes(weekday)
		},
		addDate(newDate) {
			const d = moment(newDate).format('YYYY-MM-DD')
			if (this.modelValue.indexOf(d) >= 0) return
			this.modelValue.push(d)
		},
		remove(id) {
			const i = this.modelValue.indexOf(id)
			if (i === -1) return
			this.modelValue.splice(i, 1)
		},
		chipsLabel(chip) {
			if (!chip.title) return
			if (chip.title.startsWith('20')) return chip.title
			return this.$t(`text.${ chip.title.toLowerCase() }`)
		},

		// MultiDatePicker
		separateWeekdays(model): { weekdays: string[], dates: string[] } {
			const weekdays = []
			const dates = []
			for (const d of model ?? []) {
				if (d.match?.(/^\d/)) dates.push(d)
				else weekdays.push(d)
			}
			return { weekdays, dates }
		},
		// takes an array that contains weekdays and returns an array that contains the inverted weekdays
		// TODO: this only works on strings like "Monday", not "monday"
		invertWeekdays(model) {
			return this.daysOfWeek.filter(w => !model.includes(w))
		},
		valueToModel(value) {
			let { weekdays, dates } = this.separateWeekdays(value)
			// empty weekdays means all weekdays are active
			if (weekdays.length != 0) weekdays = this.invertWeekdays(weekdays)
			weekdays = weekdays.map(d => d.toLowerCase())
			return weekdays.concat(dates)
		},
		modelToValue(model) {
			let { weekdays, dates } = this.separateWeekdays(model)
			weekdays = weekdays.map((d: string) => d.charAt(0).toUpperCase() + d.slice(1))
			if (weekdays.length != 7) weekdays = this.invertWeekdays(weekdays)
			return weekdays.concat(dates)
		},
		calcProfiles(day): number[] {
			if (this.model.includes(day.weekday)) return [ 1 ]
			if (this.model.includes(day.date)) return [ 1 ]
			return []
		},
	},
	mounted() {
		this.model = this.valueToModel(this.modelValue)
	},
}
</script>

<style scoped lang="scss">
.day {
	background-color: #f2f2f2 !important;
	box-shadow: none !important;
	border-radius: 30px !important;
	border: solid 1px rgba(0, 0, 0, 0.1) !important;
	margin: 5px;
	text-transform: none !important;
	letter-spacing: 0px !important;
	padding: 5px 10px;
	font-size: 14px !important;
	height: auto !important;
	display: none;
}
.day.inactive {
	background: #fff !important;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
	border: 1px #ddd solid !important;
	color: #000 !important;
	display: block;
}
.chips-selector {
	position: relative;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.38);
	border-radius: 4px;
	margin-bottom: 0px;
	transition: all .3s cubic-bezier(.25, .8, .5, 1);
	cursor: pointer;
	padding-bottom: 5px;
	padding-right: 5px;

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.86);
	}
	.selected-chips {
		min-height: 48px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.chip {
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
		border: thin #ddd solid !important;
		color: #000;
		padding: 5px 10px;
		border-radius: 10px;
		margin-left: 5px;
		margin-top: 5px;
		background: rgb(245, 245, 245);
		background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;

		i {
			padding-left: 10px;
		}
	}
}
::v-deep .v-date-picker-table .v-btn {
	margin: auto !important;
}
::v-deep .v-date-picker-month__day {
	height: 26px;
}
.availability-picker-wrapper {
	box-shadow: 1px 3px 15px rgba(0,0,0,0.2);
	padding: 5px;
	background: white;
	display: flex;
	gap: 10px;
}
@media screen and (max-width: 822px) {
	.availability-picker-wrapper .helpText {
		display: none;
	}
}
</style>